import { IUser } from 'app/shared/model/user.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';

export interface IUserOrganizationWarehouse {
  id?: number;
  userLogin?: string;
  userId?: number;
  organizationId?: number;
  warehouseId?: number;

  user?: IUser;
  warehouse?: IWarehouse;
  organization?: IOrganization;
}

export const defaultValue: Readonly<IUserOrganizationWarehouse> = {
  user: null,
  warehouse: null,
  organization: null,
};
