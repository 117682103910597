import axios from 'axios';
import { ICrudGetAllAction } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import {
  IInvoiceHsBaseTransactionTotal,
  defaultValue,
} from 'app/shared/model/invoiceHsBase/transaction/total/invoice-hs-base-transaction-total.model';

export const ACTION_TYPES = {
  FETCH_INVOICEHSBASETRANSACTIONTOTAL_LIST: 'invoiceHsBaseTransactionTotal/FETCH_INVOICEHSBASETRANSACTIONTOTAL_LIST',
  RESET: 'invoiceHsBaseTransactionTotal/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  totals: [] as ReadonlyArray<IInvoiceHsBaseTransactionTotal>,
  total: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type InvoiceHsBaseTransactionTotalState = Readonly<typeof initialState>;

// Reducer

export default (state: InvoiceHsBaseTransactionTotalState = initialState, action): InvoiceHsBaseTransactionTotalState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INVOICEHSBASETRANSACTIONTOTAL_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_INVOICEHSBASETRANSACTIONTOTAL_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVOICEHSBASETRANSACTIONTOTAL_LIST):
      return {
        ...state,
        loading: false,
        totals: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/invoice-hs-base-transaction-totals';

// Actions

export const getTotalsForTransaction: ICrudGetAllAction<IInvoiceHsBaseTransactionTotal> = id => {
  const requestUrl = `${apiUrl}/transaction/${id}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICEHSBASETRANSACTIONTOTAL_LIST,
    payload: axios.get<IInvoiceHsBaseTransactionTotal>(`${requestUrl}`),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
