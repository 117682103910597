import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';
import { IVariation } from 'app/shared/model/master/variation/variation.model';
import { IConsumptionTransaction } from 'app/shared/model/consumption/consumption-transaction.model';
import { Moment } from 'moment';
import { EConsumptionTransactionStatus } from 'app/shared/model/enumeration/e-transaction-status.model';

export interface IConsumptionGrouped {
  id?: number;
  quantity?: number;
  organizationId?: number;
  warehouseId?: number;
  variationId?: number;
  consumptionTransactionId?: number;

  organization?: IOrganization;
  warehouse?: IWarehouse;
  variation?: IVariation;
  consumptionTransaction?: IConsumptionTransaction;
}

export const defaultValue: Readonly<IConsumptionGrouped> = {};

export interface IConsumptionGroupedSearch {
  organizationId?: number;
  warehouseId?: number;
  variationId?: number;

  organization?: IOrganization;
  warehouse?: IWarehouse;
  variation?: IVariation;

  id?: number;
  quantity?: number;

  periodBegin?: Moment;
  periodEnd?: Moment;
  statuses?: any;
}
