import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {
  FlatTable,
  FlatTableDataCell,
  FlatTableDataRow,
  FlatTableHeader,
  FlatTableHeaderCell, FlatTablePagination,
  getSortState,
} from "@msvsustavi/msv-ui-react";
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {IRootState} from "app/shared/reducers";
import { translate} from "react-jhipster";
import ConsumptionEditDialog from "app/modules/consumption/edit/consumptionEdit.dialog";
import {fetchBasesAllSelection, getEntities} from "app/shared/reducers/invoiceHsBase/base/base/invoice-hs-base.reducer";
import {navigation} from "app/shared/util/navigation.constants";
import {Link} from 'react-router-dom';
import NumberFormat from "react-number-format";
import {APP_DECIMAL_SEPARATOR, APP_NUMBER_FORMAT_DECIMAL_SCALE, APP_THOUSAND_SEPARATOR} from "app/config/constants";
import BasesSelection from "app/modules/invoiceHsBase/dashboard/invoiceHsBase.view.search";

const BasesView = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const bases = useSelector(({invoiceHsBase}: IRootState) => invoiceHsBase.entities);
  const total = useSelector(({invoiceHsBase}: IRootState) => invoiceHsBase.totalItems);
  const basesSearchData = useSelector(({invoiceHsBase}: IRootState) => invoiceHsBase.basesSearchData);

  const [paginationState, setPaginationState] = useState(
    {
      ...getSortState(location, ITEMS_PER_PAGE),
      sort: 'id',
      order: 'desc',
      activePage: 0
    }
  );

  const loadTable = () => {
    dispatch(getEntities());
  };

  useEffect( () => {
    loadTable();
  },[])

  const sortTable = () => {
    loadTable();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      history.push(`${location.pathname}${endURL}`);
    }
  };

  const handlePagination = currentPage => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage -1
    });
    history.push(`${location.pathname}?page=${currentPage}&sort=${paginationState.sort},${paginationState.order}`);
  };

  useEffect(() => {
    sortTable()
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);


  const handleSort = (column, order) => {
    setPaginationState({
      ...paginationState,
      sort: column,
      order: order
    });
    history.push(`${location.pathname}?page=${paginationState.activePage}&sort=${column},${order}`);
  };

  useEffect(() => {
    dispatch(fetchBasesAllSelection(basesSearchData, paginationState.activePage, `${paginationState.sort},${paginationState.order}`));
  }, [basesSearchData]);


  return (
    <>
      <BasesSelection/>
      <div className={"d-flex w-100 flex-column justify-content-end align-items-end"}>
        <div className={"w-100 px-3 justify-content-end"}>
          <FlatTable>
            <FlatTableHeader>
              <FlatTableHeaderCell first={true} showSort onHeaderClick={(sort) => handleSort("designation", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBase.designation')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("organization", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBase.organization')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.m3')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.totalValueNoTaxWithExpenses')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.totalValueWithTaxNoExpenses')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.totalValueNoTaxNoExpenses')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.manipulativeCosts')}
              </FlatTableHeaderCell>

            </FlatTableHeader>
            {
              [].concat(bases) && [].concat(bases).map(row =>(
                <FlatTableDataRow>

                  <FlatTableDataCell type={"begin"} showBorder={false}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        <Link to={navigation.invoiceHsBase.card.tabs.items.replace(":id", `${row.id}`)}>
                          <div className={"d-inline-block me-1 px-1 fw-bold text-truncate color-primary"} style={{maxWidth: "250px"}}>{row.designation}</div>
                        </Link>
                      </div>
                    }
                  </FlatTableDataCell>
                  <FlatTableDataCell type={"begin"} showBorder={true}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.organizationName}
                      </div>
                    }
                  </FlatTableDataCell>
                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.m3}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.totalValueWithTaxNoExpenses}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.totalValueWithTaxNoExpenses}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.totalValueNoTaxNoExpenses}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.manipulativeCosts}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                </FlatTableDataRow>
              ))
            }
            <FlatTablePagination activePage={paginationState.activePage+1} onSelect={(page) => {
              handlePagination(page)
            }} maxButtons={4} totalItems={total} itemsPerPage={ITEMS_PER_PAGE} showPagination={true}/>
          </FlatTable>
        </div>
        <ConsumptionEditDialog/>
      </div>
    </>
  );
};

export default BasesView;

