import moment, { Moment } from 'moment';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IVariation } from 'app/shared/model/master/variation/variation.model';

export interface IConsumption {
  id?: number;
  consumptionDate?: Moment;
  quantity?: number;
  uqDesignation?: string;
  location?: string;
  document?: string;
  source?: string;
  jsonDetails?: string;
  organizationId?: number;
  warehouseId?: number;
  variationId?: number;
  consumptionTransactionId?: number;

  organization?: IOrganization;
  warehouse?: IWarehouse;
  variation?: IVariation;

  uuid?: string;
}

export const defaultValue: Readonly<IConsumption> = {
  quantity: 0.0,
  uqDesignation: '',
  document: '',
  location: '',
  source: 'M',
  variation: null,
  organization: null,
  warehouse: null,
  variationId: null,
  consumptionDate :  moment()
};

export const defaultValueInput: Readonly<IConsumption> = {
  quantity: 0.0,
  uqDesignation: '',
  document: '',
  location: '',
  source: 'M',
  variation: null,
  organization: null,
  warehouse: null,
  variationId: null,
};

export interface IConsumptionSearch {
  organizationId?: number;
  warehouseId?: number;
  variationId?: number;

  uqDesignation?: string,
  document?: string,
  location?: string,
  variation?: null,
  organization?: null,
  warehouse?: null,
}
