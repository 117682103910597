import React from "react";
import {Modal, ModalBody} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import {showEditDialog} from "app/shared/reducers/consumption/edit/consumption.edit";
import InputEditDialogComponent from "app/modules/input/edit/inputEdit.dialog.component";

const InputEditDialog = () => {

  const dispatch = useDispatch();
  const showDialog = useSelector(({input}:IRootState)=>input.edit.showEditDialog);
  return (
    <Modal isOpen={showDialog}
           toggle={() => dispatch(showEditDialog(!showDialog))}
           modalTransition={{timeout: 300}}
           backdropTransition={{timeout: 600}}
           centered={true}
           keyboard={true}
           fade={true}
           size={'lg'}
    >
      <ModalBody>
        <InputEditDialogComponent/>
      </ModalBody>
    </Modal>
  );
};

export default InputEditDialog;
