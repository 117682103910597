import React, {useEffect} from "react";
import {Modal, ModalBody} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import ManualEntryDialogComponent from "app/modules/consumption/insert/dialogs/manualEntry/manuelEntry.dialog.component";
import {
  setDocumentInput,
  setUqInput,
  setVariationInput
} from "app/shared/reducers/consumption/insert/consumption.insert";
import DocumentEntryDialogComponent
  from "app/modules/consumption/insert/dialogs/document/documentEntry.dialog.component";
import UqDesignationEntryDialogComponent
  from "app/modules/consumption/insert/dialogs/uqDesignation/uqDesignationEntry.dialog.component";

const UqDesignationEntryDialog = (props) => {
  const dispatch = useDispatch();
  const showDialog = useSelector(({consumption}:IRootState)=>consumption.insert.uqInput);

  return (
    <Modal isOpen={showDialog}
           toggle={() => dispatch(setUqInput(!showDialog))}
           modalTransition={{timeout: 300}}
           backdropTransition={{timeout: 600}}
           centered={true}
           keyboard={true}
           fade={true}
           size={'lg'}
    >
      <ModalBody>
        <UqDesignationEntryDialogComponent/>
      </ModalBody>
    </Modal>
  );
};

export default UqDesignationEntryDialog;
