import React, {useEffect, useState} from "react";
import {Formik} from "formik";
import {
  ActionButton,
  Col,
  DataTable,
  getPageBeginIndex,
  getPageEndIndex,
  getSortState,
  Grid,
  HeadlineH0,
  InputComponentNumber,
  Loading,
  Row,
  STANDARD_SAVE_SUBMIT_PROPS
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import {DataTableColumn, InputComponentText} from "@msvsustavi/msv-ui-react/dist";
import {ITEMS_PER_PAGE_DIALOG} from "app/shared/util/pagination.constants";
import {useHistory} from "react-router-dom";
import {searchTrupci} from "app/entities/nabavawrapper/trupci/trupci.reducer";
import classNames from "classnames/bind";
import {
  addTrupac, fillConsumptionFromTrupac, removeTrupac
} from "app/shared/reducers/consumption/insert/consumption.insert";


const UqDesignationEntryDialogComponent = () => {

  const dispatch = useDispatch();
  const trupciSearchData = useSelector(({trupci}: IRootState) => trupci.uqSearchData);
  const loading = useSelector(({trupci}: IRootState) => trupci.loading);
  const trupci = useSelector(({trupci}: IRootState) => trupci.entities);
  const total = useSelector(({trupci}: IRootState) => trupci.totalItems);
  const trupciForInsert = useSelector(({consumption}: IRootState) => consumption.insert.trupciForInsert);
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(getSortState(location, ITEMS_PER_PAGE_DIALOG));

  const cnNotChosen = classNames(
    "d-flex",
    "align-items-center",
    "justify-content-between",
    "p-3",
    "h-25",
    "w-25",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button",
    "justify-content-center"
  );

  const cnTrupacChoose = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "p-3",
    "h-25",
    "w-25",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button",
    "background-dark-lime-green",
    "justify-content-center"
  );

  const loadTable = () => {
    dispatch(searchTrupci(search ? search : "", paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`));
  };


  const startSearching = () => {
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(searchTrupci(
      search,
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`
    ));
  };

  const sortTable = () => {
    loadTable();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      history.push(`${location.pathname}${endURL}`);
    }
  };

  const sort = (c: string, o: string) => {
    setPaginationState({
      ...paginationState,
      order: o,
      sort: c
    });
  };

  const handlePagination = (currentPage) => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  };

  const clear = () => {
    setPaginationState({
      ...paginationState,
      sort: 'id',
      order: 'asc',
      activePage: 1
    });
    sortTable();
  };

  useEffect(() => {
    if (search) {
      startSearching();
    } else {
      clear();
    }
  }, [search]);

  const columns: DataTableColumn[] = [
    {
      field: '',
      header: '',
      sortable: true,
      itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => {
        return <div
          className={trupciForInsert != null && trupciForInsert.includes(rowData) ? cnTrupacChoose : cnNotChosen}
          onClick={
            () => {
              if(trupciForInsert != null && trupciForInsert.includes(rowData) ){
                dispatch(removeTrupac(rowData))
              }else{
                dispatch(addTrupac(rowData))
              }
            }
          }>
        </div>
      }
    },
    {
      field: 'brojPlocice',
      header: translate('denaliApp.nabavawrapperTrupci.brojPlocice'),
      sortable: true,
      itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => {
        return <div className={"d-flex justify-content-center align-content-center"}>
          {rowData?.brojPlocice}
        </div>
      }
    },
    {
      field: 'nazivVrste',
      header: translate('denaliApp.nabavawrapperTrupci.nazivVrste'),
      sortable: true,
      itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => {
        return <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
          <div className={"fw-bold border-bottom"}>{rowData?.nazivVrste}</div>
          <div className={""}>{rowData?.cjRazred} / {rowData?.cjDuljina}</div>
        </div>
      }
    },
    {
      field: 'brojDokumenta',
      header: translate('denaliApp.nabavawrapperTrupci.brojDokumenta'),
      sortable: true,
      itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => {
        return <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
          <div className={""}>{rowData?.brojDokumenta} {rowData?.stranica}</div>
        </div>
      }
    },
    {
      field: 'masaM3',
      header: translate('denaliApp.nabavawrapperTrupci.masaM3'),
      sortable: true,
      itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => {
        return <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
          <div>{rowData?.masaM3}</div>
        </div>
      }
    }
  ];

  return (
    <Formik
      initialValues={trupciSearchData}
      enableReinitialize
      onSubmit={(values, actions) => {
        let search = '';
        Object.keys(values).map(val => {
          search += val && values[val] ? `${val}.contains=${values[val]}&` : '';
        });
        dispatch(setSearch(search))
      }}
    >
      {
        formik => (
          <form onSubmit={formik.handleSubmit}>
            <Grid>
              <HeadlineH0 headline={translate("denaliApp.nabavawrapperTrupci.home.search")}/>
              <Row>
                <Col>
                  <InputComponentText
                    id={"brojPlocice"}
                    name={"brojPlocice"}
                    label={translate("denaliApp.nabavawrapperTrupci.brojPlocice")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentText
                    id={"cjDuljina"}
                    name={"cjDuljina"}
                    label={translate("denaliApp.nabavawrapperTrupci.cjDuljina")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
                <Col>
                  <InputComponentText
                    id={"cjRazred"}
                    name={"cjRazred"}
                    label={translate("denaliApp.nabavawrapperTrupci.cjRazred")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentNumber
                    id={"duljina"}
                    name={"duljina"}
                    label={translate("denaliApp.nabavawrapperTrupci.duljina")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
                <Col>
                  <InputComponentNumber
                    id={"masaM3"}
                    name={"masaM3"}
                    label={translate("denaliApp.nabavawrapperTrupci.masaM3")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentText
                    id={"nazivJM"}
                    name={"nazivJM"}
                    label={translate("denaliApp.nabavawrapperTrupci.nazivJM")}
                    required={false}
                    showBackground={false}
                  />
                </Col>
                <Col>
                  <InputComponentText
                    id={"nazivSortimenta"}
                    name={"nazivSortimenta"}
                    label={translate("denaliApp.nabavawrapperTrupci.nazivSortimenta")}
                    required={false}
                    showBackground={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentText
                    id={"nazivVrste"}
                    name={"nazivVrste"}
                    label={translate("denaliApp.nabavawrapperTrupci.nazivVrste")}
                    required={false}
                    showBackground={false}
                  />
                </Col>
                <Col>
                  <InputComponentText
                    id={"sumPloc"}
                    name={"sumPloc"}
                    label={translate("denaliApp.nabavawrapperTrupci.sumPloc")}
                    required={false}
                    showBackground={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col classname={"px-3"}>
                  {loading ? <Loading/> : <DataTable
                    columns={columns}
                    data={[].concat(trupci)}
                    activePage={paginationState.activePage}
                    itemsPerPage={ITEMS_PER_PAGE_DIALOG}
                    maxButtons={4}
                    totalItems={total}
                    paginationLabel={translate("table.tableLabel", {
                      first: getPageBeginIndex(paginationState.activePage, paginationState.itemsPerPage),
                      last: getPageEndIndex(paginationState.activePage, paginationState.itemsPerPage, total),
                      total: total
                    })
                    }
                    onSelectPage={handlePagination}
                    onSort={(column, order) => {
                      sort(column.field, order);
                    }}
                    onReload={clear}
                  />}
                </Col>
              </Row>
              <Row visible={trupciForInsert.length > 0 ?true:false}>
                <Col classname={"d-flex justify-content-center"}>
                  <ActionButton
                    label={translate('entity.action.save')}
                    {...STANDARD_SAVE_SUBMIT_PROPS}
                    faIcon={true}
                    icon={["far", "plus"]}
                    command={() =>
                      dispatch(
                        fillConsumptionFromTrupac(trupciForInsert))
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </form>
        )
      }
    </Formik>
  );
};

export default UqDesignationEntryDialogComponent;

