import React, {useEffect} from "react";
import {Formik} from "formik";
import {
  Grid,
  Col,
  Row,
  HeadlineH0,
  ActionButton,
  STANDARD_SAVE_SUBMIT_PROPS,
  InputComponentSelectAsync, LIST_ITEMS_PER_PAGE, InputComponentDate
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATE_FORMAT_INPUT} from "app/config/constants";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import {
  createEntity, reset, chooseOrganization,
  chooseWarehouse, createEntityAndFillGrouped, getLastPeriodEndForOrgAndWh, setShowDialogEdit, setShowDialog,
} from "app/shared/reducers/transaction/consumption-transaction.reducer";
import {lookupOrganizations, lookupOrganizationsSelection} from "app/shared/lookups/organization/organization.lookup";
import {lookupWarehouses} from "app/shared/lookups/warehouse/warehouse.lookup";
import {EConsumptionTransactionStatus} from "app/shared/model/enumeration/e-transaction-status.model";
import {InputComponentAutocomplete} from "@msvsustavi/msv-ui-react/dist";
import moment from "moment";


const ConsumptionTransactionEntryDialogComponent = (props) => {

  const dispatch = useDispatch();
  const consumptionTransaction = useSelector(({consumptionTransaction}:IRootState)=>consumptionTransaction.entity);
  const organization = useSelector(({consumptionTransaction}:IRootState)=>consumptionTransaction.organization);
  const warehouse = useSelector(({consumptionTransaction}:IRootState)=>consumptionTransaction.warehouse);
  const {account} = useSelector(({authentication}: IRootState) => authentication);

  useEffect( () =>{
    reset()
  },[]);


  return (
    <Formik
      initialValues={consumptionTransaction}
      enableReinitialize
      onSubmit={(values, actions) => {
          dispatch(createEntityAndFillGrouped({
            ...values,
            organizationId : organization.id,
            warehouseId : warehouse.id,
            status : "PREPARED",
            periodBegin : moment(values.periodBegin).set({"hour": 12, "minute": 0}),
              periodEnd : moment(values.periodEnd).set({"hour": 12, "minute": 0})
          }
          ));
          actions.resetForm();
          dispatch(setShowDialog(false))
      }}
    >
      {
        formik => (
          <form onSubmit={formik.handleSubmit} >
            <Grid>
              <HeadlineH0 headline={translate("denaliApp.consumptionTransaction.home.createLabel")}/>
              <Row>
                <Col>
                  <InputComponentAutocomplete
                    id={"organization"}
                    name={"organization"}
                    required={true}
                    showBackground={true}
                    value={organization}
                    label={translate("denaliApp.consumptionTransaction.organization")}
                    getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
                    loadOptions={(inputValue) => lookupOrganizationsSelection( 0, LIST_ITEMS_PER_PAGE, 'asc',account.id, inputValue != "" ? inputValue : null).then(res => res.data)}
                    onChangeOption={(option) =>{
                      dispatch(chooseOrganization(option));
                    }
                    }
                  />
                </Col>
              </Row>
              <Row visible={organization != null}>
                <Col>
                  <InputComponentAutocomplete
                    id={"warehouse"}
                    name={"warehouse"}
                    required={true}
                    showBackground={true}
                    value={warehouse}
                    label={translate("denaliApp.consumptionTransaction.warehouse")}
                    getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
                    loadOptions={(inputValue) => lookupWarehouses(`name.contains=${inputValue}&organizationId.equals=${organization.id}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
                    onChangeOption={(option) => {
                      dispatch(chooseWarehouse(option));
                      }
                    }
                  />
                </Col>
              </Row>
              <Row visible={warehouse != null}>
                <Col>
                  <InputComponentDate
                    id={"periodBegin"}
                    name={"periodBegin"}
                    label={translate("denaliApp.consumptionTransaction.periodBegin")}
                    required={true}
                    disabled={false}
                    showBackground={true}
                    dateDisplayFormat={APP_LOCAL_DATE_FORMAT_INPUT}
                  />
                </Col>
                <Col>
                  <InputComponentDate
                    id={"periodEnd"}
                    name={"periodEnd"}
                    label={translate("denaliApp.consumptionTransaction.periodEnd")}
                    required={true}
                    disabled={false}
                    showBackground={true}
                    dateDisplayFormat={APP_LOCAL_DATE_FORMAT_INPUT}
                  />
                </Col>
              </Row>
              <Row>
                <Col classname={"d-flex justify-content-center"}>
                  <ActionButton
                    label={translate('entity.action.save')}
                    {...STANDARD_SAVE_SUBMIT_PROPS}
                    faIcon={true}
                    icon={["far","plus"]}
                  />
                </Col>
              </Row>
            </Grid>
          </form>
        )
      }
    </Formik>
  );
};

export default ConsumptionTransactionEntryDialogComponent;
