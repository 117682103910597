import React, {useEffect} from 'react';
import 'antd/dist/antd.css';
import cubejs from "@cubejs-client/core";
import { Spin, Statistic, Table} from "antd";
import {QueryRenderer} from "@cubejs-client/react";
import {
  Col,
  Row
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import {useOrganization} from "app/shared/util/use-organization";



const TotalsChartsView = () => {

  const org = useSelector(({dashboardSelections}:IRootState)=>dashboardSelections.organization);
  const warehouse = useSelector(({dashboardSelections}:IRootState)=>dashboardSelections.warehouse);

  const cubejsApi = cubejs(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzU0MTI0OTYsImV4cCI6MTYzNTQ5ODg5Nn0.9Ws5MkmJGxmMouRhTXnKF8gX6n0jDoK2n9KqQAgCT58',
    { apiUrl: 'https://cube.dcg.msv-sustavi.hr/cubejs-api/v1' }
  );

  //+
  const renderChartThisWeek = ({ resultSet, error, pivotConfig }) => {
    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
      return <Spin />;
    }

    return (
      <Row classname={"w-100"}>
        <Col>
          {resultSet.seriesNames().map((s) => (
            <Statistic value={resultSet.totalRow()[s.key] === null ? '0,00' : resultSet.totalRow()[s.key]} />
          ))}
        </Col>
      </Row>
    );
  };

  //+
  const renderChartThisMonth = ({ resultSet, error, pivotConfig }) => {
    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
      return <Spin />;
    }

    return (
      <Row classname={"w-100"}>
        <Col>
          {resultSet.seriesNames().map((s) => (
            <Statistic value={resultSet.totalRow()[s.key] === null ? '0,00' : resultSet.totalRow()[s.key]} />
          ))}
        </Col>
      </Row>
    );

  };

  //+
  const renderChartThisYear = ({ resultSet, error, pivotConfig }) => {
    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
      return <Spin />;
    }

    return (
      <Row classname={"w-100"}>
        <Col>
          {resultSet.seriesNames().map((s) => (
            <Statistic value={resultSet.totalRow()[s.key] === null ? '0,00' : resultSet.totalRow()[s.key]} />
          ))}
        </Col>
      </Row>
    );

  };

  //+
  const ChartRendererThisWeek = () => {
    return (
      <QueryRenderer
        query={{
          "dimensions": [],
          "timeDimensions": [
            {
              "dimension": "ConsumptionTransaction.periodEnd",
              "dateRange": "This week"
            }
          ],
          "order": {},
          "measures": [
            "ConsumptionGrouped.quantity"
          ],
          "filters": [
            {
              "member": "Organization.designation",
              "operator": "equals",
              "values": [
                org != null ? org.designation : ""
              ]
            },
            {
              "member": "Warehouse.designation",
              "operator": "equals",
              "values": [
                warehouse != null ? warehouse.designation : ""
              ]
            }
          ],
          "limit": 5000
        }}
        cubejsApi={cubejsApi}
        resetResultSetOnChange={false}
        render={(props) => renderChartThisWeek({
          ...props,
          // @ts-ignore
          chartType: 'number',
          pivotConfig: {
            "x": [],
            "y": [
              "measures"
            ],
            "fillMissingDates": true,
            "joinDateRange": false
          }
        })}
      />
    );
  };

  //+
  const ChartRendererThisMonth = () => {
    return (
      <QueryRenderer
        query={{
          "dimensions": [],
          "timeDimensions": [
            {
              "dimension": "ConsumptionTransaction.periodEnd",
              "dateRange": "This month"
            }
          ],
          "order": {},
          "measures": [
            "ConsumptionGrouped.quantity"
          ],
          "filters": [
            {
              "member": "Organization.designation",
              "operator": "equals",
              "values": [
                org != null ? org.designation : ""
              ]
            },
            {
              "member": "Warehouse.designation",
              "operator": "equals",
              "values": [
                warehouse != null ? warehouse.designation : ""
              ]
            }
          ],
          "limit": 5000
        }}
        cubejsApi={cubejsApi}
        resetResultSetOnChange={false}
        render={(props) => renderChartThisMonth({
          ...props,
          // @ts-ignore
          chartType: 'number',
          pivotConfig: {
            "x": [],
            "y": [
              "measures"
            ],
            "fillMissingDates": true,
            "joinDateRange": false
          }
        })}
      />
    );
  };

  //+
  const ChartRendererThisYear = () => {
    return (
      <QueryRenderer
        query={{
          "dimensions": [],
          "timeDimensions": [
            {
              "dimension": "ConsumptionTransaction.periodEnd",
              "dateRange": "This year"
            }
          ],
          "order": {},
          "measures": [
            "ConsumptionGrouped.quantity"
          ],
          "filters": [
            {
              "member": "Organization.designation",
              "operator": "equals",
              "values": [
                org != null ? org.designation : ""
              ]
            },
            {
              "member": "Warehouse.designation",
              "operator": "equals",
              "values": [
                warehouse != null ? warehouse.designation : ""
              ]
            }
          ],
          "limit": 5000
        }}
        cubejsApi={cubejsApi}
        resetResultSetOnChange={false}
        render={(props) => renderChartThisYear({
          ...props,
          // @ts-ignore
          chartType: 'number',
          pivotConfig: {
            "x": [],
            "y": [
              "measures"
            ],
            "fillMissingDates": true,
            "joinDateRange": false
          }
        })}
      />
    );
  };

  const sumContent = (
    <>
      <div className={"row g-0 pe-2"}>
        <div className={"col-lg-8 p-2"}>
          <div className={"d-flex flex-column border rounded shadow p-4 w-100  justify-items-center"}>
            <div className={"d-flex fw-bold text-center"}>
              {translate("denaliApp.consumption.period.thisWeek")}
            </div>
            <div className={"d-flex fw-bold text-center"}>
              <ChartRendererThisWeek/>
            </div>
          </div>
        </div>
        <div className={"col-lg-8 p-2"}>
          <div className={"d-flex flex-column border rounded shadow p-4 w-100 justify-content-center"}>
            <div className={"d-flex fw-bold text-center"}>
              {translate("denaliApp.consumption.period.thisMonth")}
            </div>
            <div className={"d-flex fw-bold text-center"}>
              <ChartRendererThisMonth/>
            </div>
          </div>
        </div>
        <div className={"col-lg-8 p-2"}>
          <div className={"d-flex flex-column border rounded shadow p-4 w-100  justify-content-center"}>
            <div className={"d-flex fw-bold text-center"}>
              {translate("denaliApp.consumption.period.thisYear")}
            </div>
            <div className={"d-flex fw-bold pe-2 w-100"}>
              <ChartRendererThisYear/>
            </div>
          </div>
        </div>
      </div>
    </>
  );



  return (
    sumContent
   );
};

export default TotalsChartsView;
