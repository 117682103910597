import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPOPRATNICE, defaultValue, IPopratniceSearch } from 'app/shared/model/nabavawrapper/popratnice.model';

export const ACTION_TYPES = {
  FETCH_POPRATNICE_LIST: 'pOPRATNICE/FETCH_POPRATNICE_LIST',
  FETCH_POPRATNICE: 'pOPRATNICE/FETCH_POPRATNICE',
  CREATE_POPRATNICE: 'pOPRATNICE/CREATE_POPRATNICE',
  UPDATE_POPRATNICE: 'pOPRATNICE/UPDATE_POPRATNICE',
  DELETE_POPRATNICE: 'pOPRATNICE/DELETE_POPRATNICE',
  RESET: 'pOPRATNICE/RESET',
  SET_SEARCH: 'popratnice/search/SET_SEARCH',
};

const defaultPopratniceSearch: IPopratniceSearch = {};
const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPOPRATNICE>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  documentSearchData: { ...defaultPopratniceSearch },
  search: '',
};

export type PopratniceState = Readonly<typeof initialState>;

// Reducer

export default (state: PopratniceState = initialState, action): PopratniceState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_POPRATNICE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_POPRATNICE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_POPRATNICE):
    case REQUEST(ACTION_TYPES.UPDATE_POPRATNICE):
    case REQUEST(ACTION_TYPES.DELETE_POPRATNICE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_POPRATNICE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_POPRATNICE):
    case FAILURE(ACTION_TYPES.CREATE_POPRATNICE):
    case FAILURE(ACTION_TYPES.UPDATE_POPRATNICE):
    case FAILURE(ACTION_TYPES.DELETE_POPRATNICE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_POPRATNICE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_POPRATNICE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_POPRATNICE):
    case SUCCESS(ACTION_TYPES.UPDATE_POPRATNICE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_POPRATNICE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SET_SEARCH:
      return {
        ...state,
        documentSearchData: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'services/nabavawrapper/api/popratnices';

// Actions

export const getEntities: ICrudGetAllAction<IPOPRATNICE> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_POPRATNICE_LIST,
    payload: axios.get<IPOPRATNICE>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const searchPopratnice = (
  query: string | undefined,
  page: number | undefined,
  size: number | undefined,
  sort: string | undefined
) => {
  const apiUrl = '/services/nabavawrapper/api/popratnices';
  const requestUrl = `${apiUrl}${sort ? `?${query}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_POPRATNICE_LIST,
    payload: axios.get<IPOPRATNICE>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IPOPRATNICE> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_POPRATNICE,
    payload: axios.get<IPOPRATNICE>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IPOPRATNICE> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_POPRATNICE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IPOPRATNICE> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_POPRATNICE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPOPRATNICE> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_POPRATNICE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const setSearchData = (documentSearchData: IPopratniceSearch) => {
  return {
    type: ACTION_TYPES.SET_SEARCH,
    payload: documentSearchData,
  };
};
