import React, {useState} from 'react';
import 'antd/dist/antd.css';
import cubejs from "@cubejs-client/core";
import { Spin, Statistic, Table} from "antd";
import {QueryRenderer} from "@cubejs-client/react";
import {
  Col,
  InputComponentSelectAsync,
  LIST_ITEMS_PER_PAGE,
  Row
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {lookupOrganizations} from "app/shared/lookups/organization/organization.lookup";
import {lookupWarehouses} from "app/shared/lookups/warehouse/warehouse.lookup";
import {IOrganization} from "app/shared/model/master/organization/organization.model";
import {IWarehouse} from "app/shared/model/master/warehouse/warehouse.model";
import {Line, Pie} from "react-chartjs-2";
import {useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";



const WeekBeforeByVariationsChartsView = () => {


  const org = useSelector(({dashboardSelections}:IRootState)=>dashboardSelections.organization);
  const warehouse = useSelector(({dashboardSelections}:IRootState)=>dashboardSelections.warehouse);

  const COLORS_SERIES = [
    '#5b8ff9',
    '#5ad8a6',
    '#5e7092',
    '#f6bd18',
    '#6f5efa',
    '#6ec8ec',
    '#945fb9',
    '#ff9845',
    '#299796',
    '#fe99c3',
  ];


  const cubejsApi = cubejs(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzU0MTI0OTYsImV4cCI6MTYzNTQ5ODg5Nn0.9Ws5MkmJGxmMouRhTXnKF8gX6n0jDoK2n9KqQAgCT58',
    { apiUrl: 'https://cube.dcg.msv-sustavi.hr/cubejs-api/v1' }
  );

  const commonOptions = {
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  //+
  const LineChartRendererLastWeekWithVariations = ({ resultSet }) => {
    const data = {
      labels: resultSet.categories().map((c) =>  new Date(c.x).toLocaleDateString()),
      datasets: resultSet.series().map((s) => ({
        label: s.title.split(',')[0],
        data: s.series.map((r) => r.value),
        borderColor: COLORS_SERIES,
        pointRadius: 1,
        tension: 0.1,
        pointHoverRadius: 1,
        borderWidth: 2,
        tickWidth: 1,
        fill: false,
      })),
    };
    // @ts-ignore
    return <Line type="line" data={data} options={commonOptions} />;
  };

  //+
  const renderChartLineLastWeekWithVariations = ({ resultSet, error, pivotConfig }) => {
    if (error) {
      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
      return <Spin />;
    }

    return <LineChartRendererLastWeekWithVariations resultSet={resultSet} />;

  };

  //+
  const ChartRendererLineLastWeekByVariations = () => {
    return (
      <QueryRenderer
        query={{
          "dimensions": [
            "Variation.variationName"
          ],
          "timeDimensions": [
            {
              "dimension": "ConsumptionTransaction.periodEnd",
              "granularity": "day",
              "dateRange": "Last week"
            }
          ],
          "filters": [
            {
              "member": "Organization.designation",
              "operator": "equals",
              "values": [
                org != null ? org.designation : ""
              ]
            },
            {
              "member": "Warehouse.designation",
              "operator": "equals",
              "values": [
                warehouse != null ? warehouse.designation : ""
              ]
            }],
          "order": {
            "ConsumptionGrouped.quantity": "desc"
          },
          "measures": [
            "ConsumptionGrouped.quantity"
          ],
          "limit": 5000
        }}
        cubejsApi={cubejsApi}
        resetResultSetOnChange={false}
        render={(props) => renderChartLineLastWeekWithVariations({
          ...props,
          // @ts-ignore
          chartType: 'line',
          pivotConfig: {
            "x": [
              "ConsumptionTransaction.periodEnd.day"
            ],
            "y": [
              "measures"
            ],
            "fillMissingDates": true,
            "joinDateRange": false
          }
        })}
      />
    );
  };


  const lineContentLastWeek = (
    <>
      <div className={"w-100"}>
        <ChartRendererLineLastWeekByVariations/>
      </div>
    </>
  );

  return (
    lineContentLastWeek
  );
};

export default WeekBeforeByVariationsChartsView;
