import React, {useEffect} from 'react';
import 'antd/dist/antd.css';
import {
  InputComponentSelectAsync,
  LIST_ITEMS_PER_PAGE,
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {lookupOrganizations} from "app/shared/lookups/organization/organization.lookup";
import {lookupWarehouses} from "app/shared/lookups/warehouse/warehouse.lookup";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import {useOrganization} from "app/shared/util/use-organization";
import {chooseOrganization, chooseWarehouse} from "app/shared/reducers/dashobardSelections/dashboard-selections.view";
import {InputComponentAutocomplete} from "@msvsustavi/msv-ui-react/dist";

const ChatsSelectionsView = () => {

  const dispatch = useDispatch();
  const org = useSelector(({dashboardSelections}: IRootState) => dashboardSelections.organization);
  const warehouse = useSelector(({dashboardSelections}: IRootState) => dashboardSelections.warehouse);


  const {organization, isRestricted} = useOrganization();

  useEffect(() => {
    if(isRestricted){
      if(organization !== null){
        dispatch(chooseOrganization(organization));
      }
    }
  }, [organization]);

  const contentCard = (
    <>
      <div className="row g-0 p-2">
          <div className="col-sm-24 col-md-12 pe-2">
            <InputComponentAutocomplete
              id={"organization"}
              name={"organization"}
              required={false}
              showBackground={false}
              value={org}
              wrapInForm={false}
              label={translate("denaliApp.consumptionTransaction.organization")}
              getOptionLabel={option => option?.name ? `${option.designation} - ${option.name}` : ``}
              loadOptions={(inputValue) => lookupOrganizations(`name.contains=${inputValue}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
              onChangeOption={(option) => {
                dispatch(chooseOrganization(option))
              }
              }
            />
          </div>
        <div className="col-sm-24 col-md-12">
          <InputComponentAutocomplete
            id={"warehouse"}
            name={"warehouse"}
            required={false}
            showBackground={false}
            value={warehouse}
            wrapInForm={false}
            label={translate("denaliApp.consumptionTransaction.warehouse")}
            getOptionLabel={option => option?.name ? `${option.designation} - ${option.name}` : ``}
            loadOptions={(inputValue) => lookupWarehouses(`name.contains=${inputValue}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
            onChangeOption={(option) => {
              dispatch(chooseWarehouse(option))
            }
            }
          />
        </div>
      </div>
    </>
  );

  return contentCard;
}

export default ChatsSelectionsView;
