import React, {useEffect} from "react";
import {
  Card,
  CARD_IMPORTANCE,
  Grid,
  Col,
  Row, InputComponentNumber
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {InputComponentText} from "@msvsustavi/msv-ui-react/dist";
import {APP_DECIMAL_SEPARATOR, APP_NUMBER_FORMAT_DECIMAL_SCALE, APP_THOUSAND_SEPARATOR} from "app/config/constants";


const InvoiceHsBaseTransactionPreviewHeader =  () => {

  const content = (
    <Grid>
      <div className="row g-0 p-2">
        <div className="col-sm-18 col-md-18 pe-2">
          <InputComponentText
            id={"transaction.transactionDesignation"}
            name={"transaction.transactionDesignation"}
            label={translate("denaliApp.invoiceHsBaseTransaction.transactionDesignation")}
            required={true}
            showBackground={true}
            editable={false}
          />
        </div>
        <div className="col-sm-6 col-md-6 pe-2">
          <InputComponentNumber
            id={"transaction.m3"}
            name={"transaction.m3"}
            label={translate("denaliApp.invoiceHsBaseTransaction.m3")}
            required={true}
            showBackground={true}
            editable={false}
            thousandSeparator={APP_THOUSAND_SEPARATOR}
            decimalSeparator={APP_DECIMAL_SEPARATOR}
            decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
            styleClassElement={"text-end"}
          />
        </div>
      </div>
      <div className="row g-0 p-2">
        <div className="col-sm-12 col-md-12 pe-2">
          <InputComponentNumber
            id={"transaction.totalValueNoTaxWithExpenses"}
            name={"transaction.totalValueNoTaxWithExpenses"}
            label={translate("denaliApp.invoiceHsBaseTransaction.totalValueNoTaxWithExpenses")}
            required={true}
            showBackground={true}
            editable={false}
            thousandSeparator={APP_THOUSAND_SEPARATOR}
            decimalSeparator={APP_DECIMAL_SEPARATOR}
            decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
            styleClassElement={"text-end"}
          />
        </div>
        <div className="col-sm-12 col-md-12 pe-2">
          <InputComponentNumber
            id={"transaction.totalValueWithTaxNoExpenses"}
            name={"transaction.totalValueWithTaxNoExpenses"}
            label={translate("denaliApp.invoiceHsBaseTransaction.totalValueWithTaxNoExpenses")}
            required={true}
            showBackground={true}
            editable={false}
            thousandSeparator={APP_THOUSAND_SEPARATOR}
            decimalSeparator={APP_DECIMAL_SEPARATOR}
            decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
            styleClassElement={"text-end"}
          />
        </div>
      </div>
      <div className="row g-0 p-2">
        <div className="col-sm-12 col-md-12 pe-2">
          <InputComponentNumber
            id={"transaction.totalValueNoTaxNoExpenses"}
            name={"transaction.totalValueNoTaxNoExpenses"}
            label={translate("denaliApp.invoiceHsBaseTransaction.totalValueNoTaxNoExpenses")}
            required={true}
            showBackground={true}
            editable={false}
            thousandSeparator={APP_THOUSAND_SEPARATOR}
            decimalSeparator={APP_DECIMAL_SEPARATOR}
            decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
            styleClassElement={"text-end"}
          />
        </div>
        <div className="col-sm-12 col-md-12 pe-2">
          <InputComponentNumber
            id={"transaction.manipulativeCosts"}
            name={"transaction.manipulativeCosts"}
            label={translate("denaliApp.invoiceHsBaseTransaction.manipulativeCosts")}
            required={true}
            showBackground={true}
            editable={false}
            thousandSeparator={APP_THOUSAND_SEPARATOR}
            decimalSeparator={APP_DECIMAL_SEPARATOR}
            decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
            styleClassElement={"text-end"}
          />
        </div>
      </div>
    </Grid>
  );


  return (
    <Card
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={content}
    />
  );
};

export default InvoiceHsBaseTransactionPreviewHeader;
