import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {
  getSortState,
  FlatTable,
  FlatTableHeaderCell,
  FlatTableHeader,
  FlatTableDataCell,
  FlatTableDataRow,
  FlatTablePagination,
  FlatTableDataRowActions} from "@msvsustavi/msv-ui-react";
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {IRootState} from "app/shared/reducers";
import { translate} from "react-jhipster";
import {
  APP_DECIMAL_SEPARATOR,
   APP_NUMBER_FORMAT_DECIMAL_SCALE, APP_THOUSAND_SEPARATOR
} from "app/config/constants";
import {
  getEntity,
  setShowDialogEdit
} from "app/shared/reducers/transaction/consumption-transaction.reducer";
import {
  fetchConsumptionsGroupedAllSelection
} from "app/shared/reducers/consumptionGrouped/consumption-grouped.reducer";
import NumberFormat from "react-number-format";
import {
  ConsumptionTransactionStatus,
  ConsumptionTransactionStatusLegend
} from "app/modules/consumptionGrouped/transaction/view/consumption-transaction-status";
import ConsumptionTransactionEditDialog from "app/modules/consumptionGrouped/transaction/edit/consumptionTransactionEdit.dialog";
import {formatDateForDisplay} from "app/shared/util/date-utils";
import ConsumptionGroupedSelection from "app/modules/consumptionGrouped/view/selection/consumptionGrouped.selection";

const ConsumptionGroupedView = (props) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {isAuthenticated,account} = useSelector(({authentication}: IRootState) => authentication);

  const consumptionsGrouped = useSelector(({consumptionGrouped}: IRootState) => consumptionGrouped.entities);
  const consumptionGroupedSearchData = useSelector(({consumptionGrouped}: IRootState) => consumptionGrouped.consumptionGroupedSearchData);
  const total = useSelector(({consumptionGrouped}: IRootState) => consumptionGrouped.totalItems);

  const [paginationState, setPaginationState] = useState(
    {
      ...getSortState(location, ITEMS_PER_PAGE),
      sort: 'id',
      order: 'desc',
      activePage: 0
    }
  );


  const loadTable = () => {
    dispatch(fetchConsumptionsGroupedAllSelection(consumptionGroupedSearchData,account.id, paginationState.activePage, `${paginationState.sort},${paginationState.order}`));
  };

  useEffect( () => {
    loadTable();
  },[])

  const sortTable = () => {
    loadTable();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      history.push(`${location.pathname}${endURL}`);
    }
  };

  const handlePagination = currentPage => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage-1
    });
    history.push(`${location.pathname}?page=${currentPage-1}&sort=${paginationState.sort},${paginationState.order}`);
  };

  useEffect(() => {
    sortTable()
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    dispatch(fetchConsumptionsGroupedAllSelection(consumptionGroupedSearchData,account.id, paginationState.activePage, `${paginationState.sort},${paginationState.order}`));
  }, [consumptionGroupedSearchData]);

  const handleSort = (column, order) => {
    setPaginationState({
      ...paginationState,
      sort: column,
      order: order
    });
    history.push(`${location.pathname}?page=${paginationState.activePage}&sort=${column},${order}`);
  };

  return (
    isAuthenticated ?
    <>
     <ConsumptionGroupedSelection/>
    <div className={"d-flex w-100 flex-column justify-content-end align-items-end"}>
      <div>
        <ConsumptionTransactionStatusLegend/>
      </div>

      <div className={"w-100 px-3 justify-content-end"}>
        <FlatTable>
          <FlatTableHeader>

            <FlatTableHeaderCell  first showSort  onHeaderClick={(sort) => handleSort("organization", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')} className={"col-3"}>
              {translate('denaliApp.consumptionTransaction.organization')}
            </FlatTableHeaderCell>

            <FlatTableHeaderCell showSort  onHeaderClick={(sort) => handleSort("periodEnd", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')} className={"col-4"}>
              {translate('denaliApp.consumptionTransaction.periodEnd')}
            </FlatTableHeaderCell>

            <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("consumptionTransaction", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
              {translate('denaliApp.consumptionGrouped.consumptionTransaction')}
            </FlatTableHeaderCell>

            <FlatTableHeaderCell  showSort onHeaderClick={(sort) => handleSort("variation", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')} className={"col-10"}>
              {translate('denaliApp.consumptionGrouped.variation')}
            </FlatTableHeaderCell>

            <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("quantity", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
              { translate('denaliApp.consumptionGrouped.quantity')}
            </FlatTableHeaderCell>

            <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("status", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
              { translate('denaliApp.consumptionTransaction.status')}
            </FlatTableHeaderCell>

            <FlatTableHeaderCell className={"col-2"}>
            </FlatTableHeaderCell>

          </FlatTableHeader>
          {
            [].concat(consumptionsGrouped) && [].concat(consumptionsGrouped).map(row =>(
              <FlatTableDataRow>

                <FlatTableDataCell type={"center"} showBorder={false} className={"col-3"}>
                  {
                    <div className={"d-flex w-100 flex-column"}>
                      {row.organization.designation} / {row.warehouse.designation}
                    </div>
                  }
                </FlatTableDataCell>

                <FlatTableDataCell type={"center"} showBorder={true} className={"col-4"}>
                  { formatDateForDisplay(row?.consumptionTransaction.periodEnd)}
                </FlatTableDataCell>

                <FlatTableDataCell type={"center"}>
                  {
                    <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
                      <div className={"fw-bold p-1"}>{row?.consumptionTransaction?.id}</div>
                    </div>
                  }
                </FlatTableDataCell>

                <FlatTableDataCell type={"begin"} className={"col-10"}>
                  {
                    <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
                      <div className={"fw-bold  p-1"}>{row?.variation?.variationName}</div>
                    </div>
                  }
                </FlatTableDataCell>


                <FlatTableDataCell type={"center"}>
                  <div className={"d-flex flex-column justify-content-end align-content-center"}>
                    <NumberFormat
                      displayType={'text'}
                      fixedDecimalScale={true}
                      isNumericString={true}
                      className={"text-end"}
                      value={row?.quantity}
                      thousandSeparator={APP_THOUSAND_SEPARATOR}
                      decimalSeparator={APP_DECIMAL_SEPARATOR}
                      decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      suffix={" "+ row?.variation?.uom}
                    />
                  </div>
                </FlatTableDataCell>

                <FlatTableDataCell type={"center"}>
                  {
                    <div className={"d-flex justify-content-center"}>
                      <ConsumptionTransactionStatus status={row?.consumptionTransaction.status}/>
                    </div>
                  }
                </FlatTableDataCell>

                <FlatTableDataRowActions showEdit={true} showDelete={false}  className={"col-2"} onEdit={ () =>
                  {
                    dispatch(getEntity(row.consumptionTransactionId));
                    dispatch(setShowDialogEdit(true))
                  }
                }/>

              </FlatTableDataRow>

            ))
          }
          <FlatTablePagination activePage={paginationState.activePage + 1} onSelect={(page) => {
            handlePagination(page)
          }} maxButtons={4} totalItems={total} itemsPerPage={ITEMS_PER_PAGE} showPagination={true}/>
        </FlatTable>
      </div>

    </div><ConsumptionTransactionEditDialog/>
    </>: <></>
  );
};

export default ConsumptionGroupedView;


