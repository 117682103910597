import React from "react";
import {
  Col,
  FormInputCrud,
  Grid, InputComponentBoolean,
  InputComponentText,
  Row
} from "@msvsustavi/msv-ui-react/dist";
import {translate} from "react-jhipster";
import {createOrganization, deleteOrganization, getOrganization, reset, updateOrganization} from "app/shared/reducers/master/organization/organization.reducer";
import {IRootState} from "app/shared/reducers";
import {connect} from "react-redux";
import {navigation} from "app/shared/util/navigation.constants";


export interface IOrganizationEditProps extends StateProps, DispatchProps {
}

export const OrganizationEdit = (props : IOrganizationEditProps) => {

  const content = (
    <Grid>
      <Row>
        <Col span={6}>
          <InputComponentText
            id={"designation"}
            name={"designation"}
            label={translate('denaliApp.organization.designation')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={18}>
          <InputComponentText
            id={"name"}
            name={"name"}
            label={translate('denaliApp.organization.name')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={8}>
          <InputComponentBoolean
            id={"active"}
            name={"active"}
            label={translate('denaliApp.organization.active')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={16}>
          <InputComponentText
            id={"rest_url"}
            name={"rest_url"}
            label={translate('denaliApp.organization.rest_url')}
            required={true}
            visible={true}
          />
        </Col>
      </Row>
    </Grid>
  );

  return (
    <FormInputCrud
      entity={props.entity}
      updating={props.updating}
      updateSuccess={props.updateSuccess}
      loading={props.loading}
      errorMessage={props.errorMsg}

      navigationOnClose={navigation.master.organization.view}


      component={content}

      titleForm={translate('denaliApp.organization.home.createOrEditLabel')}
      titleCard={translate('denaliApp.organization.home.title')}
      labelButtonDelete={translate('entity.action.delete')}
      labelButtonSave={translate('entity.action.save')}
      labelButtonCancel={translate('entity.action.cancel')}

      createEntity={props.createOrganization}
      updateEntity={props.updateOrganization}
      deleteEntity={props.deleteOrganization}
      getEntity={props.getOrganization}
      reset={props.reset}
    />
  );
}

const mapStateToProps = ({organization}: IRootState) => ({
  entity: organization.entity,
  updating: organization.updating,
  updateSuccess: organization.updateSuccess,
  errorMsg: organization.errorMessage,
  loading: organization.loading
});

const mapDispatchToProps = {reset, getOrganization, createOrganization, updateOrganization, deleteOrganization};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationEdit);

