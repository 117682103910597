import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {
  FlatTable,
  FlatTableDataCell,
  FlatTableDataRow,
  FlatTableDataRowActions,
  FlatTableHeader,
  FlatTableHeaderCell, FlatTablePagination,
  getSortState,
} from "@msvsustavi/msv-ui-react";
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {IRootState} from "app/shared/reducers";
import { translate} from "react-jhipster";
import NumberFormat from "react-number-format";
import {
  APP_THOUSAND_SEPARATOR,
  APP_DECIMAL_SEPARATOR,
  APP_NUMBER_FORMAT_DECIMAL_SCALE,
} from "app/config/constants";
import {
  deleteConsumption, fetchConsumptionsAllSelection
} from "app/shared/reducers/consumption/view/consumption.view";
import ConsumptionEditDialog from "app/modules/consumption/edit/consumptionEdit.dialog";
import {
  setConsumtion,
  showEditDialog
} from "app/shared/reducers/consumption/edit/consumption.edit";
import {formatDateForDisplay} from "app/shared/util/date-utils";
import {ConsumptionOnTransaction} from "app/modules/consumptionGrouped/transaction/view/consumption-on-transaction";
import ConsumptionSelection from "app/modules/consumption/view/selection/consumption.selection";

const ConsumptionsView = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {isAuthenticated,account} = useSelector(({authentication}: IRootState) => authentication);
  const consumptions = useSelector(({consumption}: IRootState) => consumption.view.consumptions);
  const consumptionSearchData = useSelector(({consumption}: IRootState) => consumption.view.consumptionSearchData);
  const total = useSelector(({consumption}: IRootState) => consumption.view.totalItems);

  const [paginationState, setPaginationState] = useState(
    {
      ...getSortState(location, ITEMS_PER_PAGE),
      sort: 'id',
      order: 'desc',
      activePage: 0
    }
  );

  const loadTable = () => {
    dispatch(fetchConsumptionsAllSelection(consumptionSearchData,account.id, paginationState.activePage, `${paginationState.sort},${paginationState.order}`));
  };

  useEffect( () => {
    loadTable();
  },[])

  const sortTable = () => {
    loadTable();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      history.push(`${location.pathname}${endURL}`);
    }
  };

  const handlePagination = currentPage => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage -1
    });
    history.push(`${location.pathname}?page=${currentPage -1}&sort=${paginationState.sort},${paginationState.order}`);
  };

  useEffect(() => {
    sortTable()
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    dispatch(fetchConsumptionsAllSelection(consumptionSearchData,account.id, paginationState.activePage, `${paginationState.sort},${paginationState.order}`));
  }, [consumptionSearchData]);

  const handleSort = (column, order) => {
    setPaginationState({
      ...paginationState,
      sort: column,
      order: order
    });
    history.push(`${location.pathname}?page=${paginationState.activePage}&sort=${column},${order}`);
  };


  return (
    isAuthenticated ?
    <>
      <ConsumptionSelection/>
      <div className={"d-flex w-100 flex-column justify-content-end align-items-end"}>
        <div className={"w-100 px-3 justify-content-end"}>
          <FlatTable>
            <FlatTableHeader>

              <FlatTableHeaderCell  first showSort onHeaderClick={(sort) => handleSort("organization", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')} className={"col-3"}>
                {translate('denaliApp.consumption.organization')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("consumptionDate", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')} className={"col-4"}>
                {translate('denaliApp.consumption.consumptionDate')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("plate", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')} className={"col-2"}>
                {translate('denaliApp.consumption.locationDocPlate')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell  showSort onHeaderClick={(sort) => handleSort("variation", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}  className={"col-8"}>
                {translate('denaliApp.consumption.variation')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("quantity", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                { translate('denaliApp.consumptionGrouped.quantity')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.consumption.grouped')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
              </FlatTableHeaderCell>

            </FlatTableHeader>
            {
              [].concat(consumptions) && [].concat(consumptions).map(row =>(
                <FlatTableDataRow>

                  <FlatTableDataCell type={"center"} showBorder={false} className={"col-3"}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.organization.designation} / {row.warehouse.designation}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"center"} showBorder={true} className={"col-4"}>
                    { formatDateForDisplay(row?.consumptionDate)}
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"center"} showBorder={true} className={"col-2"} >
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row?.location !== null ? row?.location : " "}  {row?.document !== null ? row?.document : "-"}  {row?.uqDesignation !== null ? row?.uqDesignation : " "}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"} className={"col-8"}>
                    {
                      <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
                        <div className={"fw-bold  p-1"}>{row?.variation?.variationName}</div>
                      </div>
                    }
                  </FlatTableDataCell>


                  <FlatTableDataCell type={"center"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row?.quantity}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                        suffix={" "+ row?.variation?.uom}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    {
                      <div className={"d-flex justify-content-center"}>
                        <ConsumptionOnTransaction onTransaction={row?.consumptionTransactionId != null} transactionId={row?.consumptionTransactionId != null ? row?.consumptionTransactionId : ""}/>
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataRowActions showEdit={row?.consumptionTransactionId === null} showDelete={row?.consumptionTransactionId === null}  className={"col-2"} onEdit={ () =>
                  {
                    dispatch(setConsumtion(row));
                    dispatch(showEditDialog(true))
                  }
                  } onDelete={ () => {
                    dispatch(deleteConsumption(row.id))}
                  }/>

                </FlatTableDataRow>

              ))
            }
            <FlatTablePagination activePage={paginationState.activePage+1} onSelect={(page) => {
              handlePagination(page)
            }} maxButtons={4} totalItems={total} itemsPerPage={ITEMS_PER_PAGE} showPagination={true}/>
          </FlatTable>
        </div>
        <ConsumptionEditDialog/>
      </div>
    </>: <></>
  );
};

export default ConsumptionsView;
