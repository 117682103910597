import axios from 'axios';
import { ICrudGetAllAction } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import {
  IInvoiceHsBaseTransactionQuantityPartner,
  defaultValue,
} from 'app/shared/model/invoiceHsBase/transaction/quantityPartner/invoice-hs-base-transaction-quantity-partner.model';

export const ACTION_TYPES = {
  FETCH_INVOICEHSBASETRANSACTIONQUANTITYPARTNER_LIST:
    'invoiceHsBaseTransactionQuantityPartner/FETCH_INVOICEHSBASETRANSACTIONQUANTITYPARTNER_LIST',
  RESET: 'invoiceHsBaseTransactionQuantityPartner/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  quantitysByPartner: [] as ReadonlyArray<IInvoiceHsBaseTransactionQuantityPartner>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type InvoiceHsBaseTransactionQuantityPartnerState = Readonly<typeof initialState>;

// Reducer

export default (
  state: InvoiceHsBaseTransactionQuantityPartnerState = initialState,
  action
): InvoiceHsBaseTransactionQuantityPartnerState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INVOICEHSBASETRANSACTIONQUANTITYPARTNER_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_INVOICEHSBASETRANSACTIONQUANTITYPARTNER_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVOICEHSBASETRANSACTIONQUANTITYPARTNER_LIST):
      return {
        ...state,
        loading: false,
        quantitysByPartner: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/invoice-hs-base-transaction-quantity-partners';
// Actions

export const getQuantityByPartners: ICrudGetAllAction<IInvoiceHsBaseTransactionQuantityPartner> = id => {
  const requestUrl = `${apiUrl}/transaction/${id}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICEHSBASETRANSACTIONQUANTITYPARTNER_LIST,
    payload: axios.get<IInvoiceHsBaseTransactionQuantityPartner>(`${requestUrl}`),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
