import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IRootState} from 'app/shared/reducers';
import {
  ACTION_TYPE,
  BUTTON_SHAPE,
  FormTable,
  isComplexQuery,
  LAYOUT_TYPE,
  parseQuery
} from '@msvsustavi/msv-ui-react/dist';
import {translate} from "react-jhipster";
import {navigation} from "app/shared/util/navigation.constants";

import {OrganizationDataTableColumns} from "app/shared/model/master/organization/organization.column.model";
import {getOrganizations, getSearchOrganizations} from "app/shared/reducers/master/organization/organization.reducer";

export const OrganizationView = () => {

  const dispatch = useDispatch();
  const organizations = useSelector(({organization}: IRootState) => organization.organizations);
  const count = useSelector(({organization}: IRootState) => organization.count);
  const loading = useSelector(({organization}: IRootState) => organization.loading);


  return <FormTable
    loadEntities={
      (activepage, itemsPerPage, sort) => {
        return dispatch(getOrganizations(activepage - 1, itemsPerPage, sort));
      }
    }
    filterEntities={
      (query, activepage, itemsPerPage, sort) => {
        return dispatch(getSearchOrganizations(isComplexQuery(query) ? parseQuery(query) : `name.contains=${query}`, activepage - 1, itemsPerPage, sort));
      }
    }
    columns={OrganizationDataTableColumns}
    items={organizations}
    count={count}
    exportEntities={() => {
    }}
    actions={[
      {
        label: translate('denaliApp.organization.home.createLabel'),
        showLabel: true,
        icon: 'icon-add',
        name: 'new-country',
        type: ACTION_TYPE.LINK,
        layout: LAYOUT_TYPE.TOP_ICON_BOTTOM_TEXT,
        shape: BUTTON_SHAPE.ROUNDED,
        link: navigation.master.organization.new
      }
    ]}
    loading={loading}
  />;

}


export default OrganizationView;
