import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";

import { translate} from "react-jhipster";

import {InputComponentAutocomplete, InputComponentText, LIST_ITEMS_PER_PAGE} from "@msvsustavi/msv-ui-react";
import {lookupOrganizations} from "app/shared/lookups/organization/organization.lookup";
import {chooseOrganization,setSearchData} from "app/shared/reducers/invoiceHsBase/base/base/invoice-hs-base.reducer";
import {Formik} from 'formik';
/**
 *
 */
const BasesSelection = () => {
  const dispatch = useDispatch();

  const {basesSearchData,organization} = useSelector(({invoiceHsBase}: IRootState) =>invoiceHsBase);

  const [values, setValues] = useState(basesSearchData);
  useEffect(()=>{setSearchData(basesSearchData)},[basesSearchData]);


  return (<Formik
    initialValues={basesSearchData}
    enableReinitialize
    onSubmit={e => {
      dispatch(setSearchData(values))
    }}
  >
    {formik => (
      <form onChange={formik.handleSubmit}>
            <div className={"row g-0 px-3"}>
              <div className={"col-12"}>
                <InputComponentText
                  id={"designation"}
                  name={"designation"}
                  wrapInForm={false}
                  required={false}
                  showBackground={false}
                  label={translate("denaliApp.invoiceHsBase.designation")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      designation: e.target.value
                    });
                  }}
                />
              </div>
            </div>
      </form>
    )}
  </Formik>
  );
};

export default BasesSelection;

/**<div className={"col-12"}>
  <InputComponentAutocomplete
    id={"organization"}
    name={"organization"}
    required={true}
    showBackground={false}
    value = {organization}
    wrapInForm={false}
    label={translate("denaliApp.invoiceHsBase.organization")}
    getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
    loadOptions={(inputValue) => lookupOrganizations(`${inputValue}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
    onChangeOption={(option) =>{
      setValues({
        ...values,
        organizationId : option.id
      })
      dispatch(chooseOrganization(option));
    }
    }
  />
</div>**/
