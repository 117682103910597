import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';

export const ACTION_TYPES = {
  CHOOSE_ORGANIZATION: 'dashboard-selctions/CHOOSE_ORGANIZATION',
  CHOOSE_WAREHOUSE: 'dashboard-selctions/CHOOSE_WAREHOUSE',
};

const initialState = {
  organization: null as Readonly<IOrganization>,
  warehouse: null as Readonly<IWarehouse>,
};

export type DashboardSelectionsState = Readonly<typeof initialState>;

export default (state: DashboardSelectionsState = initialState, action): DashboardSelectionsState => {
  switch (action.type) {
    case ACTION_TYPES.CHOOSE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case ACTION_TYPES.CHOOSE_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    default:
      return state;
  }
};

export const chooseOrganization = (organization: IOrganization) => {
  return {
    type: ACTION_TYPES.CHOOSE_ORGANIZATION,
    payload: organization,
  };
};

export const chooseWarehouse = (wh: IWarehouse) => {
  return {
    type: ACTION_TYPES.CHOOSE_WAREHOUSE,
    payload: wh,
  };
};
