import React, {useEffect} from "react";
import {IRootState} from "app/shared/reducers";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";

import InsertForm from "app/shared/util/insert-form";

import InputHeader from "app/modules/input/insert/header/input.insert.header";
import {navigation} from "app/shared/util/navigation.constants";
import {useHistory} from "react-router-dom";
import {useOrganization} from "app/shared/util/use-organization";
import {createInput, reset} from "app/shared/reducers/input/insert/input.insert";
import PopratnicaEntryDialog from "app/modules/input/insert/choosePopratnica/popratnicaEntry.dialog";
import InputInsertType from "app/modules/input/insert/chooseInsertType/input.insert.type";
import PopratnicaInsertDetails from "app/modules/input/insert/popratnicaDetails/popratnica.insert.details";
import PopratnicaTransportInsertDetails from "app/modules/input/insert/popratnicaDetails/popratnica.transport.insert.details";
import moment from "moment";



const InputInsertCard = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const {organization,isRestricted} = useOrganization();

  const {
    input,
    organization :org,
    warehouse,
  popratnicaForInsert,showManualEntry} = useSelector(({input}: IRootState) => input.insert);

  useEffect(() => {
    dispatch(reset());
  }, []);


  return (
     <div className={"d-flex w-100 justify-content-center align-items-start"}>
       <Formik
         enableReinitialize={true}
         initialValues={{input,}}
         onSubmit={(values) => {
         dispatch(createInput(
         {
           ...values.input,
           organizationId : org.id,
           warehouseId : warehouse.id,
           variationId : 1,
           quantity : 0.00,
           inputDate : moment(values.input.inputDate).set({"hour": 12, "minute": 0})
         }));
         history.push(navigation.consumption.tabs.input);
       }}>
         {formik => (
           <InsertForm handleSubmit={formik.handleSubmit} title={"denaliApp.input.insert"} >
             <InputInsertType/>
             <PopratnicaEntryDialog/>
             {popratnicaForInsert !== null || showManualEntry?
               <>
                <InputHeader/>
                <PopratnicaInsertDetails/>
                <PopratnicaTransportInsertDetails/>
               </> : ''
               }
           </InsertForm>
         )}
       </Formik>
     </div>
  );
}


export default InputInsertCard;
