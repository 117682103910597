import React, {FormEventHandler} from "react";
import {HeadlineH0} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import SaveCancelButtons from "app/shared/util/save-cancel-buttons";

/**
 *  InsertForm
 *
 */
export interface IInsertFormProps{
  handleSubmit?: FormEventHandler;
  title?:string;
  showSaveButtons?: boolean;
}

export default class InsertForm extends React.Component<IInsertFormProps> {
  static defaultProps = { showSaveButtons: true };
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <form className={"d-flex w-100 px-5 flex-column"} onSubmit={this.props.handleSubmit}>
      <HeadlineH0 rootClassName={"w-100"} headline={translate(this.props.title)}/>
        {this.props.children}
        {this.props.showSaveButtons?<SaveCancelButtons/>:<></>}
    </form>
    );
  }
};

