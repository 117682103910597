import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {navigation} from "app/shared/util/navigation.constants";

import {UserOrganizationWarehouseEdit} from "app/modules/master/user-organization-warehouse/userOrganizationWarehouse.edit";
import UserOrganizationWarehouseView
  from "app/modules/master/user-organization-warehouse/userOrganizationWarehouse.view";


const Routes = (match) => (
    <Switch>
      <Route path={navigation.master.userOrganizationWarehouse.edit} exact component={UserOrganizationWarehouseEdit}/>
      <Route path={navigation.master.userOrganizationWarehouse.view} component={UserOrganizationWarehouseView}/>
    </Switch>
);

export default Routes;
