import React, {useEffect} from "react";
import {Modal, ModalBody} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import ManualEntryDialogComponent from "app/modules/consumption/insert/dialogs/manualEntry/manuelEntry.dialog.component";
import {setVariationInput} from "app/shared/reducers/consumption/insert/consumption.insert";
import {reset} from "app/entities/consumption/consumption.reducer";

const ManualEntryDialog = (props) => {
  const dispatch = useDispatch();
  const showDialog = useSelector(({consumption}:IRootState)=>consumption.insert.variationInput);


  return (
    <Modal isOpen={showDialog}
           toggle={() => dispatch(setVariationInput(!showDialog))}
           modalTransition={{timeout: 300}}
           backdropTransition={{timeout: 600}}
           centered={true}
           keyboard={true}
           fade={true}
           size={'lg'}
    >
      <ModalBody>
        <ManualEntryDialogComponent/>
      </ModalBody>
    </Modal>
  );
};

export default ManualEntryDialog;
