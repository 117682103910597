import React from "react";
import {Modal, ModalBody} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import ManualEntryDialogComponent from "app/modules/consumption/insert/dialogs/manualEntry/manuelEntry.dialog.component";
import {setShowDialog, setShowDialogEdit} from "app/shared/reducers/transaction/consumption-transaction.reducer";
import ConsumptionTransactionEntryDialogComponent
  from "app/modules/consumptionGrouped/transaction/insert/consumptinTransactionEntry.dialog.component";
import ConsumptionTransactionEditDialogComponent
  from "app/modules/consumptionGrouped/transaction/edit/consumptinTransactionEdit.dialog.component";

const ConsumptionTransactionEditDialog = (props) => {
  const dispatch = useDispatch();

  const showDialogEdit = useSelector(({consumptionTransaction}:IRootState)=>consumptionTransaction.show_dialog_edit);


  return (
    <Modal isOpen={showDialogEdit}
           toggle={() => dispatch(setShowDialogEdit(!showDialogEdit))}
           modalTransition={{timeout: 300}}
           backdropTransition={{timeout: 600}}
           centered={true}
           keyboard={true}
           fade={true}
           size={'lg'}
    >
      <ModalBody>
        <ConsumptionTransactionEditDialogComponent/>
      </ModalBody>
    </Modal>
  );
};

export default ConsumptionTransactionEditDialog;
