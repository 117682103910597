import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {navigation} from "app/shared/util/navigation.constants";
import OrganizationView from "app/modules/master/organization/organization.view";
import OrganizationEdit from "app/modules/master/organization/organization.edit";


const Routes = (match) => (
    <Switch>
      <Route path={navigation.master.organization.edit} exact component={OrganizationEdit}/>
      <Route path={navigation.master.organization.view} component={OrganizationView}/>
    </Switch>
);

export default Routes;
