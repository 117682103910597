export interface IInvoiceHsBaseTransactionTotal {
  id?: number;
  priceLength?: string;
  woodTypeName?: string;
  priceRange?: string;
  className?: string;
  fee?: number;
  unitPrice?: number;
  m3?: number;
  totalValueNoTaxWithExpenses?: number;
  totalValueWithTaxNoExpenses?: number;
  totalValueNoTaxNoExpenses?: number;
  manipulativeCosts?: number;
  invoiceHsBaseTransactionId?: number;
}

export const defaultValue: Readonly<IInvoiceHsBaseTransactionTotal> = {};
