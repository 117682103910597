import React from "react";
import {Modal, ModalBody} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import {setShowChooseInsertDialog} from "app/shared/reducers/dashboard/dashboard-choose-insert.reducer";
import InputChooseContentDialogComponent
  from "app/modules/home/insertChooseDialog/insertChooseContent.dialog.component";

const InsertChooseDialog = (props) => {
  const dispatch = useDispatch();

  const showChooseInsertDialog = useSelector(({dashboardChooseInsert}:IRootState)=> dashboardChooseInsert.showChooseInsertDialog);


  return (
    <Modal isOpen={showChooseInsertDialog}
           toggle={() => dispatch(setShowChooseInsertDialog(!showChooseInsertDialog))}
           modalTransition={{timeout: 300}}
           backdropTransition={{timeout: 600}}
           centered={true}
           keyboard={true}
           fade={true}
           size={'lg'}
    >
      <ModalBody>
        <InputChooseContentDialogComponent/>
      </ModalBody>
    </Modal>
  );
};

export default InsertChooseDialog;
