import {
  defaultValue,
  IInvoiceHsBaseTransaction,
} from 'app/shared/model/invoiceHsBase/transaction/transaction/invoice-hs-base-transaction.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { ITrupciFakture } from 'app/shared/model/nabavawrapper/trupci-fakture.model';
import { IPOPRATNICE } from 'app/shared/model/nabavawrapper/popratnice.model';
import { ITRUPCI } from 'app/shared/model/nabavawrapper/trupci.model';
import { ICrudPutAction } from 'react-jhipster';
import axios from 'axios';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IInvoiceHsSelected } from 'app/shared/model/invoiceHsBase/transaction/selected/invoice-hs-selected.model';
import { IPodlogaFakture } from 'app/shared/model/nabavawrapper/podloga-fakture.model';
import { IPodlogaFaktureUkupno } from 'app/shared/model/nabavawrapper/podloga-fakture-ukupno.model';
import { IInvoiceHsBaseTransactionTotal } from 'app/shared/model/invoiceHsBase/transaction/total/invoice-hs-base-transaction-total.model';
import { IPodlogaFakturePartner } from 'app/shared/model/nabavawrapper/podloga-fakture-partner.model';
import { IInvoiceHsBaseTransactionQuantityPartner } from 'app/shared/model/invoiceHsBase/transaction/quantityPartner/invoice-hs-base-transaction-quantity-partner.model';
import { IVariation } from 'app/shared/model/master/variation/variation.model';
import { getTransaction } from 'app/shared/reducers/invoiceHsBase/transaction/transaction/invoice-hs-base-transaction.reducer';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';

/**
 * Reducer za unos transakcije jedne.
 * Unos zaglavlja te punjenje svih transakcijskih tablica za invoice hs base.
 * Brisanja kao takvog nema, samo ćemo status postavit obrisano.
 */
export const ACTION_TYPES = {
  CREATE_INVOICEHSBASETRANSACTION: 'invoiceHsBaseTransactionInsert/CREATE_INVOICEHSBASETRANSACTION',
  CREATE_INVOICEHSSELECTED: 'invoiceHsBaseTransactionInsert/CREATE_INVOICEHSSELECTED',
  CREATE_INVOICEHSBASETRANSACTIONTOTAL: 'invoiceHsBaseTransactionInsert/CREATE_INVOICEHSBASETRANSACTIONTOTAL',
  CREATE_INVOICEHSBASETRANSACTIONQUANTITYPARTNER: 'invoiceHsBaseTransactionInsert/CREATE_INVOICEHSBASETRANSACTIONQUANTITYPARTNER',
  ADD_INVOICE_FOR_INSERT: 'invoiceHsBaseTransactionInsert/ADD_INVOICE_FOR_INSERT',
  REMOVE_INVOICE_FOR_INSERT: 'invoiceHsBaseTransactionInsert/REMOVE_INVOICE_FOR_INSERT',
  FETCH_INVOICEHSBASETRANSACTION: 'invoiceHsBaseTransactionInsert/REMOVE_INVOICE_FOR_INSERT',
  SET_FILLEDTRANSACTION: 'invoiceHsBaseTransactionInsert/SET_FILLEDTRANSACTION',
  SET_FILLEDPARTNERS: 'invoiceHsBaseTransactionInsert/SET_FILLEDPARTNERS',
  SET_FILLEDTOTALS: 'invoiceHsBaseTransactionInsert/SET_FILLEDTOTALS',
  SET_LOADING: 'invoiceHsBaseTransactionInsert/SET_LOADING',
  RESET: 'invoiceHsBaseTransaction/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  updating: false,
  transaction: defaultValue,
  filledTransaction: false,
  filledTotals: false,
  filledPartners: false,
  selectedInvoices: [] as ReadonlyArray<ITrupciFakture>,
};

export type InvoiceHsBaseTransactionInsertState = Readonly<typeof initialState>;

export default (state: InvoiceHsBaseTransactionInsertState = initialState, action): InvoiceHsBaseTransactionInsertState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_INVOICEHSBASETRANSACTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_INVOICEHSBASETRANSACTION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_INVOICEHSBASETRANSACTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        transaction: action.payload.data,
      };
    case ACTION_TYPES.ADD_INVOICE_FOR_INSERT: {
      const invoices = [].concat(state.selectedInvoices);
      const n: ITRUPCI = action.payload;
      invoices.push(n);
      return {
        ...state,
        selectedInvoices: invoices,
      };
    }
    case ACTION_TYPES.REMOVE_INVOICE_FOR_INSERT: {
      const invoices = state.selectedInvoices.filter(value => value.id !== action.payload.id);
      return {
        ...state,
        selectedInvoices: invoices,
      };
    }
    case ACTION_TYPES.SET_FILLEDTRANSACTION: {
      return {
        ...state,
        filledTransaction: action.payload,
      };
    }
    case ACTION_TYPES.SET_FILLEDPARTNERS: {
      return {
        ...state,
        filledPartners: action.payload,
      };
    }
    case ACTION_TYPES.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case ACTION_TYPES.SET_FILLEDTOTALS: {
      return {
        ...state,
        filledTotals: action.payload,
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrlHsBaseTransaction = 'api/invoice-hs-base-transactions';
const apiUrlHsBaseSelectedInvoices = 'api/invoice-hs-selecteds';
const apiUrlBaseTotal = 'api/invoice-hs-base-transaction-totals';
const apiUrlBasePartner = 'api/invoice-hs-base-transaction-quantity-partners';

export const createInvoiceHsBaseTransaction: ICrudPutAction<IInvoiceHsBaseTransaction> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INVOICEHSBASETRANSACTION,
    payload: axios.post(apiUrlHsBaseTransaction, cleanEntity(entity)),
  });
  return result;
};

export const createInvoiceHsBaseSelected: ICrudPutAction<IInvoiceHsSelected> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INVOICEHSSELECTED,
    payload: axios.post(apiUrlHsBaseSelectedInvoices, cleanEntity(entity)),
  });
  return result;
};

export const createBaseTotal: ICrudPutAction<IInvoiceHsBaseTransactionTotal> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INVOICEHSBASETRANSACTIONTOTAL,
    payload: axios.post(apiUrlBaseTotal, cleanEntity(entity)),
  });
  return result;
};

export const createHsBasePartner: ICrudPutAction<IInvoiceHsBaseTransactionQuantityPartner> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INVOICEHSBASETRANSACTIONQUANTITYPARTNER,
    payload: axios.post(apiUrlFakturePodlogaPartner, cleanEntity(entity)),
  });
  return result;
};

export const setFilledTransaction = filledTransactionBoolean => {
  return {
    type: ACTION_TYPES.SET_FILLEDTRANSACTION,
    payload: filledTransactionBoolean,
  };
};

export const setFilledPartners = filledPartnersBoolean => {
  return {
    type: ACTION_TYPES.SET_FILLEDPARTNERS,
    payload: filledPartnersBoolean,
  };
};

export const setFilledTotals = filledTotalsBoolean => {
  return {
    type: ACTION_TYPES.SET_FILLEDTOTALS,
    payload: filledTotalsBoolean,
  };
};

export const setLoading = laoding => {
  return {
    type: ACTION_TYPES.SET_LOADING,
    payload: laoding,
  };
};

export const addInvoice = (invoice: ITrupciFakture) => {
  return {
    type: ACTION_TYPES.ADD_INVOICE_FOR_INSERT,
    payload: invoice,
  };
};

export const removeInvoice = (invoice: ITrupciFakture) => {
  return {
    type: ACTION_TYPES.REMOVE_INVOICE_FOR_INSERT,
    payload: invoice,
  };
};

export const saveTransaction = transaction => dispatch => {
  createInvoiceHsBaseTransaction(transaction);
};

export const saveBaseTotal = baseTotal => dispatch => {
  createBaseTotal(baseTotal);
};

const apiUrlNabavaWrapperPodlogaFaktureDeleteAndInsert = 'services/nabavawrapper/api/podloga-faktures';
const apiUrlFakturePodlogaUkupno = 'services/nabavawrapper/api/podloga-fakture-ukupnos';
const apiUrlFakturePodlogaPartner = 'services/nabavawrapper/api/podloga-fakture-partners';
const apiUrlOrg = 'api/organizations';
const apiUrlTransaction = 'api/invoice-hs-base-transactions';

export const saveSelectedInvoice = (transaction, invoicesForBase: ITrupciFakture[]) => async dispatch => {
  let transId = 0;
  dispatch(setLoading(true));
  dispatch(createInvoiceHsBaseTransaction(transaction))
    .then(entity => {
      transId = entity.value.data.id;
      invoicesForBase.map(trupacFaktura => {
        const requestUrlOrg = `${apiUrlOrg}/by_name/${trupacFaktura.organizacija}`;
        axios.get<IOrganization>(requestUrlOrg).then(org => {
          const invoiceHsSelected: IInvoiceHsSelected = {
            documentNumber: trupacFaktura.brojDokumenta,
            generatedInvoiceDesignation: trupacFaktura.generiranaOznakaFakture,
            invoiceHsBaseTransactionId: transId,
            invoiceDesignation: trupacFaktura.oznakaFakture,
            organizationId: org.data.id,
            partnerName: trupacFaktura.partner,
            value : trupacFaktura.iznos
          };
          dispatch(createInvoiceHsBaseSelected(invoiceHsSelected));
        });
      });
    })
    .then(() => {
      const fakture = [];
      invoicesForBase.map(trupacFaktura => {
        const podlogaFaktura: IPodlogaFakture = {
          oznakaFakture: trupacFaktura.brojDokumenta,
        };
        fakture.push(podlogaFaktura);
      });
      axios
        .post(apiUrlNabavaWrapperPodlogaFaktureDeleteAndInsert, fakture)
        .then(() => {
          axios
            .get<IPodlogaFaktureUkupno[]>(apiUrlFakturePodlogaUkupno)
            .then(res => {
              res.data &&
                res.data.map(ukupno => {
                  const hsBaseTotal: IInvoiceHsBaseTransactionTotal = {
                    fee: ukupno.naknada,
                    invoiceHsBaseTransactionId: transId,
                    className: ukupno.kl,
                    manipulativeCosts: ukupno.manipTr,
                    woodTypeName: ukupno.vd,
                    priceLength: ukupno.cd,
                    priceRange: ukupno.cr,
                    totalValueNoTaxNoExpenses: ukupno.ukIznosBezPdvBezManipTroskova,
                    totalValueNoTaxWithExpenses: ukupno.ukIznosBezPdvSManipTroskovima,
                    totalValueWithTaxNoExpenses: ukupno.ukIznosSPdvBezManipTroskova,
                    unitPrice: ukupno.jedCijena,
                    m3: ukupno.m3
                  };
                  axios.post(apiUrlBaseTotal, hsBaseTotal);
                });
            })
            .then(() => axios.get(`${apiUrlTransaction}/${transId}/sum_totals`))
            .then(() => {
              dispatch(getTransaction(transId));
            })
            .then(() => {
              dispatch(setFilledTotals(true));
            });
        })
        .then(() => {
          axios
            .get<IPodlogaFakturePartner[]>(apiUrlFakturePodlogaPartner)
            .then(res => {
              res.data &&
                res.data.map(par => {
                  const requestUrlOrg = `${apiUrlOrg}/by_name/${par.parProiz}`;
                  axios.get<IOrganization>(requestUrlOrg).then(org => {
                    const hsBasePartner: IInvoiceHsBaseTransactionQuantityPartner = {
                      invoiceHsBaseTransactionId: transId,
                      woodTypeName: par.vd,
                      priceLength: par.cd,
                      priceRange: par.cr,
                      className: par.kl,
                      organizationId: org.data.id,
                      quantity: par.m3,
                    };
                    axios.post(apiUrlBasePartner, hsBasePartner);
                  });
                });
            })
            .then(() => {
              dispatch(setFilledPartners(true)).then(() => {
                dispatch(setLoading(false));
              });
            });
        });
    })
    .then(() => {
      dispatch(setFilledTransaction(true));
    });
};
