import React, {useEffect} from "react";
import {Formik} from "formik";
import {
  Grid,
  HeadlineH0,
  ActionButton,
  STANDARD_SAVE_SUBMIT_PROPS,
  InputComponentText,
  InputComponentBoolean,
  HeadlineH3,
  InputComponentAutocomplete,
  LIST_ITEMS_PER_PAGE,
  InputComponentDate
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {useDispatch, useSelector} from "react-redux";
import {
   showEditDialog, updateInput
} from "app/shared/reducers/input/edit/input.edit";
import {IRootState} from "app/shared/reducers";
import {lookupOrganizations, lookupOrganizationsSelection} from "app/shared/lookups/organization/organization.lookup";
import {chooseOrganization, chooseWarehouse, reset} from "app/shared/reducers/input/insert/input.insert";
import {lookupWarehouses} from "app/shared/lookups/warehouse/warehouse.lookup";
import {APP_LOCAL_DATE_FORMAT_INPUT} from "app/config/constants";
import moment from "moment";




const InputEditDialogComponent = () => {

  const dispatch = useDispatch();
  const inputEdit = useSelector(({input}:IRootState)=>input.edit.input);
  const organization = useSelector(({input}:IRootState)=>input.edit.organization);
  const warehouse = useSelector(({input}:IRootState)=>input.edit.warehouse);
  const {account} = useSelector(({authentication}: IRootState) => authentication);

  return (
    <Formik
      initialValues={
         inputEdit
      }
      enableReinitialize={true}
      onSubmit={(inputEdit, actions) => {
          dispatch(updateInput({
            ...inputEdit,
            organizationId : organization === null ? inputEdit.organizationId  : organization.id,
            warehouseId : warehouse === null ? inputEdit.warehouseId  : warehouse.id,
            inputDate : moment(inputEdit.inputDate).set({"hour": 12, "minute": 0})
            }
          ));
          dispatch(showEditDialog(false));
      }}
    >
      {
        formik => (
          <form onSubmit={formik.handleSubmit}>
            <Grid>
              <HeadlineH0 headline={translate("denaliApp.input.inputEdit")}/>
              <div className = "row g-0 p-2">
                <div className = "col-sm-24 col-md-24 pe-2">
                  <InputComponentAutocomplete
                    id={"organization"}
                    name={"organization"}
                    required={true}
                    showBackground={true}
                    value={inputEdit.organization}
                    label={translate("denaliApp.input.organization")}
                    getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
                    loadOptions={(inputValue) => lookupOrganizationsSelection( 0, LIST_ITEMS_PER_PAGE, 'asc',account.id, inputValue != "" ? inputValue : null).then(res => res.data)}
                    onChangeOption={(option) =>{
                      dispatch(chooseOrganization(option));
                    }
                    }
                  />
                </div>
              </div>
              <div className = "row g-0 p-2">
                <div className = "col-sm-24 col-md-24 pe-2">
                  <InputComponentAutocomplete
                    id={"warehouse"}
                    name={"warehouse"}
                    required={true}
                    showBackground={true}
                    value={inputEdit.warehouse}
                    label={translate("denaliApp.input.warehouse")}
                    getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
                    loadOptions={(inputValue) => lookupWarehouses(`name.contains=${inputValue}&organizationId.equals=${organization === null ? inputEdit.organization.id : organization.id}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
                    onChangeOption={(option) =>
                      dispatch(chooseWarehouse(option))
                    }
                  />
                </div>
              </div>
              <div className = "row g-0 p-2">
                <div className = "col-sm-24 col-md-24 pe-2">
                  <InputComponentDate
                    id={"inputDate"}
                    name={"inputDate"}
                    label={translate("denaliApp.input.inputDate")}
                    required={true}
                    disabled={false}
                    showBackground={true}
                    dateDisplayFormat={APP_LOCAL_DATE_FORMAT_INPUT}
                  />
                </div>
              </div>
              <HeadlineH3 headline={translate("denaliApp.input.document")}/>
              <div className = "row g-0 p-2">
                <div className = "col-sm-24 col-md-12 pe-2">
                  <InputComponentText
                    id={"document"}
                    name={"document"}
                    required={false}
                    showBackground={true}
                    label={translate("denaliApp.input.document")}
                  />
                </div>
                <div className = "col-sm-24 col-md-12 pe-2">
                  <InputComponentText
                    id={"page"}
                    name={"page"}
                    required={false}
                    showBackground={true}
                    label={translate("denaliApp.input.page")}
                  />
                </div>
              </div>
              <div className = "row g-0 p-2">
                <div className = "col-sm-24 col-md-12 pe-2">
                  <InputComponentText
                    id={"partner"}
                    name={"partner"}
                    required={false}
                    showBackground={true}
                    label={translate("denaliApp.input.partner")}
                  />
                </div>
                <div className = "col-sm-24 col-md-12 pe-2">
                  <InputComponentText
                    id={"forestAdministration"}
                    name={"forestAdministration"}
                    required={false}
                    showBackground={true}
                    label={translate("denaliApp.input.forestAdministration")}
                  />
                </div>
              </div>

              <div className = "row g-0 p-2">
                <div className = "col-sm-24 col-md-12 pe-2">
                  <InputComponentText
                    id={"forestAdministrationDivision"}
                    name={"forestAdministrationDivision"}
                    required={false}
                    showBackground={true}
                    label={translate("denaliApp.input.forestAdministrationDivision")}
                  />
                </div>
                <div className = "col-sm-24 col-md-12 pe-2">
                  <InputComponentBoolean
                    id={"indVm"}
                    name={"indVm"}
                    required={false}
                    showBackground={true}
                    label={translate("denaliApp.input.indVm")}
                  />
                </div>
              </div>

              <HeadlineH3 headline={translate("denaliApp.input.transport")}/>
              <div className = "row g-0 p-2">
                <div className = "col-sm-24 col-md-12 pe-2">
                  <InputComponentText
                    id={"transportPartner"}
                    name={"transportPartner"}
                    required={false}
                    showBackground={true}
                    label={translate("denaliApp.input.transportPartner")}
                  />
                </div>
                <div className = "col-sm-24 col-md-12 pe-2">
                  <InputComponentText
                    id={"transportDriver"}
                    name={"transportDriver"}
                    required={false}
                    showBackground={true}
                    label={translate("denaliApp.input.transportDriver")}
                  />
                </div>
              </div>

              <div className = "row g-0 p-2">
                <div className = "col-sm-24 col-md-12 pe-2">
                  <InputComponentText
                    id={"transportRegistration"}
                    name={"transportRegistration"}
                    required={false}
                    showBackground={true}
                    label={translate("denaliApp.input.transportRegistration")}
                  />
                </div>
                <div className = "col-sm-24 col-md-12 pe-2">
                  <InputComponentText
                    id={"transportKm"}
                    name={"transportKm"}
                    required={false}
                    showBackground={true}
                    label={translate("denaliApp.input.transportKm")}
                  />
                </div>
              </div>

              <div className = "row g-0 p-2">
                <div className = "col-sm-24 col-md-12 pe-2">
                  <InputComponentText
                    id={"transportLocationStart"}
                    name={"transportLocationStart"}
                    required={false}
                    showBackground={true}
                    label={translate("denaliApp.input.transportLocationStart")}
                  />
                </div>
                <div className = "col-sm-24 col-md-12 pe-2">
                  <InputComponentText
                    id={"transportLocationEnd"}
                    name={"transportLocationEnd"}
                    required={false}
                    showBackground={true}
                    label={translate("denaliApp.input.transportLocationEnd")}
                  />
                </div>
              </div>


              <div className = "row g-0 p-2 ">
                <div className = "col-sm-24 col-md-24 pe-2 centered-row">
                  <ActionButton
                    label={translate('entity.action.save')}
                    {...STANDARD_SAVE_SUBMIT_PROPS}
                    faIcon={true}
                    icon={["fas","arrow-down"]}

                  />
                </div>
              </div>

            </Grid>
          </form>
        )
      }
    </Formik>
  );
};

export default InputEditDialogComponent;
