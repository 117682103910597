import React, {useEffect, useRef, useState} from "react";
import {Formik} from "formik";
import {
  Grid,
  Col,
  Row,
  InputComponentText,
  HeadlineH0,
  getSortState
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {useDispatch, useSelector} from "react-redux";
import {
  applyPopratnica,
  choosePopratnicaForInsert, setShowPopratnicaDialog
} from "app/shared/reducers/input/insert/input.insert";
import {IRootState} from "app/shared/reducers";
import { InputComponentBoolean} from "@msvsustavi/msv-ui-react/dist";
import { ITEMS_PER_PAGE_DIALOG} from "app/shared/util/pagination.constants";
import {useHistory} from "react-router-dom";
import {searchPopratnice} from "app/entities/nabavawrapper/popratnice/popratnice.reducer";
import classNames from "classnames/bind";
import {formatDateForDisplay} from "app/shared/util/date-utils";
import "./results.scss";


const PopratnicaEntryDialogComponent = () => {

  const dispatch = useDispatch();
  const { documentSearchData,
    entities} = useSelector(({popratnice}: IRootState) => popratnice);
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(getSortState(location, ITEMS_PER_PAGE_DIALOG));

  const cnNotChosen = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-25",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button"
  );

  const cnPopratnicaChoose = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-25",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button",
    "background-dark-lime-green"
  );

  const loadTable = () => {
    dispatch(searchPopratnice(search ? search : "", paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`));
  };


  const startSearching = () => {
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(searchPopratnice(
      search,
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`
    ));
  };

  const sortTable = () => {
    loadTable();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      history.push(`${location.pathname}${endURL}`);
    }
  };

  const sort = (c: string, o: string) => {
    setPaginationState({
      ...paginationState,
      order: o,
      sort: c
    });
  };

  const handlePagination = (currentPage) => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  };

  const clear = () => {
    setPaginationState({
      ...paginationState,
      sort: 'id',
      order: 'asc',
      activePage: 1
    });
    sortTable();
  };

  useEffect(() => {
    if (search) {
      startSearching();
    } else {
      clear();
    }
  }, [search]);

  const contentCard = (
    <Formik
      initialValues={documentSearchData}
      enableReinitialize
      onSubmit={(values, actions) => {
        let search = '';
        Object.keys(values).map(val => {
          if(val == 'stranica' || val == 'indVm'){
            search += val && values[val] ? `${val}.equals=${values[val]}&` : '';
          }else{
            search += val && values[val] ? `${val}.contains=${values[val]}&` : '';
          }
        });
        dispatch(setSearch(search))
        dispatch(choosePopratnicaForInsert(null))
      }}
    >
      {
        formik => (
          <form onSubmit={formik.handleSubmit}>
            <Grid>
              <HeadlineH0 headline={translate("denaliApp.nabavawrapperPopratnice.home.search")}/>
              <div className = "row g-0">
                <div className = "col-sm-24 col-md-12">
                  <InputComponentText
                    id={"brojDokumenta"}
                    name={"brojDokumenta"}
                    label={translate("denaliApp.nabavawrapperPopratnice.brojDokumenta")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </div>
                <div className = "col-sm-24 col-md-12">
                  <InputComponentText
                    id={"stranica"}
                    name={"stranica"}
                    label={translate("denaliApp.nabavawrapperPopratnice.stranica")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </div>
              </div>
              <div className = "row g-0">
                <div className = "col-sm-24 col-md-12">
                  <InputComponentText
                    id={"organizacija"}
                    name={"organizacija"}
                    label={translate("denaliApp.nabavawrapperPopratnice.organizacija")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </div>
                <div className = "col-sm-24 col-md-12">
                  <InputComponentText
                    id={"partner"}
                    name={"partner"}
                    label={translate("denaliApp.nabavawrapperPopratnice.partner")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </div>
              </div>
              <div className = "row g-0">
                <div className = "col-sm-24 col-md-24">
                  <InputComponentText
                    id={"upravaSuma"}
                    name={"upravaSuma"}
                    label={translate("denaliApp.nabavawrapperPopratnice.upravaSuma")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </div>
              </div>
              <div className = "row g-0">
                <div className = "col-sm-24 col-md-12">
                  <InputComponentText
                    id={"sumarija"}
                    name={"sumarija"}
                    label={translate("denaliApp.nabavawrapperPopratnice.sumarija")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </div>
                <div className = "col-sm-24 col-md-12">
                  <InputComponentBoolean
                    id={"indVM"}
                    name={"indVM"}
                    label={translate("denaliApp.nabavawrapperPopratnice.indVM")}
                    required={false}
                    showBackground={false}
                    onValueChange={() => {
                      formik.submitForm();
                    }}
                  />
                </div>
              </div>
            </Grid>
          </form>
        )
      }
    </Formik>
  );

  const rowCN = classNames('p-2', 'border-bottom-1', 'border-bottom-color-brown-grey-two', 'row-hover', 'pointer');



  const contentCardTable = (
    <>
    <div className={"w-100"}>
      {
        <div className={"results-wraper"}>
          <HeadlineH0 headline={translate("denaliApp.nabavawrapperPopratnice.choosePopratnica")}/>
          <div className={"w-100 px-3 justify-content-end"}>
              {
                [].concat(entities) && [].concat(entities).map(row =>{
                      return (
                        <Row classname={rowCN}>
                          <Col span={24}>
                            <div onClick={() => {
                              dispatch(choosePopratnicaForInsert(row));
                              dispatch(applyPopratnica());
                              dispatch(setShowPopratnicaDialog(false));
                            }}>
                              <div className={"d-flex flex-column"}>
                                <div className={"d-flex flex-row"}>
                                  <div className={"main-data"}>{row?.brojDokumenta} {row?.stranica}</div>
                                  <div className={"other-data"}> { formatDateForDisplay(row?.datumPopratnice)}</div>
                                  <div className={"other-data"}>({row?.partner})</div>
                                  <div className={"other-data"}>- {row?.sumarija} </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>)
                      })
              }
          </div>
        </div>
      }
    </div>
   </>
  );

  return (
    <>
      {contentCard}
      {contentCardTable}
    </>
  );
}

export default PopratnicaEntryDialogComponent;

