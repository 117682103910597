import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import Popup from "reactjs-popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translate, translate} from "react-jhipster";
import {setSearchData} from "app/shared/reducers/input/view/input.view";
import {InputComponentText} from "@msvsustavi/msv-ui-react/dist";

/**
 *
 */
const TransportSelection = (props) => {
  const dispatch = useDispatch();

  const {inputSearchData} = useSelector(({input}: IRootState) => input.view);

  const [values, setValues] = useState(inputSearchData);
  useEffect(()=>{setValues(inputSearchData)},[inputSearchData]);

  return (
    <Popup
      trigger={open => <div className={"mx-4 d-flex align-items-center"}>
        <div className={"me-1"}><Translate contentKey={"denaliApp.input.transport"}/></div>
        <FontAwesomeIcon icon={["far", "angle-down"]}/>
      </div>}
      closeOnDocumentClick
      position={['bottom right', 'bottom left']}
      arrow
      onClose={(event => {
        dispatch(setSearchData(values));
      })}
    >
      <div className={"d-flex flex-column popup p-3  rounded shadow"}>
        <div className={"d-flex flex-column"}>
          <div className={"px-2 py-1 text-uppercase fs-5 fw-bold selection-header"}><Translate contentKey={"denaliApp.input.transport"}/></div>
          <div className={"container"}>
            <div className={"row g-0"}>
              <div className={"col-12"}>
                <InputComponentText
                  id={"transportPartner"}
                  name={"transportPartner"}
                  value = {values.transportPartner}
                  wrapInForm={false}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.input.transportPartner")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      transportPartner: e.target.value
                    });
                  }}
                />
              </div>
              <div className={"col-12"}>
                <InputComponentText
                  id={"transportRegistration"}
                  name={"transportRegistration"}
                  value = {values.transportRegistration}
                  wrapInForm={false}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.input.transportRegistration")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      transportRegistration: e.target.value
                    });
                  }}
                />
              </div>
            </div>
            <div className={"row g-0"}>
              <div className={"col-12"}>
                <InputComponentText
                  id={"transportDriver"}
                  name={"transportDriver"}
                  value = {values.transportDriver}
                  wrapInForm={false}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.input.transportDriver")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      transportDriver: e.target.value
                    });
                  }}
                />
              </div>
              <div className={"col-12"}>
                <InputComponentText
                  id={"transportKm"}
                  name={"transportKm"}
                  wrapInForm={false}
                  value = {values.transportKm}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.input.transportKm")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      transportKm: e.target.value
                    });
                  }}
                />
              </div>
            </div>
            <div className={"row g-0"}>
              <div className={"col-12"}>
                <InputComponentText
                  id={"transportLocationStart"}
                  name={"transportLocationStart"}
                  value = {values.transportLocationStart}
                  wrapInForm={false}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.input.transportLocationStart")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      transportLocationStart: e.target.value
                    });
                  }}
                />
              </div>
              <div className={"col-12"}>
                <InputComponentText
                  id={"transportLocationEnd"}
                  name={"transportLocationEnd"}
                  wrapInForm={false}
                  value = {values.transportLocationEnd}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.input.transportLocationEnd")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      transportLocationEnd: e.target.value
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default TransportSelection;
