export interface IVariation {
  id?: number;
  sku?: string;
  variationName?: string;
  active?: boolean;
  productDesignation?: string;
  productName?: string;
  uom?: string;
  productType?: string;
  productCategory?: string;
}

export const defaultValue: Readonly<IVariation> = {
  active: false,
};
