import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import {translate} from "react-jhipster";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Card,
  CARD_IMPORTANCE,
  HeadlineCopyRegular
} from "@msvsustavi/msv-ui-react";
import {v4} from "uuid";
import ConsumptionRow from "app/modules/consumption/insert/consumptionItemTable/consumption.row";
import ConsumptionHeader from "app/modules/consumption/insert/consumptionItemTable/consumption.header";


const ConsumptionInputs = () => {
  const consumptionInputs = useSelector(({consumption}: IRootState) => consumption.insert.items);

  const list = (
    consumptionInputs && consumptionInputs.length !== 0 ?
      <>
      <ConsumptionHeader/>
      <ul className={"pe-2"}>
        {consumptionInputs && consumptionInputs.map(eq => {
          return (
            <li key={v4()}>
              <ConsumptionRow consumption={eq} showDelete={true}/>
            </li>
          );
        })}
      </ul>
      </>:
      <HeadlineCopyRegular className={"p-2"} headline={translate('denaliApp.consumption.insert.noItems')}/>
  );


  return (
    <Card
      title={translate("denaliApp.consumption.insert.items")}
      titleBordered
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={list}
    />
  );
};

export default ConsumptionInputs;
