import React from "react";
import {
  Col,
  FormInputCrud,
  Grid, InputComponentBoolean,
  InputComponentText,
  Row
} from "@msvsustavi/msv-ui-react/dist";
import {translate} from "react-jhipster";
import {createVariation, deleteVariation, getVariation, reset, updateVariation} from "app/shared/reducers/master/variation/variation.reducer";
import {IRootState} from "app/shared/reducers";
import {connect} from "react-redux";
import {navigation} from "app/shared/util/navigation.constants";

export interface IVariatonEditProps extends StateProps, DispatchProps {
}

export const VariationEdit = (props: IVariatonEditProps) => {

  const content = (
    <Grid>
      <Row>
        <Col span={6}>
          <InputComponentText
            id={"sku"}
            name={"sku"}
            label={translate('denaliApp.variation.sku')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={18}>
          <InputComponentText
            id={"variationName"}
            name={"variationName"}
            label={translate('denaliApp.variation.variationName')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={4}>
          <InputComponentBoolean
            id={"active"}
            name={"active"}
            label={translate('denaliApp.variation.active')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={6}>
          <InputComponentText
            id={"productDesignation"}
            name={"productDesignation"}
            label={translate('denaliApp.variation.productDesignation')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={14}>
          <InputComponentText
            id={"productName"}
            name={"productName"}
            label={translate('denaliApp.variation.productName')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={4}>
          <InputComponentText
            id={"uom"}
            name={"uom"}
            label={translate('denaliApp.variation.uom')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={10}>
          <InputComponentText
            id={"productType"}
            name={"productType"}
            label={translate('denaliApp.variation.productType')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={10}>
          <InputComponentText
            id={"productCategory"}
            name={"productCategory"}
            label={translate('denaliApp.variation.productCategory')}
            required={true}
            visible={true}
          />
        </Col>
      </Row>
    </Grid>
  );

  return (
    <FormInputCrud
      entity={props.entity}
      updating={props.updating}
      updateSuccess={props.updateSuccess}
      loading={props.loading}
      errorMessage={props.errorMsg}

      navigationOnClose={navigation.master.variation.view}

      component={content}

      titleForm={translate('denaliApp.variation.home.createOrEditLabel')}
      titleCard={translate('denaliApp.variation.home.title')}
      labelButtonDelete={translate('entity.action.delete')}
      labelButtonSave={translate('entity.action.save')}
      labelButtonCancel={translate('entity.action.cancel')}

      createEntity={props.createVariation}
      updateEntity={props.updateVariation}
      deleteEntity={props.deleteVariation}
      getEntity={props.getVariation}
      reset={props.reset}
    />
  );
}

const mapStateToProps = ({variation}: IRootState) => ({
  entity: variation.entity,
  updating: variation.updating,
  updateSuccess: variation.updateSuccess,
  errorMsg: variation.errorMessage,
  loading: variation.loading
});

const mapDispatchToProps = {reset, getVariation, createVariation, updateVariation, deleteVariation};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VariationEdit);


