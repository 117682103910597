import React, {useState} from "react";
import {
  Col,
  FormInputCrud,
  Grid, InputComponentBoolean, InputComponentAutocomplete,
  InputComponentText, LIST_ITEMS_PER_PAGE,
  Row
} from "@msvsustavi/msv-ui-react/dist";
import {translate} from "react-jhipster";
import {IRootState} from "app/shared/reducers";
import {connect} from "react-redux";
import {navigation} from "app/shared/util/navigation.constants";
import {lookupOrganizations} from "app/shared/lookups/organization/organization.lookup";
import {
  chooseOrganization,
  createWarehouse,
  deleteWarehouse,
  getWarehouse,
  reset, updateWarehouse
} from "app/shared/reducers/master/warehouse/warehouse.reducer";

export interface IWarehouseEditProps extends StateProps, DispatchProps {
}

export const WarehouseEdit = (props: IWarehouseEditProps) => {

   const content = (
    <Grid>
      <Row>
        <Col span={6}>
          <InputComponentText
            id={"designation"}
            name={"designation"}
            label={translate('denaliApp.warehouse.designation')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={18}>
          <InputComponentText
            id={"name"}
            name={"name"}
            label={translate('denaliApp.warehouse.name')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={6}>
          <InputComponentBoolean
            id={"active"}
            name={"active"}
            label={translate('denaliApp.warehouse.active')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={18}>
          <InputComponentText
            id={"mappingToElbrusWarehouse"}
            name={"mappingToElbrusWarehouse"}
            label={translate('denaliApp.warehouse.mappingToElbrusWarehouse')}
            required={true}
            visible={true}
          />
        </Col>
        <Col span={24}>
          <InputComponentAutocomplete
            id={"organization"}
            name={"organization"}
            value={props.entity.organization}
            required={true}
            showBackground={true}
            label={translate("denaliApp.warehouse.organization")}
            getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
            loadOptions={(inputValue) => lookupOrganizations(`name.contains=${inputValue}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
            onChangeOption={option => {
                props.chooseOrganization(option)
            }}
          />
        </Col>
      </Row>
    </Grid>
  );

  return (
    <FormInputCrud
      entity={props.entity}
      updating={props.updating}
      updateSuccess={props.updateSuccess}
      loading={props.loading}
      errorMessage={props.errorMsg}

      navigationOnClose={navigation.master.warehouse.view}

      component={content}

      titleForm={translate('denaliApp.warehouse.home.createOrEditLabel')}
      titleCard={translate('denaliApp.warehouse.home.title')}
      labelButtonDelete={translate('entity.action.delete')}
      labelButtonSave={translate('entity.action.save')}
      labelButtonCancel={translate('entity.action.cancel')}

      createEntity={props.createWarehouse}
      updateEntity={props.updateWarehouse}
      deleteEntity={props.deleteWarehouse}
      getEntity={props.getWarehouse}
      reset={props.reset}
    />
  );
}

const mapStateToProps = ({warehouse}: IRootState) => ({
  entity: warehouse.entity,
  updating: warehouse.updating,
  updateSuccess: warehouse.updateSuccess,
  errorMsg: warehouse.errorMessage,
  loading: warehouse.loading
});

const mapDispatchToProps = {reset, getWarehouse, createWarehouse, updateWarehouse, deleteWarehouse,chooseOrganization};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseEdit);


