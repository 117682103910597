import React from "react";

import "./input.selection.scss";
import OrganizationSelection from "app/modules/input/view/selection/organization.selection";
import PopratnicaSelection from "app/modules/input/view/selection/popratnica.selection";
import TransportSelection from "app/modules/input/view/selection/transport.selection";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {clearSearchData, downloadExcelInput, setSearchData} from "app/shared/reducers/input/view/input.view";


const InputSelection = () => {

  const dispatch = useDispatch();
  const {inputs,totalItems,inputSearchData} = useSelector(({input}: IRootState) => input.view);

  const separator = () => (
    <div className={"border-left-1 border-left-color-white"}>&nbsp;</div>
  );

  return (
    <div
      className={"w-100 input_selection rounded-1 d-flex flex-row justify-content-begin align-items-center color-brown-grey-two px-2 fs-5 fw-bold tooltipBoundary"}>
      <OrganizationSelection/>
      {separator()}
      <PopratnicaSelection/>
      {separator()}
      <TransportSelection/>
      {separator()}
      <div className={"flex-grow-1 d-flex justify-content-end"}>
        {separator()}
        <div className={"px-2"}>{`${inputs && inputs?.length>ITEMS_PER_PAGE?ITEMS_PER_PAGE:inputs?.length}/${totalItems}`}</div>
        {separator()}
        <FontAwesomeIcon className={"m-2 color-theme-secundary"} icon={["far", "times"]} onClick={()=>{
          dispatch(clearSearchData())}}/>
        <FontAwesomeIcon className={"m-2 color-theme-secundary"} icon={["far", "download"]} onClick={()=>{dispatch(downloadExcelInput(inputSearchData))}}/>
      </div>
    </div>
  );
};

export default InputSelection;
