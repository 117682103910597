import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IRootState} from 'app/shared/reducers';
import {
  ACTION_TYPE,
  BUTTON_SHAPE,
  FormTable,
  isComplexQuery,
  LAYOUT_TYPE,
  parseQuery
} from '@msvsustavi/msv-ui-react/dist';
import {translate} from "react-jhipster";
import {navigation} from "app/shared/util/navigation.constants";

import {UserOrganizationWarehouseDataTableColumns} from "app/shared/model/master/userOrganizationWarehouse/user-organization-warehouse.column.model";
import {
  getSearchUOWs,
  getUOWs
} from "app/shared/reducers/master/user-organization-warehouse/user-organization-warehouse.reducer";

export const UserOrganizationWarehouseView = () => {

  const dispatch = useDispatch();
  const uows = useSelector(({userOrganizationWarehouse}: IRootState) => userOrganizationWarehouse.entities);
  const count = useSelector(({userOrganizationWarehouse}: IRootState) => userOrganizationWarehouse.totalItems);
  const loading = useSelector(({userOrganizationWarehouse}: IRootState) => userOrganizationWarehouse.loading);


  return <FormTable
    loadEntities={
      (activepage, itemsPerPage, sort) => {
        return dispatch(getUOWs(activepage - 1, itemsPerPage, sort));
      }
    }
    filterEntities={
      (query, activepage, itemsPerPage, sort) => {
        return dispatch(getSearchUOWs(isComplexQuery(query) ? parseQuery(query) : `name.contains=${query}`, activepage - 1, itemsPerPage, sort));
      }
    }
    columns={UserOrganizationWarehouseDataTableColumns}
    items={uows}
    count={count}
    exportEntities={() => {
    }}
    actions={[
      {
        label: translate('denaliApp.userOrganizationWarehouse.home.createLabel'),
        showLabel: true,
        icon: 'icon-add',
        name: 'new-country',
        type: ACTION_TYPE.LINK,
        layout: LAYOUT_TYPE.TOP_ICON_BOTTOM_TEXT,
        shape: BUTTON_SHAPE.ROUNDED,
        link: navigation.master.userOrganizationWarehouse.new
      }
    ]}
    loading={loading}
  />;

}


export default UserOrganizationWarehouseView;
