import React, {useEffect} from "react";
import {IRootState} from "app/shared/reducers";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";

import InsertForm from "app/shared/util/insert-form";

import ConsumptionHeader from "app/modules/consumption/insert/header/consumption.insert.header";
import ConsumptionChooseType from "app/modules/consumption/insert/chooseInputType/consumption.insert.type";
import ManualEntryDialog from "app/modules/consumption/insert/dialogs/manualEntry/manuelEntry.dialog";
import ConsumptionInputs from "app/modules/consumption/insert/items/consumptionInput.insert";
import {createConsumptions, reset} from "app/shared/reducers/consumption/insert/consumption.insert";
import {navigation} from "app/shared/util/navigation.constants";
import {useHistory} from "react-router-dom";
import DocumentEntryDialog from "app/modules/consumption/insert/dialogs/document/documentEntry.dialog";
import UqDesignationEntryDialog from "app/modules/consumption/insert/dialogs/uqDesignation/uqDesignationEntry.dialog";
import {useOrganization} from "app/shared/util/use-organization";
import moment from "moment";

const ConsumptionInsertCard = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    consumption,
    organization,
    warehouse,
    items
  } = useSelector(({consumption}: IRootState) => consumption.insert);

  useEffect(() => {
    dispatch(reset());
  }, []);


  return (
     <div className={"d-flex w-100 justify-content-center align-items-start"}>
       <Formik
         enableReinitialize={true}
         initialValues={{consumption}}
         onSubmit={(values) => {
         dispatch(createConsumptions(
         {
           ...values.consumption,
           source : "M",
           organizationId : organization.id,
           warehouseId : warehouse.id,
           consumptionDate : moment(values.consumption.consumptionDate).set({"hour": 12, "minute": 0})
         },[].concat(items)));
         history.push(navigation.consumption.tabs.consumptionTable);
       }}>
         {formik => (
           <InsertForm handleSubmit={formik.handleSubmit} title={"denaliApp.consumption.insert.title"} >
             <ConsumptionHeader/>
             <ConsumptionChooseType/>
             <ManualEntryDialog/>
             <DocumentEntryDialog/>
             <UqDesignationEntryDialog/>
             <ConsumptionInputs/>
           </InsertForm>
         )}
       </Formik>
     </div>
  );
}


export default ConsumptionInsertCard;
