import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {navigation} from "app/shared/util/navigation.constants";
import VariationView from "app/modules/master/variation/variation.view";
import VariationEdit from "app/modules/master/variation/variation.edit";


const Routes = (match) => (
    <Switch>
      <Route path={navigation.master.variation.edit} exact component={VariationEdit}/>
      <Route path={navigation.master.variation.view} component={VariationView}/>
    </Switch>
);

export default Routes;
