import React from "react";
import {
  Card,
  CARD_IMPORTANCE,
  Grid
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {InputComponentBoolean, InputComponentText} from "@msvsustavi/msv-ui-react/dist";


export default (props) => {

  const content = (
    <Grid>
      <div className = "row g-0 p-2">
        <div className = "col-sm-24 col-md-8 pe-2">
          <InputComponentText
            id={"input.document"}
            name={"input.document"}
            required={true}
            showBackground={true}
            label={translate("denaliApp.input.document")}
          />
        </div>
        <div className = "col-sm-24 col-md-8 pe-2">
          <InputComponentText
            id={"input.page"}
            name={"input.page"}
            required={true}
            showBackground={true}
            label={translate("denaliApp.input.page")}
          />
        </div>
        <div className = "col-sm-24 col-md-8 pe-2">
          <InputComponentText
            id={"input.partner"}
            name={"input.partner"}
            required={false}
            showBackground={true}
            label={translate("denaliApp.input.partner")}
          />
        </div>
      </div>
      <div className = "row g-0 p-2">
        <div className = "col-sm-24 col-md-8 pe-2">
          <InputComponentText
            id={"input.forestAdministration"}
            name={"input.forestAdministration"}
            required={false}
            showBackground={true}
            label={translate("denaliApp.input.forestAdministration")}
          />
        </div>
        <div className = "col-sm-24 col-md-8 pe-2">
          <InputComponentText
            id={"input.forestAdministrationDivision"}
            name={"input.forestAdministrationDivision"}
            required={false}
            showBackground={true}
            label={translate("denaliApp.input.forestAdministrationDivision")}
          />
        </div>
        <div className = "col-sm-24 col-md-8 pe-2">
          <InputComponentBoolean
            id={"input.indVm"}
            name={"input.indVm"}
            required={false}
            showBackground={true}
            label={translate("denaliApp.input.indVm")}
          />
        </div>
      </div>
    </Grid>
  );


  return (
    <Card
      title={translate("denaliApp.input.document")}
      titleBordered={true}
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={content}
    />
  );
};
