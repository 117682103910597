import React from "react";
import {
  Grid,
  HeadlineH0,
} from "@msvsustavi/msv-ui-react";
import {Translate, translate} from "react-jhipster";
import {useDispatch} from "react-redux";
import classNames from "classnames/bind";
import {useHistory} from "react-router-dom";
import {navigation} from "app/shared/util/navigation.constants";
import {setShowChooseInsertDialog} from "app/shared/reducers/dashboard/dashboard-choose-insert.reducer";


const InputChooseContentDialogComponent = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const cnLink = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-90",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button"
  );

  return (
            <Grid>
              <HeadlineH0 headline={translate("inputChoose.title")}/>
              <div className = "row g-0 p-2">
                <div className = "col-sm-24 col-md-12 pe-2">
                  <div className={cnLink} onClick={() =>
                    {
                      dispatch(setShowChooseInsertDialog(false));
                      history.push(navigation.input.insert.home);
                    }
                  }>
                    <div className={"d-flex flex-column"}>
                      <Translate contentKey={"inputChoose.input"}/>
                    </div>
                  </div>
                </div>
                <div className = "col-sm-24 col-md-12 pe-2">
                  <div className={cnLink} onClick={() =>
                    {
                      dispatch(setShowChooseInsertDialog(false));
                      history.push(navigation.consumption.insert.home);
                    }
                  }>
                    <div className={"d-flex flex-column"}>
                      <Translate contentKey={"inputChoose.consumption"}/>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>

  );
}

export default InputChooseContentDialogComponent;
