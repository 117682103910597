export interface IInvoiceHsBaseTransaction {
  id?: number;
  status?: string;
  transactionDesignation?: string;
  totalValueNoTaxWithExpenses?: number;
  totalValueWithTaxNoExpenses?: number;
  totalValueNoTaxNoExpenses?: number;
  manipulativeCosts?: number;
  m3?: number;
}

export const defaultValue: Readonly<IInvoiceHsBaseTransaction> = {};
