import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import Popup from "reactjs-popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translate, translate} from "react-jhipster";
import {setSearchData} from "app/shared/reducers/input/view/input.view";
import {InputComponentText} from "@msvsustavi/msv-ui-react/dist";

/**
 *
 */
const PopratnicaSelection = (props) => {
  const dispatch = useDispatch();

  const {inputSearchData} = useSelector(({input}: IRootState) => input.view);

  const [values, setValues] = useState(inputSearchData);
  useEffect(()=>{setSearchData(inputSearchData)},[inputSearchData]);


  return (
    <Popup
      trigger={open => <div className={"mx-4 d-flex align-items-center"}>
        <div className={"me-1"}><Translate contentKey={"denaliApp.input.document"}/></div>
        <FontAwesomeIcon icon={["far", "angle-down"]}/>
      </div>}
      closeOnDocumentClick
      position={['bottom right', 'bottom left']}
      arrow
      onClose={(event => {
        dispatch(setSearchData(values));
      })}
    >
      <div className={"d-flex flex-column popup p-3  rounded shadow"}>
        <div className={"d-flex flex-column"}>
          <div className={"px-2 py-1 text-uppercase fs-5 fw-bold selection-header"}><Translate contentKey={"denaliApp.input.document"}/></div>
          <div className={"container"}>
            <div className={"row g-0"}>
              <div className={"col-12"}>
                <InputComponentText
                  id={"document"}
                  name={"document"}
                  value = {values.document}
                  wrapInForm={false}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.input.document")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      document: e.target.value
                    });
                  }}
                />
              </div>
              <div className={"col-12"}>
                <InputComponentText
                  id={"page"}
                  name={"page"}
                  value = {values.page}
                  wrapInForm={false}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.input.page")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      page: e.target.value
                    });
                  }}
                />
              </div>
            </div>
            <div className={"row g-0"}>
              <div className={"col-12"}>
                <InputComponentText
                  id={"partner"}
                  name={"partner"}
                  value = {values.partner}
                  wrapInForm={false}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.input.partner")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      partner: e.target.value
                    });
                  }}
                />
              </div>
              <div className={"col-12"}>
                <InputComponentText
                  id={"forestAdministration"}
                  name={"forestAdministration"}
                  wrapInForm={false}
                  value = {values.forestAdministration}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.input.forestAdministration")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      forestAdministration: e.target.value
                    });
                  }}
                />
              </div>
            </div>
            <div className={"row g-0"}>
              <div className={"col-24"}>
                <InputComponentText
                  id={"forestAdministrationDivision"}
                  name={"forestAdministrationDivision"}
                  wrapInForm={false}
                  value = {values.forestAdministrationDivision}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.input.forestAdministrationDivision")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      forestAdministrationDivision: e.target.value
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default PopratnicaSelection;
