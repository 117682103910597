import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import Popup from "reactjs-popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translate, translate} from "react-jhipster";
import {v4} from "uuid";
import {
  consumptionTransactionStatus,
  EConsumptionTransactionStatus
} from "app/shared/model/enumeration/e-transaction-status.model";
import {calculateConsumptionTransactionStatus} from "app/modules/consumptionGrouped/transaction/view/consumption-transaction-status";
import {setSearchData} from "app/shared/reducers/consumptionGrouped/consumption-grouped.reducer";
import classNames from "classnames/bind";

/**
 *
 */
const StatusTransactionSelection = (props) => {
  const dispatch = useDispatch();

  const {consumptionGroupedSearchData} = useSelector(({consumptionGrouped}: IRootState) => consumptionGrouped);

  const [values, setValues] = useState(consumptionGroupedSearchData);
  useEffect(()=>{setValues(consumptionGroupedSearchData)},[consumptionGroupedSearchData]);

  const consumtionTransactionStatuses = [].concat([
    {
      value: EConsumptionTransactionStatus.INITIALIZED,
      label: translate(consumptionTransactionStatus(EConsumptionTransactionStatus.INITIALIZED)),
      theme: calculateConsumptionTransactionStatus(EConsumptionTransactionStatus.INITIALIZED),
      selected: values.statuses.includes(EConsumptionTransactionStatus.INITIALIZED),
      onSelect: (selected) => {
        if (selected) {
          const v = [].concat(values.statuses);
          v.push(EConsumptionTransactionStatus.INITIALIZED);
          setValues({
            ...values,
            statuses: v
          })
        } else {
          setValues({
            ...values,
            statuses: values.statuses.filter(e => e !== EConsumptionTransactionStatus.INITIALIZED)
          })
        }

      }
    }, {
      value: EConsumptionTransactionStatus.PREPARED,
      label: translate(consumptionTransactionStatus(EConsumptionTransactionStatus.PREPARED)),
      theme: calculateConsumptionTransactionStatus(EConsumptionTransactionStatus.PREPARED),
      selected: values.statuses.includes(EConsumptionTransactionStatus.PREPARED),
      onSelect: (selected) => {
        if (selected) {
          const v = [].concat(values.statuses);
          v.push(EConsumptionTransactionStatus.PREPARED);
          setValues({
            ...values,
            statuses: v
          })
        } else {
          setValues({
            ...values,
            statuses: values.statuses.filter(e => e !== EConsumptionTransactionStatus.PREPARED)
          })
        }

      }
    }, {
      value: EConsumptionTransactionStatus.VERIFIED,
      label: translate(consumptionTransactionStatus(EConsumptionTransactionStatus.VERIFIED)),
      theme: calculateConsumptionTransactionStatus(EConsumptionTransactionStatus.VERIFIED),
      selected: values.statuses.includes(EConsumptionTransactionStatus.VERIFIED),
      onSelect: (selected) => {
        if (selected) {
          const v = [].concat(values.statuses);
          v.push(EConsumptionTransactionStatus.VERIFIED);
          setValues({
            ...values,
            statuses: v
          })
        } else {
          setValues({
            ...values,
            statuses: values.statuses.filter(e => e !== EConsumptionTransactionStatus.VERIFIED)
          })
        }

      }
    }, {
      value: EConsumptionTransactionStatus.TRANSFERED,
      label: translate(consumptionTransactionStatus(EConsumptionTransactionStatus.TRANSFERED)),
      theme: calculateConsumptionTransactionStatus(EConsumptionTransactionStatus.TRANSFERED),
      selected: values.statuses.includes(EConsumptionTransactionStatus.TRANSFERED),
      onSelect: (selected) => {
        if (selected) {
          const v = [].concat(values.statuses);
          v.push(EConsumptionTransactionStatus.TRANSFERED);
          setValues({
            ...values,
            statuses: v
          })
        } else {
          setValues({
            ...values,
            statuses: values.statuses.filter(e => e !== EConsumptionTransactionStatus.TRANSFERED)
          })
        }

      }
    }, {
      value: EConsumptionTransactionStatus.REJECTED,
      label: translate(consumptionTransactionStatus(EConsumptionTransactionStatus.REJECTED)),
      theme: calculateConsumptionTransactionStatus(EConsumptionTransactionStatus.REJECTED),
      selected: values.statuses.includes(EConsumptionTransactionStatus.REJECTED),
      onSelect: (selected) => {
        if (selected) {
          const v = [].concat(values.statuses);
          v.push(EConsumptionTransactionStatus.REJECTED);
          setValues({
            ...values,
            statuses: v
          })
        } else {
          setValues({
            ...values,
            statuses: values.statuses.filter(e => e !== EConsumptionTransactionStatus.REJECTED)
          })
        }

      }
    }
  ]);


  const StatusElement = ({selected, theme, label, onSelect}) => {
    const [sel, setSel] = useState(selected ? true : false);

    const bckg = sel ? `background-${theme}` : 'background-white';

    const elementClass = classNames(
      bckg,
      'border-top-2',
      'border-right-2',
      'border-bottom-2',
      'border-left-2',
      `border-left-color-${theme}`,
      `border-top-color-${theme}`,
      `border-right-color-${theme}`,
      `border-bottom-color-${theme}`,
      'my-1',
      'p-1',
      'rounded',
      {'color-white': sel},
      {'color-black': !sel},
      'fw-bold',
      'shadow'
    );

    return (
      <div className={elementClass} onClick={
        e => {
          setSel(!sel);
          if (onSelect) {
            onSelect(!sel);
          }
        }
      }>
        <div>{label}</div>
      </div>
    );
  }

  return (
    <Popup
      trigger={open => <div className={"mx-4 d-flex align-items-center"}>
        <div className={"me-1"}><Translate contentKey={"denaliApp.consumptionTransaction.status"}/></div>
        <FontAwesomeIcon icon={["far", "angle-down"]}/>
      </div>}
      closeOnDocumentClick
      position={['bottom right', 'bottom left']}
      arrow
      onClose={(event => {
        dispatch(setSearchData({
          ...values}));
      })}
    >
      <div className={"d-flex flex-column popup p-3  rounded shadow"}>
        <div className={"d-flex flex-column"}>
          <div className={"row g-0"}>
            <div className={"col-24"}>
              {consumtionTransactionStatuses.map((status) => (
                <StatusElement key={v4()} {...status}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default StatusTransactionSelection;
