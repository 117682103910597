import React from "react";
import {
  DataTableColumn, dataTableItemTemplateBoolean,
  dataTableItemTemplateFK, dataTableItemTemplateString
} from "@msvsustavi/msv-ui-react/dist";
import Translate from "react-jhipster/lib/src/language/translate";
import {IWarehouse} from "app/shared/model/master/warehouse/warehouse.model";



export const WarehouseDataTableColumns: DataTableColumn[] = [
  {
    field:  'sku',
    header: <Translate contentKey={'denaliApp.warehouse.designation'}/>,
     sortable: true,
    itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => //
     dataTableItemTemplateFK(column, rowData, rowIndex, colIndex, columnCount, (r, c) => r['designation'], (r, c) => `/master/warehouse/${r['id']}`)
  },{
    field: 'name',
    header: <Translate contentKey={'denaliApp.warehouse.name'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateString
  },
  {
    field: 'active',
    header: <Translate contentKey={'denaliApp.warehouse.active'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateBoolean
  },
  {
    field: 'mappingToElbrusWarehouse',
    header: <Translate contentKey={'denaliApp.warehouse.mappingToElbrusWarehouse'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateString
  },
  {
    field: 'organization.designation',
    header: <Translate contentKey={'denaliApp.warehouse.organization'}/>,
    sortable: true,
    itemTemplate: (column, rowData: IWarehouse, rowIndex, colIndex, columnCount) => {
      return (
        <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
          <div className={""}>{rowData?.organization?.designation}</div>
        </div>
      )
    }
  }
];
