import React from "react";
import {
  Card,
  CARD_IMPORTANCE,
  InputComponentNumber
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {InputComponentText} from "@msvsustavi/msv-ui-react/dist";
import {
  APP_DECIMAL_SEPARATOR,
  APP_NUMBER_FORMAT_DECIMAL_SCALE,
  APP_THOUSAND_SEPARATOR
} from "app/config/constants";


const InvoiceHsBaseCardHeader =  () => {

  const content = (
    <>
      <div className="row g-0 p-2">
        <div className="col-sm-12 col-md-12 pe-2">
          <InputComponentText
            id={"invoiceHsBase.designation"}
            name={"invoiceHsBase.designation"}
            label={translate("denaliApp.invoiceHsBase.designation")}
            required={true}
            showBackground={true}
            editable={false}
          />
        </div>
        <div className="col-sm-12 col-md-12 pe-2">
          <InputComponentNumber
            id={"invoiceHsBase.m3"}
            name={"invoiceHsBase.m3"}
            label={translate("denaliApp.invoiceHsBase.m3")}
            required={true}
            showBackground={true}
            editable={false}
            thousandSeparator={APP_THOUSAND_SEPARATOR}
            decimalSeparator={APP_DECIMAL_SEPARATOR}
            decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
            styleClassElement={"text-end"}
          />
        </div>
      </div>
      <div className="row g-0 p-2">
        <div className="col-sm-12 col-md-12 pe-2">
          <InputComponentNumber
            id={"invoiceHsBase.totalValueNoTaxWithExpenses"}
            name={"invoiceHsBase.totalValueNoTaxWithExpenses"}
            label={translate("denaliApp.invoiceHsBase.totalValueNoTaxWithExpenses")}
            required={true}
            showBackground={true}
            editable={false}
            thousandSeparator={APP_THOUSAND_SEPARATOR}
            decimalSeparator={APP_DECIMAL_SEPARATOR}
            decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
            styleClassElement={"text-end"}
          />
        </div>
        <div className="col-sm-12 col-md-12 pe-2">
          <InputComponentNumber
            id={"invoiceHsBase.totalValueWithTaxNoExpenses"}
            name={"invoiceHsBase.totalValueWithTaxNoExpenses"}
            label={translate("denaliApp.invoiceHsBase.totalValueWithTaxNoExpenses")}
            required={true}
            showBackground={true}
            editable={false}
            thousandSeparator={APP_THOUSAND_SEPARATOR}
            decimalSeparator={APP_DECIMAL_SEPARATOR}
            decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
            styleClassElement={"text-end"}
          />
        </div>
      </div>
        <div className="row g-0 p-2">
          <div className="col-sm-12 col-md-12 pe-2">
          <InputComponentNumber
            id={"invoiceHsBase.totalValueNoTaxNoExpenses"}
            name={"invoiceHsBase.totalValueNoTaxNoExpenses"}
            label={translate("denaliApp.invoiceHsBase.totalValueNoTaxNoExpenses")}
            required={true}
            showBackground={true}
            editable={false}
            thousandSeparator={APP_THOUSAND_SEPARATOR}
            decimalSeparator={APP_DECIMAL_SEPARATOR}
            decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
            styleClassElement={"text-end"}
          />
        </div>
        <div className="col-sm-12 col-md-12 pe-2">
          <InputComponentNumber
            id={"invoiceHsBase.manipulativeCosts"}
            name={"invoiceHsBase.manipulativeCosts"}
            label={translate("denaliApp.invoiceHsBase.manipulativeCosts")}
            required={true}
            showBackground={true}
            editable={false}
            thousandSeparator={APP_THOUSAND_SEPARATOR}
            decimalSeparator={APP_DECIMAL_SEPARATOR}
            decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
            styleClassElement={"text-end"}
          />
        </div>
      </div>
    </>
  );


  return (
    <Card
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100 pe-3"}
      content={content}
    />
  );
};

export default InvoiceHsBaseCardHeader;
