import React, {useEffect} from "react";
import {Modal, ModalBody} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import ManualEntryDialogComponent from "app/modules/consumption/insert/dialogs/manualEntry/manuelEntry.dialog.component";
import {setDocumentInput, setVariationInput} from "app/shared/reducers/consumption/insert/consumption.insert";
import DocumentEntryDialogComponent
  from "app/modules/consumption/insert/dialogs/document/documentEntry.dialog.component";

const DocumentEntryDialog = (props) => {
  const dispatch = useDispatch();
  const showDialog = useSelector(({consumption}:IRootState)=>consumption.insert.documentInput);

  return (
    <Modal isOpen={showDialog}
           toggle={() => dispatch(setDocumentInput(!showDialog))}
           modalTransition={{timeout: 300}}
           backdropTransition={{timeout: 600}}
           centered={true}
           keyboard={true}
           fade={true}
           size={'lg'}
    >
      <ModalBody>
        <DocumentEntryDialogComponent/>
      </ModalBody>
    </Modal>
  );
};

export default DocumentEntryDialog;
