import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/shared/reducers/user/user.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import organization, {
  OrganizationState
} from 'app/shared/reducers/master/organization/organization.reducer';
// prettier-ignore
import variation, {
  VariationState
} from 'app/shared/reducers/master/variation/variation.reducer';
// prettier-ignore
import warehouse, {
  WarehouseState
} from 'app/shared/reducers/master/warehouse/warehouse.reducer';
// prettier-ignore
import consumption, {
  ConsumptionState
} from 'app/shared/reducers/consumption/consumption.reducer';
// prettier-ignore
import userOrganizationWarehouse, {
  UserOrganizationWarehouseState
} from 'app/shared/reducers/master/user-organization-warehouse/user-organization-warehouse.reducer';
// prettier-ignore
import popratnice, {
  PopratniceState
} from 'app/entities/nabavawrapper/popratnice/popratnice.reducer';
// prettier-ignore
import trupci, {
  TrupciState
} from 'app/entities/nabavawrapper/trupci/trupci.reducer';
// prettier-ignore
import popratnicaRekaptulacija, {
  PopratnicaRekaptulacijaState
} from 'app/entities/nabavawrapper/popratnica-rekaptulacija/popratnica-rekaptulacija.reducer';
import consumptionTransaction, { ConsumptionTransactionState } from 'app/shared/reducers/transaction/consumption-transaction.reducer';
import consumptionGrouped, { ConsumptionGroupedState } from 'app/shared/reducers/consumptionGrouped/consumption-grouped.reducer';
import dashboardSelections, { DashboardSelectionsState } from 'app/shared/reducers/dashobardSelections/dashboard-selections.view';
// prettier-ignore
import input, {
  InputState
} from 'app/shared/reducers/input/input.reducer';
import invoiceHsBaseTransaction, {
  InvoiceHsBaseTransactionState,
} from 'app/shared/reducers/invoiceHsBase/transaction/transaction/invoice-hs-base-transaction.reducer';
import invoiceHsBaseTransactionQuantityPartner, {
  InvoiceHsBaseTransactionQuantityPartnerState,
} from 'app/shared/reducers/invoiceHsBase/transaction/quantityPatner/invoice-hs-base-transaction-quantity-partner.reducer';
import invoiceHsBaseTransactionTotal, {
  InvoiceHsBaseTransactionTotalState,
} from 'app/shared/reducers/invoiceHsBase/transaction/total/invoice-hs-base-transaction-total.reducer';
import invoiceHsSelected, {
  InvoiceHsSelectedState,
} from 'app/shared/reducers/invoiceHsBase/transaction/selected/invoice-hs-selected.reducer';
import trupciFakture, { TrupciFaktureState } from 'app/entities/nabavawrapper/trupci-fakture/trupci-fakture.reducer';
import invoiceHsBaseTransactionInsert, {
  InvoiceHsBaseTransactionInsertState,
} from 'app/shared/reducers/invoiceHsBase/transaction/insert/invoice-hs-base-transaction-insert.reducer';

import dashboardChooseInsert, { DashboardChooseInsertState } from 'app/shared/reducers/dashboard/dashboard-choose-insert.reducer';
import invoiceHsBase, { InvoiceHsBaseState } from 'app/shared/reducers/invoiceHsBase/base/base/invoice-hs-base.reducer';
import invoiceHsBaseItem, { InvoiceHsBaseItemState } from 'app/shared/reducers/invoiceHsBase/base/item/invoice-hs-base-item.reducer';
import invoiceHsBaseInvoice, {
  InvoiceHsBaseInvoiceState,
} from 'app/shared/reducers/invoiceHsBase/base/invoices/invoice-hs-base-invoice.reducer';

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly organization: OrganizationState;
  readonly variation: VariationState;
  readonly warehouse: WarehouseState;
  readonly consumption: ConsumptionState;
  readonly userOrganizationWarehouse: UserOrganizationWarehouseState;
  readonly popratnice: PopratniceState;
  readonly trupci: TrupciState;
  readonly loadingBar: any;
  readonly popratnicaRekaptulacija: PopratnicaRekaptulacijaState;
  readonly consumptionTransaction: ConsumptionTransactionState;
  readonly consumptionGrouped: ConsumptionGroupedState;
  readonly dashboardSelections: DashboardSelectionsState;
  readonly input: InputState;
  readonly dashboardChooseInsert: DashboardChooseInsertState;
  readonly invoiceHsBaseTransaction: InvoiceHsBaseTransactionState;
  readonly invoiceHsBaseTransactionQuantityPartner: InvoiceHsBaseTransactionQuantityPartnerState;
  readonly invoiceHsBaseTransactionTotal: InvoiceHsBaseTransactionTotalState;
  readonly invoiceHsSelected: InvoiceHsSelectedState;
  readonly trupciFakture: TrupciFaktureState;
  readonly invoiceHsBaseTransactionInsert: InvoiceHsBaseTransactionInsertState;
  readonly invoiceHsBase: InvoiceHsBaseState;
  readonly invoiceHsBaseItem: InvoiceHsBaseItemState;
  readonly invoiceHsBaseInvoice: InvoiceHsBaseInvoiceState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  organization,
  variation,
  warehouse,
  consumption,
  userOrganizationWarehouse,
  popratnice,
  trupci,
  popratnicaRekaptulacija,
  consumptionTransaction,
  consumptionGrouped,
  dashboardSelections,
  input,
  dashboardChooseInsert,
  invoiceHsBaseTransaction,
  invoiceHsBaseTransactionQuantityPartner,
  invoiceHsBaseTransactionTotal,
  invoiceHsSelected,
  trupciFakture,
  invoiceHsBaseTransactionInsert,
  invoiceHsBase,
  invoiceHsBaseItem,
  invoiceHsBaseInvoice,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
});

export default rootReducer;
