import { combineReducers } from 'redux';
import insert, { InputInsertState } from 'app/shared/reducers/input/insert/input.insert';
import view, { InputViewState } from 'app/shared/reducers/input/view/input.view';
import edit, { InputEditState } from 'app/shared/reducers/input/edit/input.edit';

export interface InputState {
  readonly insert: InputInsertState;
  readonly view: InputViewState;
  readonly edit: InputEditState;
}

export default combineReducers<InputState>({
  insert,
  view,
  edit,
});
