import moment, { Moment } from 'moment';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';

export interface IConsumptionTransaction {
  id?: number;
  periodBegin?: Moment;
  periodEnd?: Moment;
  status?: string;
  organizationId?: number;
  warehouseId?: number;

  warehouse?: IWarehouse;
  organization?: IOrganization;
}

export const defaultValue: Readonly<IConsumptionTransaction> = {
  periodEnd: moment(),
};
