import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IVariation, defaultValue } from 'app/shared/model/master/variation/variation.model';

export const ACTION_TYPES = {
  SEARCH_VARIATIONS: 'variation/SEARCH_VARIATIONS',
  FETCH_VARIATION_LIST: 'variation/FETCH_VARIATION_LIST',
  FETCH_VARIATION: 'variation/FETCH_VARIATION',
  CREATE_VARIATION: 'variation/CREATE_VARIATION',
  UPDATE_VARIATION: 'variation/UPDATE_VARIATION',
  DELETE_VARIATION: 'variation/DELETE_VARIATION',
  RESET: 'variation/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IVariation>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type VariationState = Readonly<typeof initialState>;

// Reducer

export default (state: VariationState = initialState, action): VariationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_VARIATIONS):
    case REQUEST(ACTION_TYPES.FETCH_VARIATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_VARIATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_VARIATION):
    case REQUEST(ACTION_TYPES.UPDATE_VARIATION):
    case REQUEST(ACTION_TYPES.DELETE_VARIATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_VARIATIONS):
    case FAILURE(ACTION_TYPES.FETCH_VARIATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VARIATION):
    case FAILURE(ACTION_TYPES.CREATE_VARIATION):
    case FAILURE(ACTION_TYPES.UPDATE_VARIATION):
    case FAILURE(ACTION_TYPES.DELETE_VARIATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_VARIATIONS):
    case SUCCESS(ACTION_TYPES.FETCH_VARIATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_VARIATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_VARIATION):
    case SUCCESS(ACTION_TYPES.UPDATE_VARIATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_VARIATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/variations';
const apiSearchUrl = 'api/_search/variations';

// Actions
export const getSearchVariations = (
  query: string | undefined,
  page: number | undefined,
  size: number | undefined,
  sort: string | undefined
) => {
  const requestUrl = `${apiUrl}${sort ? `?${query}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_VARIATION_LIST,
    payload: axios.get<IVariation[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getVariations: ICrudGetAllAction<IVariation> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_VARIATION_LIST,
    payload: axios.get<IVariation>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getVariation: ICrudGetAction<IVariation> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_VARIATION,
    payload: axios.get<IVariation>(requestUrl),
  };
};

export const createVariation: ICrudPutAction<IVariation> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_VARIATION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getVariations());
  return result;
};

export const updateVariation: ICrudPutAction<IVariation> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_VARIATION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteVariation: ICrudDeleteAction<IVariation> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_VARIATION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getVariations());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
