import React from "react";
import {
  DataTableColumn,
  dataTableItemTemplateFK
} from "@msvsustavi/msv-ui-react/dist";
import Translate from "react-jhipster/lib/src/language/translate";
import {IUserOrganizationWarehouse} from "app/shared/model/master/userOrganizationWarehouse/user-organization-warehouse.model";



export const UserOrganizationWarehouseDataTableColumns: DataTableColumn[] = [
  {
    field:  'userLogin',
    header: <Translate contentKey={'denaliApp.userOrganizationWarehouse.user'}/>,
    sortable: true,
    itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => //
      dataTableItemTemplateFK(column, rowData, rowIndex, colIndex, columnCount, (r, c) => r['userLogin'], (r, c) => `/master/userOrganizationWarehouse/${r['id']}`)
  },{
    field: 'organization.designation',
    header: <Translate contentKey={'denaliApp.warehouse.organization'}/>,
    sortable: true,
    itemTemplate: (column, rowData: IUserOrganizationWarehouse, rowIndex, colIndex, columnCount) => {
      return (
        <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
          <div className={""}>{rowData?.organization?.designation}</div>
        </div>
      )
    }
  }
];
