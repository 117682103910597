import React, {useEffect, useState} from "react";
import {useRouteMatch, Route, useHistory} from "react-router-dom";
import {translate} from "react-jhipster";
import {
  Card, CARD_IMPORTANCE,
  FlatTable, FlatTableDataCell,
  FlatTableDataRow,
  FlatTableHeader,
  FlatTableHeaderCell, getSortState
} from "@msvsustavi/msv-ui-react/dist";
import NumberFormat from "react-number-format";
import {APP_DECIMAL_SEPARATOR, APP_NUMBER_FORMAT_DECIMAL_SCALE, APP_THOUSAND_SEPARATOR} from "app/config/constants";
import {ITEMS_PER_PAGE_DIALOG} from "app/shared/util/pagination.constants";
import {useDispatch, useSelector} from "react-redux";
import {
  downloadAttachment,
  getItemsByHsBase
} from "app/shared/reducers/invoiceHsBase/base/item/invoice-hs-base-item.reducer";
import {IRootState} from "app/shared/reducers";
import {ACTION_TYPE, ActionButton, BUTTON_SHAPE, LAYOUT_TYPE} from "@msvsustavi/msv-ui-react";

const InvoiceHsBaseTabsItems = () => {

  const [paginationState, setPaginationState] = useState(getSortState(location, ITEMS_PER_PAGE_DIALOG));
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const items  = useSelector(({invoiceHsBaseItem}: IRootState) => invoiceHsBaseItem.entities);
  const invoiceHsBase =  useSelector(({invoiceHsBase}: IRootState) => invoiceHsBase.entity);

  useEffect(() => {
    dispatch(getItemsByHsBase(match.params['id']))
  }, []);

  const handleSort = (column, order) => {
    setPaginationState({
      ...paginationState,
      sort: column,
      order: order
    });
    history.push(`${location.pathname}?page=${paginationState.activePage}&sort=${column},${order}`);
  };


  const contentCardTable = (
    <>
      <div className={"d-flex w-100 flex-column justify-content-end align-items-end"}>
        <ActionButton
          layout={LAYOUT_TYPE.TOP_ICON_BOTTOM_TEXT}
          shape={BUTTON_SHAPE.ROUNDED}
          type={ACTION_TYPE.BUTTON}
          label={translate('denaliApp.invoiceHsBaseItem.download')}
          showLabel={true}
          faIcon={true}
          icon={["far", "cloud-download"]}
          command={() => {
            downloadAttachment(match.params['id'],invoiceHsBase.designation)
          }}
        />
        <div className={"w-100 px-3 justify-content-end"}>
          <FlatTable>
            <FlatTableHeader>

              <FlatTableHeaderCell  first showSort onHeaderClick={(sort) => handleSort("woodTypeName", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseItem.woodTypeName')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("className", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseItem.className')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("priceLength", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseItem.priceLength')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell  showSort onHeaderClick={(sort) => handleSort("priceRange", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseItem.priceRange')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseItem.fee')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseItem.unitPrice')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseItem.totalValueNoTaxWithExpenses')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseItem.totalValueWithTaxNoExpenses')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseItem.totalValueNoTaxNoExpenses')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.manipulativeCosts')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseItem.m3')}
              </FlatTableHeaderCell>

            </FlatTableHeader>
            {
              [].concat(items) && [].concat(items).map(row =>(
                <FlatTableDataRow>

                  <FlatTableDataCell type={"begin"} showBorder={false}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.woodTypeName}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"} showBorder={true}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.className}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"} showBorder={true}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.priceLength}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.priceRange}
                      </div>
                    }
                  </FlatTableDataCell>


                  <FlatTableDataCell type={"center"} className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.fee}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"center"} className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.unitPrice}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.totalValueNoTaxWithExpenses}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.totalValueWithTaxNoExpenses}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.totalValueNoTaxNoExpenses}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.manipulativeCosts}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.m3}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                </FlatTableDataRow>

              ))
            }
          </FlatTable>
        </div>
      </div>
    </>
  );


  return (
    <Card
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={contentCardTable}
    />
  );

}

export default InvoiceHsBaseTabsItems;
