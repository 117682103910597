import axios from 'axios';
import { IVariation } from 'app/shared/model/master/variation/variation.model';

export const lookupVariations = (
  query: string | undefined,
  page: number | undefined,
  size: number | undefined,
  sort: string | undefined
) => {
  const apiUrl = 'api/variations';
  const requestUrl = `${apiUrl}${sort ? `?${query}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return axios.get<IVariation[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`);
};
