export interface IPopratnicaRekaptulacija {
  id?: number;
  vrstaDrva?: string;
  detaljnaVrstaDrva?: string;
  cjRazred?: string;
  quantity?: number;
  volume?: number;
  indVM?: boolean;
  hsPopratnicaId?: number;
  varijacija?: string;
}

export const defaultValue: Readonly<IPopratnicaRekaptulacija> = {
  indVM: false,
};
