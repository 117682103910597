import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IOrganization, defaultValue } from 'app/shared/model/master/organization/organization.model';
import { IConsumptionGrouped } from 'app/shared/model/consumption/consumption-grouped.model';

export const ACTION_TYPES = {
  SEARCH_ORGANIZATIONS: 'organization/view/SEARCH_ORGANIZATIONS',
  FETCH_ORGANIZATION_LIST: 'organization/view/FETCH_ORGANIZATION_LIST',
  FETCH_ORGANIZATION: 'organization/view/FETCH_ORGANIZATION',
  CREATE_ORGANIZATION: 'organization/view/CREATE_ORGANIZATION',
  UPDATE_ORGANIZATION: 'organization/view/UPDATE_ORGANIZATION',
  DELETE_ORGANIZATION: 'organization/view/DELETE_ORGANIZATION',
  RESET: 'organization/view/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  organizations: [] as ReadonlyArray<IOrganization>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  count: 0,
};

export type OrganizationState = Readonly<typeof initialState>;

// Reducer

export default (state: OrganizationState = initialState, action): OrganizationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_ORGANIZATIONS):
    case REQUEST(ACTION_TYPES.FETCH_ORGANIZATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ORGANIZATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_ORGANIZATION):
    case REQUEST(ACTION_TYPES.UPDATE_ORGANIZATION):
    case REQUEST(ACTION_TYPES.DELETE_ORGANIZATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_ORGANIZATIONS):
    case FAILURE(ACTION_TYPES.FETCH_ORGANIZATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ORGANIZATION):
    case FAILURE(ACTION_TYPES.CREATE_ORGANIZATION):
    case FAILURE(ACTION_TYPES.UPDATE_ORGANIZATION):
    case FAILURE(ACTION_TYPES.DELETE_ORGANIZATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_ORGANIZATIONS):
    case SUCCESS(ACTION_TYPES.FETCH_ORGANIZATION_LIST):
      return {
        ...state,
        loading: false,
        organizations: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_ORGANIZATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ORGANIZATION):
    case SUCCESS(ACTION_TYPES.UPDATE_ORGANIZATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_ORGANIZATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/organizations';
// Actions

export const getSearchOrganizations = (
  query: string | undefined,
  page: number | undefined,
  size: number | undefined,
  sort: string | undefined
) => {
  const requestUrl = `${apiUrl}${sort ? `?${query}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ORGANIZATION_LIST,
    payload: axios.get<IOrganization[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getOrganizations: ICrudGetAllAction<IOrganization> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ORGANIZATION_LIST,
    payload: axios.get<IOrganization>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getOrganization: ICrudGetAction<IOrganization> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ORGANIZATION,
    payload: axios.get<IOrganization>(requestUrl),
  };
};

export const createOrganization: ICrudPutAction<IOrganization> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ORGANIZATION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getOrganizations());
  return result;
};

export const updateOrganization: ICrudPutAction<IOrganization> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ORGANIZATION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteOrganization: ICrudDeleteAction<IOrganization> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ORGANIZATION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getOrganizations());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
