import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  Card,
  CARD_IMPORTANCE,
  getSortState,
  FlatTable,
  FlatTableHeader,
  FlatTableHeaderCell,
  FlatTableDataRow,
  FlatTableDataCell
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {IRootState} from "app/shared/reducers";

import {ITEMS_PER_PAGE_DIALOG} from "app/shared/util/pagination.constants";
import {useHistory, useRouteMatch} from "react-router-dom";
import NumberFormat from "react-number-format";
import {APP_DECIMAL_SEPARATOR, APP_NUMBER_FORMAT_DECIMAL_SCALE, APP_THOUSAND_SEPARATOR} from "app/config/constants";


const InvoiceHsBaseTransactionPreviewPartner =  () => {

  const history = useHistory();
  const [paginationState, setPaginationState] = useState(getSortState(location, ITEMS_PER_PAGE_DIALOG));
  const quantitysByPartner  = useSelector(({invoiceHsBaseTransactionQuantityPartner}: IRootState) => invoiceHsBaseTransactionQuantityPartner.quantitysByPartner);


  const handleSort = (column, order) => {
    setPaginationState({
      ...paginationState,
      sort: column,
      order: order
    });
    history.push(`${location.pathname}?page=${paginationState.activePage}&sort=${column},${order}`);
  };

  const contentCardTable = (
    <>
      <div className={"d-flex w-100 flex-column justify-content-end align-items-end"}>
        <div className={"w-100 px-3 justify-content-end"}>
          <FlatTable>
            <FlatTableHeader>

              <FlatTableHeaderCell  first showSort onHeaderClick={(sort) => handleSort("woodTypeName", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseTransactionQuantityPartner.organization')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("className", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseTransactionQuantityPartner.woodTypeName')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("className", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')} >
                {translate('denaliApp.invoiceHsBaseTransactionQuantityPartner.className')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("priceLength", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')} >
                {translate('denaliApp.invoiceHsBaseTransactionQuantityPartner.priceLength')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell  showSort onHeaderClick={(sort) => handleSort("priceRange", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')} >
                {translate('denaliApp.invoiceHsBaseTransactionQuantityPartner.priceRange')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell>
                { translate('denaliApp.invoiceHsBaseTransactionQuantityPartner.quantity')}
              </FlatTableHeaderCell>

            </FlatTableHeader>
            {
              [].concat(quantitysByPartner) && [].concat(quantitysByPartner).map(row =>(
                <FlatTableDataRow>

                  <FlatTableDataCell type={"begin"} showBorder={false}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.organizationName}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"} showBorder={true}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.woodTypeName}
                      </div>
                    }
                  </FlatTableDataCell>


                  <FlatTableDataCell type={"begin"} showBorder={true}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.className}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"} showBorder={true}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.priceLength}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.priceRange}
                      </div>
                    }
                  </FlatTableDataCell>


                  <FlatTableDataCell>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.quantity}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                </FlatTableDataRow>

              ))
            }
          </FlatTable>
        </div>
      </div>
    </>
  );


  return (
    <Card
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={contentCardTable}
      titleBordered={true}
      title= {translate('denaliApp.invoiceHsBaseTransactionQuantityPartner.home.title')}/>
  );
};


export default InvoiceHsBaseTransactionPreviewPartner;
