import React, {useEffect} from "react";
import {IRootState} from "app/shared/reducers";
import {useDispatch, useSelector} from "react-redux";
import {Formik} from "formik";

import InsertForm from "app/shared/util/insert-form";

import InvoiceHsBaseTransactionHeader from "app/modules/invoiceHsBase/transaction/insert/header/invoiceHsBaseTransaction.insert.header";
import InvoiceHsBaseTransactionInvoices from "app/modules/invoiceHsBase/transaction/insert/invoices/invoiceHsBaseTransaction.insert.invoices";
import {
  reset
} from "app/shared/reducers/invoiceHsBase/transaction/transaction/invoice-hs-base-transaction.reducer";
import {saveSelectedInvoice} from "app/shared/reducers/invoiceHsBase/transaction/insert/invoice-hs-base-transaction-insert.reducer";
import {navigation} from "app/shared/util/navigation.constants";
import {useHistory} from "react-router-dom";
import {translate} from "react-jhipster";
import {Loading} from "@msvsustavi/msv-ui-react";

const InvoiceHsBaseTransactionInsertCard = (props) => {

  const transaction = useSelector(({invoiceHsBaseTransactionInsert}: IRootState) => invoiceHsBaseTransactionInsert.transaction);
  const {filledTransaction,filledTotals,filledPartners,loading} = useSelector(({invoiceHsBaseTransactionInsert}: IRootState) => invoiceHsBaseTransactionInsert);
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedInvoices} = useSelector(({invoiceHsBaseTransactionInsert}: IRootState) => invoiceHsBaseTransactionInsert);


  useEffect(() => {
    dispatch(reset())
  }, []);

  useEffect(() => {
    if(filledTransaction && filledTotals && filledPartners){
      history.push(navigation.invoiceHsBase.transaction.preview.replace(':id', String(transaction.id)))
    }
  }, [filledTransaction,filledTotals,filledPartners]);


  return (
        <div className={"d-flex w-100 justify-content-center align-items-start"}>
          <Formik
            enableReinitialize={true}
            initialValues={{transaction}}
            onSubmit={(values) => {
              if(selectedInvoices.length > 0 && values.transaction.transactionDesignation !== undefined){
                dispatch(saveSelectedInvoice(values.transaction,[].concat(selectedInvoices)));
              }else {
                if(selectedInvoices.length === 0){
                  alert(translate("denaliApp.invoiceHsBaseTransaction.alertNoInvoices"))
                }else if (transaction.transactionDesignation === undefined){
                  alert(translate("denaliApp.invoiceHsBaseTransaction.alertNoDesignation"))
                }else{
                  alert(translate("denaliApp.invoiceHsBaseTransaction.alertErrorForTrans"))
                }
              }
            }}>
            {formik => (
              loading && (!filledTransaction || !filledTotals || !filledPartners)? <Loading/> :
              <InsertForm handleSubmit={formik.handleSubmit} title={"denaliApp.invoiceHsBaseTransaction.home.title"}>
                <InvoiceHsBaseTransactionHeader/>
                <InvoiceHsBaseTransactionInvoices/>
              </InsertForm>
            )}
          </Formik>
        </div>
  );
}


export default InvoiceHsBaseTransactionInsertCard;
