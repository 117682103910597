import { defaultValue, IConsumption } from 'app/shared/model/consumption/consumption.model';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IVariation } from 'app/shared/model/master/variation/variation.model';
import { ICrudPutAction } from 'react-jhipster';

export const ACTION_TYPES = {
  UPDATE_CONSUMPTION: 'consumption/edit/UPDATE_CONSUMPTION',
  FETCH_CONSUMPTION: 'consumption/edit/FETCH_CONSUMPTION',

  CHOOSE_ORGANIZATION: 'consumption/edit/CHOOSE_ORGANIZATION',
  CHOOSE_WAREHOUSE: 'consumption/edit/CHOOSE_WAREHOUSE',
  CHOOSE_VARIATION: 'consumption/edit/CHOOSE_VARIATION',

  SHOW_EDIT_DIALOG: 'consumption/edit/SHOW_EDIT_DIALOG',

  RESET: 'consumption/edit/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  consumption: defaultValue,
  updating: false,
  updateSuccess: false,

  organization: null as Readonly<IOrganization>,
  warehouse: null as Readonly<IWarehouse>,
  variation: null as Readonly<IVariation>,
  showEditDialog: false,
};

export type ConsumptionEditState = Readonly<typeof initialState>;

export default (state: ConsumptionEditState = initialState, action): ConsumptionEditState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.UPDATE_CONSUMPTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.UPDATE_CONSUMPTION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        consumption: {},
      };
    case SUCCESS(ACTION_TYPES.UPDATE_CONSUMPTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        consumption: action.payload.data,
      };
    case ACTION_TYPES.FETCH_CONSUMPTION:
      return {
        ...state,
        consumption: action.payload,
      };
    case ACTION_TYPES.CHOOSE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case ACTION_TYPES.CHOOSE_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    case ACTION_TYPES.CHOOSE_VARIATION:
      return {
        ...state,
        variation: action.payload,
      };
    case ACTION_TYPES.SHOW_EDIT_DIALOG:
      return {
        ...state,
        showEditDialog: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/consumptions';

export const updateConsumption: ICrudPutAction<IConsumption> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CONSUMPTION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const setConsumtion = consumption => {
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTION,
    payload: consumption,
  };
};

export const chooseOrganization = (organization: IOrganization) => {
  return {
    type: ACTION_TYPES.CHOOSE_ORGANIZATION,
    payload: organization,
  };
};

export const chooseWarehouse = (wh: IWarehouse) => {
  return {
    type: ACTION_TYPES.CHOOSE_WAREHOUSE,
    payload: wh,
  };
};

export const chooseVariation = (variat: IVariation) => {
  return {
    type: ACTION_TYPES.CHOOSE_VARIATION,
    payload: variat,
  };
};

export const showEditDialog = (showEditDialog: boolean) => {
  return {
    type: ACTION_TYPES.SHOW_EDIT_DIALOG,
    payload: showEditDialog,
  };
};
