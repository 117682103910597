import {Login} from '@msvsustavi/msv-ui-react/dist';
import * as Yup from "yup";
import React, {useEffect} from "react";
import { connect } from 'react-redux';
import {IRootState} from "app/shared/reducers";
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";
import { login } from 'app/shared/reducers/authentication';
import {useHistory} from "react-router-dom";
import {translate} from "react-jhipster";

export interface ILoginProps extends StateProps, DispatchProps{}

const LoginElement = (props:ILoginProps) => {
  const history = useHistory();
  const handleLogin =  (username, password, rememberMe = false) => {
     props.login(username, password, rememberMe);
  }

  useEffect(()=>{if(props.isAuthenticated)history.push('/')},[props.isAuthenticated])

  return (
    <Login loginData={{username: "username",password: "password", remeberme: true}}
           changePasswordData={{email:'mail@domain.com'}}
           capchaSiteKey={'6Ld6cOAUAAAAAE-P9X2pilqi_ldgUJvI7d5DaD-3'}
           onForgotPasswordRequest={
             (values)=>{
               alert(JSON.stringify(values, null,2))
             }
           }
           onLogin={(values)=>{handleLogin(values.username, values.password, values.remeberme)}}
           validationSchema={
             Yup.object(
               {username: Yup.string().required('Required')}
             )
           }
           labelTitle={translate('login.title')}
           labelSubtitle={translate('login.subtitle')}

           labelUsername={translate('login.form.username')}
           labelPassword={translate('login.form.password')}
           labelLogin={translate('login.form.button')}
           labelChangePassword={translate('login.form.changePassword')}
           labelEmail={translate('login.form.email')}
           labelForgotPassword={translate('login.form.forgotPassword')}
           labelRememberMe={translate('login.form.rememberme')}
           labelSubtitleForgotPassword={translate('login.forgotPasswordForm.subtitle')}

           showForgotPassword
           showRememberMe
    />
  );
}

const mapStateToProps = ({ authentication, applicationProfile, locale }: IRootState) => ({
  loading: authentication.loading,
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  ribbonEnv: applicationProfile.ribbonEnv,
  account: authentication.account
});

const mapDispatchToProps = {login};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(LoginElement)!;
