import React from "react";

import "./input.selection.scss";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OrganizationConsumptionSelection from "app/modules/consumption/view/selection/organization.selection";
import {clearSearchData, downloadExcelConsumptions} from "app/shared/reducers/consumption/view/consumption.view";
import DocumentSelection from "app/modules/consumption/view/selection/document.selection";


const ConsumptionSelection = () => {

  const dispatch = useDispatch();
  const {consumptions,totalItems,consumptionSearchData} = useSelector(({consumption}: IRootState) => consumption.view);

  const separator = () => (
    <div className={"border-left-1 border-left-color-white"}>&nbsp;</div>
  );

  return (
    <div
      className={"w-100 input_selection rounded-1 d-flex flex-row justify-content-begin align-items-center color-brown-grey-two px-2 fs-5 fw-bold tooltipBoundary"}>
      <OrganizationConsumptionSelection/>
      {separator()}
      <DocumentSelection/>
      {separator()}
      <div className={"flex-grow-1 d-flex justify-content-end"}>
        {separator()}
        <div className={"px-2"}>{`${consumptions && consumptions?.length>ITEMS_PER_PAGE?ITEMS_PER_PAGE:consumptions?.length}/${totalItems}`}</div>
        {separator()}
        <FontAwesomeIcon className={"m-2 color-theme-secundary"} icon={["far", "times"]} onClick={()=>{
          dispatch(clearSearchData())}}/>
        <FontAwesomeIcon className={"m-2 color-theme-secundary"} icon={["far", "download"]} onClick={()=>{dispatch(downloadExcelConsumptions(consumptionSearchData))}}/>
      </div>
    </div>
  );
};

export default ConsumptionSelection;
