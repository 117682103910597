export const ACTION_TYPES = {
  SET_SHOW_CHOOSE_INSERT_DIALOG: 'dashboard-choose-insert/SET_SHOW_CHOOSE_DIALOG',
};

const initialState = {
  showChooseInsertDialog: false,
};

export type DashboardChooseInsertState = Readonly<typeof initialState>;

export default (state: DashboardChooseInsertState = initialState, action): DashboardChooseInsertState => {
  switch (action.type) {
    case ACTION_TYPES.SET_SHOW_CHOOSE_INSERT_DIALOG:
      return {
        ...state,
        showChooseInsertDialog: action.payload,
      };
    default:
      return state;
  }
};

export const setShowChooseInsertDialog = (showChooseInsertDialog: boolean) => {
  return {
    type: ACTION_TYPES.SET_SHOW_CHOOSE_INSERT_DIALOG,
    payload: showChooseInsertDialog,
  };
};
