import axios from 'axios';
import { ICrudGetAction, ICrudPutAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import {
  IInvoiceHsBaseTransaction,
  defaultValue,
} from 'app/shared/model/invoiceHsBase/transaction/transaction/invoice-hs-base-transaction.model';

export const ACTION_TYPES = {
  CREATE_INVOICEHSBASETRANSACTION: 'invoiceHsBaseTransaction/CREATE_INVOICEHSBASETRANSACTION',
  FETCH_INVOICEHSBASETRANSACTION: 'invoiceHsBaseTransaction/FETCH_INVOICEHSBASETRANSACTION',
  RESET: 'invoiceHsBaseTransaction/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IInvoiceHsBaseTransaction>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type InvoiceHsBaseTransactionState = Readonly<typeof initialState>;

// Reducer

export default (state: InvoiceHsBaseTransactionState = initialState, action): InvoiceHsBaseTransactionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_INVOICEHSBASETRANSACTION):
    case REQUEST(ACTION_TYPES.FETCH_INVOICEHSBASETRANSACTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_INVOICEHSBASETRANSACTION):
    case FAILURE(ACTION_TYPES.FETCH_INVOICEHSBASETRANSACTION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_INVOICEHSBASETRANSACTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVOICEHSBASETRANSACTION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/invoice-hs-base-transactions';

// Actions
export const createInvoiceHsBaseTransaction: ICrudPutAction<IInvoiceHsBaseTransaction> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INVOICEHSBASETRANSACTION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const getTransaction: ICrudGetAction<IInvoiceHsBaseTransaction> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICEHSBASETRANSACTION,
    payload: axios.get<IInvoiceHsBaseTransaction>(requestUrl),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
