import React from "react";
import {
  DataTableColumn, dataTableItemTemplateBoolean,
  dataTableItemTemplateFK, dataTableItemTemplateString
} from "@msvsustavi/msv-ui-react/dist";
import Translate from "react-jhipster/lib/src/language/translate";



export const OrganizationDataTableColumns: DataTableColumn[] = [
  {
    field:  'designation',
    header: <Translate contentKey={'denaliApp.organization.designation'}/>,
     sortable: true,
    itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => //
     dataTableItemTemplateFK(column, rowData, rowIndex, colIndex, columnCount, (r, c) => r['designation'], (r, c) => `/master/organization/${r['id']}`)
  },{
    field: 'name',
    header: <Translate contentKey={'denaliApp.organization.name'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateString
  },
  {
    field: 'active',
    header: <Translate contentKey={'denaliApp.organization.active'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateBoolean
  },
  {
    field: 'rest_url',
    header: <Translate contentKey={'denaliApp.organization.rest_url'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateString
  }
];
