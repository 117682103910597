import React from "react";
import {
  Col,
  FormInputCrud,
  Grid, InputComponentSelectAsync, LIST_ITEMS_PER_PAGE,
  Row
} from "@msvsustavi/msv-ui-react/dist";
import {translate} from "react-jhipster";
import {IRootState} from "app/shared/reducers";
import {connect} from "react-redux";
import {navigation} from "app/shared/util/navigation.constants";
import {lookupOrganizations} from "app/shared/lookups/organization/organization.lookup";
import {
  chooseUser,chooseOrganization,
  chooseWarehouse, createUOW, deleteUOW, getUOW, reset, updateUOW
} from "app/shared/reducers/master/user-organization-warehouse/user-organization-warehouse.reducer";
import {lookupUsers} from "app/shared/lookups/user/user.lookup";
import {InputComponentAutocomplete} from "@msvsustavi/msv-ui-react";


export interface IUserOrganizationWarehouseEditProps extends StateProps, DispatchProps {
}

export const UserOrganizationWarehouseEdit = (props : IUserOrganizationWarehouseEditProps) => {

  const content = (
    <Grid>
      <Row>
        <Col span={24}>
          <InputComponentAutocomplete
            id={"organization"}
            name={"organization"}
            required={true}
            showBackground={true}
            value ={props.entity.organization}
            label={translate("denaliApp.userOrganizationWarehouse.organization")}
            getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
            loadOptions={(inputValue) => lookupOrganizations(`${inputValue}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
            onChangeOption={option => {
              props.chooseOrganization(option)
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <InputComponentAutocomplete
            id={"user"}
            name={"user"}
            required={true}
            showBackground={true}
            label={translate("denaliApp.userOrganizationWarehouse.user")}
            getOptionLabel={option => option?.login?`${option.login}`:``}
            loadOptions={(inputValue) => lookupUsers(`${inputValue}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
            onChangeOption={option => {
              props.chooseUser(option)
            }}
          />
        </Col>
      </Row>
    </Grid>
  );

  return (
    <FormInputCrud
      entity={props.entity}
      updating={props.updating}
      updateSuccess={props.updateSuccess}
      loading={props.loading}
      errorMessage={props.errorMsg}

      navigationOnClose={navigation.master.userOrganizationWarehouse.view}


      component={content}

      titleForm={translate('denaliApp.userOrganizationWarehouse.home.createOrEditLabel')}
      titleCard={translate('denaliApp.userOrganizationWarehouse.home.title')}
      labelButtonDelete={translate('entity.action.delete')}
      labelButtonSave={translate('entity.action.save')}
      labelButtonCancel={translate('entity.action.cancel')}

      createEntity={props.createUOW}
      updateEntity={props.updateUOW}
      deleteEntity={props.deleteUOW}
      getEntity={props.getUOW}
      reset={props.reset}
    />
  );
}

const mapStateToProps = ({userOrganizationWarehouse}: IRootState) => ({
  entity: userOrganizationWarehouse.entity,
  updating: userOrganizationWarehouse.updating,
  updateSuccess: userOrganizationWarehouse.updateSuccess,
  errorMsg: userOrganizationWarehouse.errorMessage,
  loading: userOrganizationWarehouse.loading
});

const mapDispatchToProps = {reset, getUOW, createUOW, updateUOW, deleteUOW,chooseWarehouse,chooseOrganization,chooseUser};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserOrganizationWarehouseEdit);

