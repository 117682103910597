const config = {
  VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  CONSUMPTION: 'ROLE_CONSUMPTION',
  INPUT: 'ROLE_INPUT',
  BASES: 'ROLE_BASES',
  ORGANIZATION_RESTRICTED: 'ORGANIZATION_RESTRICTED',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'dd.MM.yyyy';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD.MM.YYYY';
export const APP_LOCAL_DATE_FORMAT_INPUT = 'dd.MM.yyyy';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_LOCAL_DATE_FORMAT_SEARCH = 'YYYY-MM-DD';
export const APP_LOCAL_DATE_FORMAT_SHORT = 'D.M.YY';

export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_DECIMAL_SEPARATOR = ',';
export const APP_THOUSAND_SEPARATOR = '.';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const APP_NUMBER_FORMAT_DECIMAL_SCALE = 2;
