import React, {useEffect} from "react";
import {Formik} from "formik";
import {
  Grid,
  Col,
  Row,
  InputComponentText,
  HeadlineH0,
  InputComponentNumber,
  ActionButton,
  STANDARD_SAVE_SUBMIT_PROPS,
   InputComponentSelectAsync, LIST_ITEMS_PER_PAGE
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {APP_DECIMAL_SEPARATOR, APP_THOUSAND_SEPARATOR} from "app/config/constants";
import {useDispatch, useSelector} from "react-redux";
import {lookupVariations} from "app/shared/lookups/variation/variation.lookup";
import {
  addConsumptionInput, chooseVariation, removeConsumptionInput, resetConsumptionInput,
} from "app/shared/reducers/consumption/insert/consumption.insert";
import {IRootState} from "app/shared/reducers";
import {InputComponentAutocomplete} from "@msvsustavi/msv-ui-react/dist";


const ManualEntryDialogComponent = (props) => {

  const dispatch = useDispatch();
  const {variation,consumptionInput} = useSelector(({consumption}:IRootState)=>consumption.insert);

  useEffect( () =>{
    if(consumptionInput.variation == null){
      resetConsumptionInput()
    }
  },[]);

  return (
    <Formik
      initialValues={consumptionInput}
      enableReinitialize = {true}
      onSubmit={(consumptionInput, actions) => {
          dispatch(removeConsumptionInput(consumptionInput))
          dispatch(addConsumptionInput({
            ...consumptionInput,
            variationId : variation.id
            }
          ));
          actions.resetForm();
          dispatch(resetConsumptionInput())
      }}
    >
      {
        formik => (
          <form onSubmit={formik.handleSubmit} >
            <Grid>
              <HeadlineH0 headline={translate("denaliApp.consumption.insert.title")}/>
              <Row>
                <Col>
                    <InputComponentAutocomplete
                      id={"variation"}
                      name={"variation"}
                      required={true}
                      showBackground={true}
                      label={translate("denaliApp.consumption.variation")}
                      getOptionLabel={option => option?.variationName?`${option.sku} - ${option.variationName} - (${option.uom})`:``}
                      loadOptions={(inputValue) => lookupVariations(`variationName.contains=${inputValue}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
                      onChangeOption={option => {
                        dispatch(chooseVariation(option))
                      }}
                    />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentNumber
                    id={"quantity"}
                    name={"quantity"}
                    label={translate("denaliApp.consumption.quantity")}
                    required={true}
                    showBackground={true}
                    decimalScale={3}
                    thousandSeparator={APP_THOUSAND_SEPARATOR}
                    decimalSeparator={APP_DECIMAL_SEPARATOR}
                  />
                </Col>
              </Row>
               <Row>
                 <Col>
                    <InputComponentText
                      id={"uqDesignation"}
                      name={"uqDesignation"}
                      label={translate("denaliApp.consumption.uqDesignation")}
                      required={false}
                      showBackground={false}
                    />
                  </Col>
                  <Col>
                    <InputComponentText
                      id={"document"}
                      name={"document"}
                      label={translate("denaliApp.consumption.document")}
                      required={false}
                      showBackground={false}
                    />
                  </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentText
                    id={"location"}
                    name={"location"}
                    label={translate("denaliApp.consumption.location")}
                    required={false}
                    showBackground={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col classname={"d-flex justify-content-center"}>
                  <ActionButton
                    label={translate('entity.action.save')}
                    {...STANDARD_SAVE_SUBMIT_PROPS}
                    faIcon={true}
                    icon={["far","plus"]}
                  />
                </Col>
              </Row>
            </Grid>
          </form>
        )
      }
    </Formik>
  );
};

export default ManualEntryDialogComponent;
