import React, {useContext, useMemo} from 'react';
import { Switch, Route} from 'react-router-dom';

import {NavigationModulesLanding} from '@msvsustavi/msv-ui-react/dist';
import {translate} from "react-jhipster";
import {HeaderElementContext} from "app/modules/layout/header";
import {HeaderElement} from "@msvsustavi/msv-ui-react";
import {navigation} from "app/shared/util/navigation.constants";
import Variation from "app/modules/master/variation";
import Organization from "app/modules/master/organization";
import Warehouse from "app/modules/master/warehouse";
import UserOrganizationWarehouse from "app/modules/master/user-organization-warehouse";

const Routes = () => {
  const {setCurrentTitleElement} = useContext(HeaderElementContext);
  useMemo(() => {
    setCurrentTitleElement(<HeaderElement title={''} subtitle={''}/>);
  }, []);

  return <>
    <Switch>
      <NavigationModulesLanding
        modules={[
          {
            name: translate('denaliApp.modules.basic.name'),
            label: translate('denaliApp.modules.basic.label'),
            icon: ["fad", "database"],
            faIcon: true,
            target: '/master/home',
            exact: true,
            description: translate('denaliApp.modules.basic.description'),
            functionalities: [
              {
                name: translate('denaliApp.modules.basic.organization.title'),
                label: translate('denaliApp.modules.basic.organization.label'),
                icon: ["fas", "sitemap"],
                faIcon: true,
                target: navigation.master.organization.view,
                description: translate('denaliApp.modules.basic.organization.description')
              },
              {
                name: translate('denaliApp.modules.basic.warehouse.title'),
                label: translate('denaliApp.modules.basic.warehouse.label'),
                icon: ["fas", "warehouse"],
                faIcon: true,
                target: navigation.master.warehouse.view,
                description: translate('denaliApp.modules.basic.warehouse.description')
              },
              {
                name: translate('denaliApp.modules.basic.variation.title'),
                label: translate('denaliApp.modules.basic.variation.label'),
                icon: ["fas", "percentage"],
                faIcon: true,
                target: navigation.master.variation.view,
                description: translate('denaliApp.modules.basic.variation.description')
              },
              {
                name: translate('denaliApp.modules.basic.userOrganizationWarehouse.title'),
                label: translate('denaliApp.modules.basic.userOrganizationWarehouse.label'),
                icon: ["fal", "layer-group"],
                faIcon: true,
                target: navigation.master.userOrganizationWarehouse.view,
                description: translate('denaliApp.modules.basic.userOrganizationWarehouse.description')
              }
            ]
          }
        ]}
        modulesPerRow={4}
        headerName={translate('denaliApp.modules.headerName')}
        headerTitle={translate('denaliApp.modules.view.name')}
        labelReturn={translate('entity.action.back')}
      />
    </Switch>
    <Switch>
      <Route path={navigation.master.userOrganizationWarehouse.view} component={UserOrganizationWarehouse}/>
      <Route path={navigation.master.organization.view} component={Organization}/>
      <Route path={navigation.master.variation.view} component={Variation}/>
      <Route path={navigation.master.warehouse.view} component={Warehouse}/>
    </Switch>
  </>
};

export default Routes;
