import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IRootState} from 'app/shared/reducers';
import {
  ACTION_TYPE,
  BUTTON_SHAPE,
  FormTable,
  isComplexQuery,
  LAYOUT_TYPE,
  parseQuery
} from '@msvsustavi/msv-ui-react/dist';
import {translate} from "react-jhipster";
import {navigation} from "app/shared/util/navigation.constants";
import {getSearchWarehouses, getWarehouses} from "app/shared/reducers/master/warehouse/warehouse.reducer";
import {WarehouseDataTableColumns} from "app/shared/model/master/warehouse/warehouse.column.model";


export const WarehouseView = (props) => {

  const dispatch = useDispatch();
  const warehouses = useSelector(({warehouse}: IRootState) => warehouse.entities);
  const count = useSelector(({warehouse}: IRootState) => warehouse.totalItems);
  const loading = useSelector(({warehouse}: IRootState) => warehouse.loading);

  return <FormTable
    loadEntities={
      (activepage, itemsPerPage, sort) => {
        return dispatch(getWarehouses(activepage - 1, itemsPerPage, sort));
      }
    }
    filterEntities={
      (query, activepage, itemsPerPage, sort) => {
        return dispatch(getSearchWarehouses(isComplexQuery(query) ? parseQuery(query) : `name.contains=${query}`, activepage - 1, itemsPerPage, sort));
      }
    }
    columns={WarehouseDataTableColumns}
    items={warehouses}
    count={count}
    exportEntities={() => {
    }}
    actions={[
      {
        label: translate('denaliApp.warehouse.home.createLabel'),
        showLabel: true,
        icon: 'icon-add',
        name: 'new-country',
        type: ACTION_TYPE.LINK,
        layout: LAYOUT_TYPE.TOP_ICON_BOTTOM_TEXT,
        shape: BUTTON_SHAPE.ROUNDED,
        link: navigation.master.warehouse.new
      }
    ]}
    loading={loading}
  />;

}


export default WarehouseView;
