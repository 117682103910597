import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IConsumptionGrouped, defaultValue, IConsumptionGroupedSearch } from 'app/shared/model/consumption/consumption-grouped.model';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { IVariation } from 'app/shared/model/master/variation/variation.model';
import { IConsumptionTransaction } from 'app/shared/model/consumption/consumption-transaction.model';
import { EConsumptionTransactionStatus } from 'app/shared/model/enumeration/e-transaction-status.model';

export const ACTION_TYPES = {
  SEARCH_CONSUMPTIONGROUPEDS: 'consumptionGrouped/SEARCH_CONSUMPTIONGROUPEDS',
  FETCH_CONSUMPTIONGROUPED_LIST: 'consumptionGrouped/FETCH_CONSUMPTIONGROUPED_LIST',
  FETCH_CONSUMPTIONGROUPED: 'consumptionGrouped/FETCH_CONSUMPTIONGROUPED',
  CREATE_CONSUMPTIONGROUPED: 'consumptionGrouped/CREATE_CONSUMPTIONGROUPED',
  UPDATE_CONSUMPTIONGROUPED: 'consumptionGrouped/UPDATE_CONSUMPTIONGROUPED',
  DELETE_CONSUMPTIONGROUPED: 'consumptionGrouped/DELETE_CONSUMPTIONGROUPED',
  RESET: 'consumptionGrouped/RESET',

  SET_SEARCH: 'consumptionGrouped/SET_SEARCH',
  CLEAR_SEARCH: 'consumptionGrouped/CLEAR_SEARCH',

  CHOOSE_ORGANIZATION: 'consumptionGrouped/CHOOSE_ORGANIZATION',
  CHOOSE_WAREHOUSE: 'consumptionGrouped/CHOOSE_WAREHOUSE',
  CHOOSE_VARIATION: 'consumptionGrouped/CHOOSE_VARIATION',
};

const defaultConsumptionGroupedSearch: IConsumptionGroupedSearch = {
  statuses: [] as Array<EConsumptionTransactionStatus>,
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IConsumptionGrouped>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,

  consumptionGroupedSearchData: { ...defaultConsumptionGroupedSearch },

  organization: null as Readonly<IOrganization>,
  warehouse: null as Readonly<IWarehouse>,
  variation: null as Readonly<IVariation>,
};

export type ConsumptionGroupedState = Readonly<typeof initialState>;

// Reducer

export default (state: ConsumptionGroupedState = initialState, action): ConsumptionGroupedState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_CONSUMPTIONGROUPEDS):
    case REQUEST(ACTION_TYPES.FETCH_CONSUMPTIONGROUPED_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CONSUMPTIONGROUPED):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_CONSUMPTIONGROUPED):
    case REQUEST(ACTION_TYPES.UPDATE_CONSUMPTIONGROUPED):
    case REQUEST(ACTION_TYPES.DELETE_CONSUMPTIONGROUPED):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_CONSUMPTIONGROUPEDS):
    case FAILURE(ACTION_TYPES.FETCH_CONSUMPTIONGROUPED_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CONSUMPTIONGROUPED):
    case FAILURE(ACTION_TYPES.CREATE_CONSUMPTIONGROUPED):
    case FAILURE(ACTION_TYPES.UPDATE_CONSUMPTIONGROUPED):
    case FAILURE(ACTION_TYPES.DELETE_CONSUMPTIONGROUPED):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_CONSUMPTIONGROUPEDS):
    case SUCCESS(ACTION_TYPES.FETCH_CONSUMPTIONGROUPED_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONSUMPTIONGROUPED):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_CONSUMPTIONGROUPED):
    case SUCCESS(ACTION_TYPES.UPDATE_CONSUMPTIONGROUPED):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_CONSUMPTIONGROUPED):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.SET_SEARCH:
      return {
        ...state,
        consumptionGroupedSearchData: action.payload,
      };
    case ACTION_TYPES.CLEAR_SEARCH:
      return {
        ...state,
        consumptionGroupedSearchData: defaultConsumptionGroupedSearch,
      };
    case ACTION_TYPES.CHOOSE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case ACTION_TYPES.CHOOSE_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    case ACTION_TYPES.CHOOSE_VARIATION:
      return {
        ...state,
        variation: action.payload,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/consumption-groupeds';

export const loadConsumptionGrouped: ICrudGetAllAction<IConsumptionGrouped[]> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTIONGROUPED_LIST,
    payload: axios.get<IConsumptionGrouped[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const searchConsumptionGrouped = (
  query: string | undefined,
  page: number | undefined,
  size: number | undefined,
  sort: string | undefined
) => {
  const requestUrl = `${apiUrl}${sort ? `?${query}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTIONGROUPED_LIST,
    payload: axios.get<IConsumptionGrouped[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const fetchConsumptionsGroupedAllSelection = (selection, userId, page?: number, sort?: string) => {
  const requestUrl = `${apiUrl}/all/${userId}${sort ? `?page=${page}&size=${ITEMS_PER_PAGE}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTIONGROUPED_LIST,
    payload: axios.put<any>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, selection),
  };
};

export const getEntities: ICrudGetAllAction<IConsumptionGrouped> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTIONGROUPED_LIST,
    payload: axios.get<IConsumptionGrouped>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IConsumptionGrouped> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTIONGROUPED,
    payload: axios.get<IConsumptionGrouped>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IConsumptionGrouped> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CONSUMPTIONGROUPED,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IConsumptionGrouped> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CONSUMPTIONGROUPED,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IConsumptionGrouped> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CONSUMPTIONGROUPED,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const setSearchData = (consumptionGroupedSearchData: IConsumptionGroupedSearch) => {
  return {
    type: ACTION_TYPES.SET_SEARCH,
    payload: consumptionGroupedSearchData,
  };
};

export const clearSearchData = () => {
  return {
    type: ACTION_TYPES.CLEAR_SEARCH,
  };
};

export const chooseOrganization = (organization: IOrganization) => {
  return {
    type: ACTION_TYPES.CHOOSE_ORGANIZATION,
    payload: organization,
  };
};

export const chooseWarehouse = (wh: IWarehouse) => {
  return {
    type: ACTION_TYPES.CHOOSE_WAREHOUSE,
    payload: wh,
  };
};

export const chooseVariation = (variation: IVariation) => {
  return {
    type: ACTION_TYPES.CHOOSE_VARIATION,
    payload: variation,
  };
};
