import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  Card,
  CARD_IMPORTANCE,
  Grid,
  Col,
  Row, HeadlineH0, getSortState
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {IRootState} from "app/shared/reducers";
import {InputComponentText} from "@msvsustavi/msv-ui-react/dist";

import {formatDateForDisplay} from "app/shared/util/date-utils";
import classNames from "classnames/bind";
import {searchTrupciFakture} from "app/entities/nabavawrapper/trupci-fakture/trupci-fakture.reducer";
import {ITEMS_PER_PAGE_DIALOG} from "app/shared/util/pagination.constants";
import {useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Formik} from "formik";
import {
  addInvoice,
  removeInvoice
} from "app/shared/reducers/invoiceHsBase/transaction/insert/invoice-hs-base-transaction-insert.reducer";


export default (props) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(getSortState(location, ITEMS_PER_PAGE_DIALOG));
  const { trupciFakture,
    trupciFaktureSearchData} = useSelector(({trupciFakture}: IRootState) => trupciFakture);
  const { selectedInvoices} = useSelector(({invoiceHsBaseTransactionInsert}: IRootState) => invoiceHsBaseTransactionInsert);

  const rowCN = classNames('p-2', 'border-bottom-1', 'border-bottom-color-brown-grey-two', 'row-hover', 'pointer');


  const loadTable = () => {
    dispatch(searchTrupciFakture(search ? search : "", paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`));
  };

  const startSearching = () => {
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(searchTrupciFakture(
      search,
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`
    ));
  };

  const clear = () => {
    setPaginationState({
      ...paginationState,
      sort: 'id',
      order: 'asc',
      activePage: 1
    });
    sortTable();
  };

  const sortTable = () => {
    loadTable();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      history.push(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    if (search) {
      startSearching();
    } else {
      clear();
    }
  }, [search]);

  const contentCardSearch = (
    <Formik
      initialValues={trupciFaktureSearchData}
      enableReinitialize
      onSubmit={(values, actions) => {
        let search = '';
        Object.keys(values).map(val => {
          if(val == 'stranica'){
            search += val && values[val] ? `${val}.equals=${values[val]}&` : '';
          }else{
            search += val && values[val] ? `${val}.contains=${values[val]}&` : '';
          }
        });
        dispatch(setSearch(search));
      }}
    >
      {
        formik => (
          <form onSubmit={formik.handleSubmit}>
            <Grid>
              <HeadlineH0 headline={translate("denaliApp.nabavawrapperTrupciFakture.searchTrupciFakture")}/>
              <Row>
                <Col>
                  <InputComponentText
                    id={"oznakaFakture"}
                    name={"oznakaFakture"}
                    label={translate("denaliApp.nabavawrapperTrupciFakture.oznakaFakture")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
                <Col>
                  <InputComponentText
                    id={"generiranaOznakaFakture"}
                    name={"generiranaOznakaFakture"}
                    label={translate("denaliApp.nabavawrapperTrupciFakture.generiranaOznakaFakture")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentText
                    id={"brojDokumenta"}
                    name={"brojDokumenta"}
                    label={translate("denaliApp.nabavawrapperTrupciFakture.brojDokumenta")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentText
                    id={"oznakaUgovora"}
                    name={"oznakaUgovora"}
                    label={translate("denaliApp.nabavawrapperTrupciFakture.oznakaUgovora")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
                <Col>
                  <InputComponentText
                    id={"generiranaOznakaUgovora"}
                    name={"generiranaOznakaUgovora"}
                    label={translate("denaliApp.nabavawrapperTrupciFakture.generiranaOznakaUgovora")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentText
                    id={"organizacija"}
                    name={"organizacija"}
                    label={translate("denaliApp.nabavawrapperTrupciFakture.organizacija")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
                <Col>
                  <InputComponentText
                    id={"partner"}
                    name={"partner"}
                    label={translate("denaliApp.nabavawrapperTrupciFakture.partner")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
              </Row>
            </Grid>
          </form>
        )
      }
    </Formik>
  );

  const contentCardTable = (
    <>
      {contentCardSearch}
      <div className={"w-100"}>
        {
          <div className={"results-wraper-big"}>
            <HeadlineH0 headline={translate("denaliApp.nabavawrapperTrupciFakture.chooseTrupciFakture")}/>
            <div className={"w-100 h-100 px-3 justify-content-end"}>
              {
                [].concat(trupciFakture) && [].concat(trupciFakture).map(row =>{
                  return (
                    <Row classname={rowCN}>
                      <Col span={2} visible={selectedInvoices.includes(row)}>
                        <FontAwesomeIcon className={"fs-1 color-shamrock-green"} icon={["far", "check-square"]}/>
                      </Col>
                      <Col span={2} visible={!selectedInvoices.includes(row)}>
                        <FontAwesomeIcon className={"fs-1 color-shamrock-green"} icon={["far", "square"]}/>
                      </Col>
                      <Col span={22}>
                        <div  onClick={
                          () => {
                            if(selectedInvoices != null && selectedInvoices.includes(row) ){
                              dispatch(removeInvoice(row))
                            }else{
                              dispatch(addInvoice(row))
                            }
                          }
                        }>
                          <div className={"d-flex flex-column"}>
                            <div className={"d-flex flex-row"}>
                              <div className={"main-data"}>Račun: {row?.oznakaFakture}</div>
                              <div className={"other-data"}>({row?.generiranaOznakaFakture} - {row?.brojDokumenta})</div>
                              <div className={"other-data"}>/ <b>Datum:</b> { formatDateForDisplay(row?.datumFakture)}</div>
                            </div>
                            <div className={"d-flex flex-row"}>
                              <div className={"other-data"}><b>Ugovor:</b> {row?.oznakaUgovora} ({row?.generiranaOznakaUgovora}) / <b>Partner:</b> {row?.partner} / <b>Organizacija:</b> {row?.organizacija} </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>)
                })
              }
            </div>
          </div>
        }
      </div>
      <div className={"w-100"}>
        {
          <div className={"w-100"}>
            <HeadlineH0 headline={translate("denaliApp.nabavawrapperTrupciFakture.selectedInvoices")}/>
            <div className={"w-100 h-100 px-3 justify-content-end"}>
              {
                [].concat(selectedInvoices) && [].concat(selectedInvoices).map(row =>{
                  return (
                    <Row classname={"border-bottom-1 border-bottom-color-brown-grey-two"}>
                      <Col span={24} classname={rowCN}>
                        <div  onClick={
                          () => {
                              dispatch(removeInvoice(row))}}>
                          <div className={"d-flex flex-column"}>
                            <div className={"d-flex flex-row"}>
                              <div className={"main-data"}>Račun: {row?.oznakaFakture}</div>
                              <div className={"other-data"}>({row?.generiranaOznakaFakture} - {row?.brojDokumenta})</div>
                              <div className={"other-data"}>/ <b>Datum:</b> { formatDateForDisplay(row?.datumFakture)}</div>
                            </div>
                            <div className={"d-flex flex-row"}>
                              <div className={"other-data"}><b>Ugovor:</b> {row?.oznakaUgovora} ({row?.generiranaOznakaUgovora}) / <b>Partner:</b> {row?.partner} / <b>Organizacija:</b> {row?.organizacija} </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>)
                })
              }
            </div>
          </div>
        }
      </div>
    </>
  );


  return (
    <Card
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={contentCardTable}
    />
  );
};
