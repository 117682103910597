import React from "react";
import {
  Card,
  CARD_IMPORTANCE,
  Grid,
  Row
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {InputComponentText} from "@msvsustavi/msv-ui-react/dist";


export default (props) => {

  const content = (
    <Grid>
      <Row>
        <div className = "col-sm-24 col-md-8 pe-2">
          <InputComponentText
            id={"input.transportPartner"}
            name={"input.transportPartner"}
            required={false}
            showBackground={true}
            label={translate("denaliApp.input.transportPartner")}
          />
        </div>
        <div className = "col-sm-24 col-md-8 pe-2">
          <InputComponentText
            id={"input.transportDriver"}
            name={"input.transportDriver"}
            required={false}
            showBackground={true}
            label={translate("denaliApp.input.transportDriver")}
          />
        </div>
        <div className = "col-sm-24 col-md-8 pe-2">
          <InputComponentText
            id={"input.transportRegistration"}
            name={"input.transportRegistration"}
            required={false}
            showBackground={true}
            label={translate("denaliApp.input.transportRegistration")}
          />
        </div>
      </Row>
      <Row>
        <div className = "col-sm-24 col-md-8 pe-2">
          <InputComponentText
            id={"input.transportKm"}
            name={"input.transportKm"}
            required={false}
            showBackground={true}
            label={translate("denaliApp.input.transportKm")}
          />
        </div>
        <div className = "col-sm-24 col-md-8 pe-2">
          <InputComponentText
            id={"input.transportLocationStart"}
            name={"input.transportLocationStart"}
            required={false}
            showBackground={true}
            label={translate("denaliApp.input.transportLocationStart")}
          />
        </div>
        <div className = "col-sm-24 col-md-8 pe-2">
          <InputComponentText
            id={"input.transportLocationEnd"}
            name={"input.transportLocationEnd"}
            required={false}
            showBackground={true}
            label={translate("denaliApp.input.transportLocationEnd")}
          />
        </div>
      </Row>
    </Grid>
  );

  return (
    <Card
      title={translate("denaliApp.input.transport")}
      titleBordered={true}
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={content}
    />
  );
};
