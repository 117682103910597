import React from "react";
import {Module} from '@msvsustavi/msv-ui-react/dist';
import {navigation} from "app/shared/util/navigation.constants";
export const modulesAll: Module[] = [
  {
    name:'Consumption',
    description:'Consumption',
    label:'Consumption',
    target:navigation.consumption.tabs.input,
    icon:["far","th-list"],
    faIcon: true,
    functionalities:[

    ]
  },
  {
    name:'Bases',
    description:'Bases',
    label:'Bases',
    target:navigation.invoiceHsBase.dashboard.view,
    icon:["fas","table"],
    faIcon: true,
    functionalities:[

    ]
  },
  {
    name:'Master data',
    description:'Master data',
    label:'Master data',
    target:navigation.master.home,
    icon:["far","cogs"],
    faIcon: true,
    functionalities:[

    ]
  },
];


export const modulesConsumption: Module[] = [
  {
    name:'Consumption',
    description:'Consumption',
    label:'Consumption',
    target:navigation.consumption.tabs.input,
    icon:["far","th-list"],
    faIcon: true,
    functionalities:[

    ]
  }
];

export const modulesBases: Module[] = [
  {
    name:'Bases',
    description:'Bases',
    label:'Bases',
    target:navigation.invoiceHsBase.dashboard.view,
    icon:["fas","table"],
    faIcon: true,
    functionalities:[

    ]
  }
];

export const modulesBasesAndConsumption: Module[] = [
  {
    name:'Consumption',
    description:'Consumption',
    label:'Consumption',
    target:navigation.consumption.tabs.input,
    icon:["far","th-list"],
    faIcon: true,
    functionalities:[

    ]
  },
  {
    name:'Bases',
    description:'Bases',
    label:'Bases',
    target:navigation.invoiceHsBase.dashboard.view,
    icon:["fas","table"],
    faIcon: true,
    functionalities:[

    ]
  }
];

export const modulesNon: Module[] = [

];
