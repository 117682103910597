import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  Card,
  CARD_IMPORTANCE,
  Grid,
  Col,
  Row
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {IRootState} from "app/shared/reducers";
import {InputComponentText} from "@msvsustavi/msv-ui-react/dist";


const InvoiceHsBaseTransactionHeader = () => {

  const dispatch = useDispatch();
  const transaction = useSelector(({invoiceHsBaseTransactionInsert}: IRootState) => invoiceHsBaseTransactionInsert.transaction);

  const content = (
    <Grid>
      <Row>
        <Col>
          <InputComponentText
            id={"transaction.transactionDesignation"}
            name={"transaction.transactionDesignation"}
            label={translate("denaliApp.invoiceHsBaseTransaction.transactionDesignation")}
            required={true}
            showBackground={true}
          />
        </Col>
      </Row>
    </Grid>
  );


  return (
    <Card
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={content}
    />
  );
};

export  default InvoiceHsBaseTransactionHeader;
