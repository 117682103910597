import React, {useEffect} from "react";
import {Formik} from "formik";
import {
  ActionButton,
  Col,
  Grid,
  HeadlineH0,
  InputComponentDate,
  InputComponentSelectAsync,
  LIST_ITEMS_PER_PAGE,
  Row,
  STANDARD_SAVE_SUBMIT_PROPS
} from "@msvsustavi/msv-ui-react";
import {Translate, translate} from "react-jhipster";
import {APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATE_FORMAT_INPUT} from "app/config/constants";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import {
  chooseOrganization,
  chooseStatusForChange,
  chooseWarehouse,
  reset, setShowDialogEdit,
  updateEntity,
} from "app/shared/reducers/transaction/consumption-transaction.reducer";
import {lookupOrganizations} from "app/shared/lookups/organization/organization.lookup";
import {lookupWarehouses} from "app/shared/lookups/warehouse/warehouse.lookup";
import classNames from "classnames/bind";
import {EConsumptionTransactionStatus} from "app/shared/model/enumeration/e-transaction-status.model";


const ConsumptionTransactionEditDialogComponent = (props) => {

  const dispatch = useDispatch();
  const consumptionTransaction = useSelector(({consumptionTransaction}:IRootState)=>consumptionTransaction.entity);
  const organization = useSelector(({consumptionTransaction}:IRootState)=>consumptionTransaction.organization);
  const warehouse = useSelector(({consumptionTransaction}:IRootState)=>consumptionTransaction.warehouse);
  const selectedStatus = useSelector(({consumptionTransaction}:IRootState)=>consumptionTransaction.status);


  const cnLink = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-90",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button"
  );

  const cnLinkChooseRejected = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-90",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button",
    "background-red"
  );

  const cnLinkChooseVerified = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-90",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button",
    "background-dark-lime-green"
  );

  const cnLinkChooseTransfer = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-90",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button",
    "background-vivid-purple"
  );


  useEffect( () =>{
    reset();
  },[]);

  return (
    <Formik
      initialValues={consumptionTransaction}
      enableReinitialize
      onSubmit={(values, actions) => {
        dispatch(updateEntity({
          ...values,
          status: selectedStatus !== null ? selectedStatus : consumptionTransaction.status
        }));
        actions.resetForm();
        dispatch(setShowDialogEdit(false))
      }}
    >
      {
        formik => (
          <form onSubmit={formik.handleSubmit} >
            <Grid>
              <HeadlineH0 headline={translate("denaliApp.EConsumptionTransactionStatus.changeStatus.label")}/>
              <Row>
                <Col visible={consumptionTransaction.status === EConsumptionTransactionStatus.PREPARED}>
                  <div className={selectedStatus === EConsumptionTransactionStatus.VERIFIED ? cnLinkChooseVerified : cnLink} onClick={() =>
                    selectedStatus ===  EConsumptionTransactionStatus.VERIFIED ? dispatch(chooseStatusForChange(null)) :
                    dispatch(chooseStatusForChange(EConsumptionTransactionStatus.VERIFIED))
                  }>
                    <div className={"d-flex flex-column"}>
                      <Translate contentKey={"denaliApp.EConsumptionTransactionStatus.VERIFIED.action"}/>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className={selectedStatus === EConsumptionTransactionStatus.REJECTED ? cnLinkChooseRejected : cnLink} onClick={() =>
                    selectedStatus ===  EConsumptionTransactionStatus.REJECTED ? dispatch(chooseStatusForChange(null)) :
                      dispatch(chooseStatusForChange(EConsumptionTransactionStatus.REJECTED))
                  }>
                    <div className={"d-flex flex-column"}>
                      <Translate contentKey={"denaliApp.EConsumptionTransactionStatus.REJECTED.action"}/>
                    </div>
                  </div>
                </Col>
                <Col visible={consumptionTransaction.status === EConsumptionTransactionStatus.VERIFIED}>
                  <div className={selectedStatus === EConsumptionTransactionStatus.TRANSFERED ? cnLinkChooseTransfer : cnLink} onClick={() =>
                    selectedStatus ===  EConsumptionTransactionStatus.TRANSFERED ? dispatch(chooseStatusForChange(null)) :
                      dispatch(chooseStatusForChange(EConsumptionTransactionStatus.TRANSFERED))
                  }>
                    <div className={"d-flex flex-column"}>
                      <Translate contentKey={"denaliApp.EConsumptionTransactionStatus.TRANSFERED.action"}/>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col classname={"d-flex justify-content-center"}>
                  <ActionButton
                    label={translate('entity.action.save')}
                    {...STANDARD_SAVE_SUBMIT_PROPS}
                    faIcon={true}
                    icon={["far","plus"]}
                  />
                </Col>
              </Row>
            </Grid>
          </form>
        )
      }
    </Formik>
  );
}

export default ConsumptionTransactionEditDialogComponent;
