// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../node_modules/css-loader/dist/cjs.js!@msvsustavi/msv-ui-styles/css/theme/sass/meru.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./logo-denali.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".appLogo {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") top center no-repeat !important;\n  background-size: contain !important;\n  width: 160px;\n  filter: brightness(0) invert(1);\n}\n\n.standard-layout-header-wrapper .standard-layout-header-wrapper .appLogo,\n.standard-layout-header-wrapper .standard-layout-header-wrapper .app-logo {\n  background-color: #cecfcf;\n}", ""]);
// Exports
module.exports = exports;
