import React from "react";
import {IRootState} from "app/shared/reducers";
import { useDispatch, useSelector} from "react-redux";
import {
  Card,
  CARD_IMPORTANCE,
  Grid,
  Col,
  Row
} from "@msvsustavi/msv-ui-react";
import classNames from "classnames/bind";
import {
  setDocumentInput,
  setUqInput,
  setVariationInput
} from "app/shared/reducers/consumption/insert/consumption.insert";
import {Translate, translate} from "react-jhipster";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default (props) => {

  const dispatch = useDispatch();
  const variationInput = useSelector(({consumption}:IRootState)=>consumption.insert.variationInput);
  const documentInput = useSelector(({consumption}:IRootState)=>consumption.insert.documentInput);
  const uqInput = useSelector(({consumption}:IRootState)=>consumption.insert.uqInput);

  const cnLink = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-90",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button"
  );

  const cnLinkChoose = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-90",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button",
    "background-dark-lime-green"
  );


  const content = (
    <Grid>
      <Row>
        <Col>
          <div className={variationInput ? cnLinkChoose : cnLink}  onClick={
            ()=>
            dispatch(setVariationInput(!variationInput))
             }>
            <div className={"d-flex flex-column"}>
              <Translate contentKey={"denaliApp.consumption.insert.manualEntry"}/>
            </div>
            <FontAwesomeIcon className={"fs-1 color-black"} icon={["far", "pen"]}/>
          </div>
        </Col>
        <Col>
          <div className={uqInput ? cnLinkChoose : cnLink}  onClick={
            ()=>
              dispatch(setUqInput(!uqInput))
          }>
            <div className={"d-flex flex-column"}>
              <Translate contentKey={"denaliApp.consumption.insert.plateLookup"}/>
            </div>
            <FontAwesomeIcon className={"fs-1 color-black"} icon={["fal", "rectangle-wide"]}/>
          </div>
        </Col>
        <Col>
          <div className={documentInput ? cnLinkChoose : cnLink}  onClick={
              ()=>
                dispatch(setDocumentInput(!documentInput))
            }>
            <div className={"d-flex flex-column"}>
              <Translate contentKey={"denaliApp.consumption.insert.documentLookup"}/>
            </div>
            <FontAwesomeIcon className={"fs-1 color-black"} icon={["fas", "th-list"]}/>
          </div>
        </Col>
      </Row>
    </Grid>
  );


  return (
    <Card
      title={translate("denaliApp.consumption.insert.chooseConsumptionInputType")}
      titleBordered={true}
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={content}
    />
  );
};
