import axios from 'axios';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ITrupciFakture, ITrupciFaktureSearch } from 'app/shared/model/nabavawrapper/trupci-fakture.model';

export const ACTION_TYPES = {
  FETCH_TRUPCIFAKTURE_LIST: 'trupciFakture/FETCH_TRUPCIFAKTURE_LIST',
  SET_SEARCH: 'trupciFakture/search/SET_SEARCH',
  RESET: 'trupciFakture/RESET',
};

const defaultTrupciFaktureSearch: ITrupciFaktureSearch = {};

const initialState = {
  loading: false,
  errorMessage: null,
  trupciFakture: [] as ReadonlyArray<ITrupciFakture>,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  trupciFaktureSearchData: { ...defaultTrupciFaktureSearch },
};

export type TrupciFaktureState = Readonly<typeof initialState>;

// Reducer

export default (state: TrupciFaktureState = initialState, action): TrupciFaktureState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TRUPCIFAKTURE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_TRUPCIFAKTURE_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRUPCIFAKTURE_LIST):
      return {
        ...state,
        loading: false,
        trupciFakture: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case ACTION_TYPES.SET_SEARCH:
      return {
        ...state,
        trupciFaktureSearchData: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'services/nabavawrapper/api/trupci-faktures';

export const searchTrupciFakture = (
  query: string | undefined,
  page: number | undefined,
  size: number | undefined,
  sort: string | undefined
) => {
  const requestUrl = `${apiUrl}${sort ? `?${query}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_TRUPCIFAKTURE_LIST,
    payload: axios.get<ITrupciFakture>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const setSearchData = (trupciFaktureSearchData: ITrupciFaktureSearch) => {
  return {
    type: ACTION_TYPES.SET_SEARCH,
    payload: trupciFaktureSearchData,
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
