import {Route, Switch} from "react-router-dom";
import {navigation} from "app/shared/util/navigation.constants";
import React from "react";

import ConsumptionsView from "app/modules/consumption/view/consumption.view";
import {TabRouter} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import ConsumptionGroupedView from "app/modules/consumptionGrouped/view/consumptionGrouped.view";
import InputsView from "app/modules/input/view/input.view";


const Routes = () => (
  <>
    <TabRouter tabs={
      [
        {
          id: 'inputs',
          label: translate('denaliApp.input.home.title'),
          target: navigation.consumption.tabs.input,
          showAlert: false
        },
        {
          id: 'consumptions',
          label: translate('denaliApp.consumption.home.title'),
          target: navigation.consumption.tabs.consumptionTable,
          showAlert: false
        },
        {
          id: 'grouped',
          label: translate('denaliApp.consumptionGrouped.home.title'),
          target: navigation.consumption.tabs.consumptionGrouped,
          showAlert: false
        }
      ]
    }>
    </TabRouter>
    <Switch>
      <Route path={navigation.consumption.tabs.input}>
        <InputsView/>
      </Route>
      <Route path={navigation.consumption.tabs.consumptionTable}>
        <ConsumptionsView/>
      </Route>
      <Route path={navigation.consumption.tabs.consumptionGrouped}>
        <ConsumptionGroupedView/>
      </Route>
    </Switch>
  </>
);

export default Routes;
