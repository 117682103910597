import React, {useEffect, useState} from "react";
import {useRouteMatch, Route, useHistory} from "react-router-dom";
import {translate} from "react-jhipster";
import {
  Card, CARD_IMPORTANCE,
  FlatTable, FlatTableDataCell,
  FlatTableDataRow,
  FlatTableHeader,
  FlatTableHeaderCell, getSortState
} from "@msvsustavi/msv-ui-react/dist";
import NumberFormat from "react-number-format";
import {APP_DECIMAL_SEPARATOR, APP_NUMBER_FORMAT_DECIMAL_SCALE, APP_THOUSAND_SEPARATOR} from "app/config/constants";
import {ITEMS_PER_PAGE_DIALOG} from "app/shared/util/pagination.constants";
import {useDispatch, useSelector} from "react-redux";
import {getItemsByHsBase} from "app/shared/reducers/invoiceHsBase/base/item/invoice-hs-base-item.reducer";
import {IRootState} from "app/shared/reducers";
import {getInvoicesForBase} from "app/shared/reducers/invoiceHsBase/base/invoices/invoice-hs-base-invoice.reducer";

const InvoiceHsBaseTabsInvoices = () => {

  const [paginationState, setPaginationState] = useState(getSortState(location, ITEMS_PER_PAGE_DIALOG));
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const items  = useSelector(({invoiceHsBaseInvoice}: IRootState) => invoiceHsBaseInvoice.entities);

  useEffect(() => {
    dispatch(getInvoicesForBase(match.params['id']))
  }, []);

  const handleSort = (column, order) => {
    setPaginationState({
      ...paginationState,
      sort: column,
      order: order
    });
    history.push(`${location.pathname}?page=${paginationState.activePage}&sort=${column},${order}`);
  };


  const contentCardTable = (
    <>
      <div className={"d-flex w-100 flex-column justify-content-end align-items-end"}>
        <div className={"w-100 px-3 justify-content-end"}>
          <FlatTable>
            <FlatTableHeader>

              <FlatTableHeaderCell  first showSort onHeaderClick={(sort) => handleSort("invoiceDesignation", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseInvoice.invoiceDesignation')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("generatedInvoiceDesignation", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseInvoice.generatedInvoiceDesignation')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("documentNumber", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseInvoice.documentNumber')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("partnerName", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                { translate('denaliApp.invoiceHsBaseInvoice.partnerName')}
              </FlatTableHeaderCell>

            </FlatTableHeader>
            {
              [].concat(items) && [].concat(items).map(row =>(
                <FlatTableDataRow>

                  <FlatTableDataCell type={"begin"} showBorder={false}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.invoiceDesignation}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"} showBorder={true}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.generatedInvoiceDesignation}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"} showBorder={true}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.documentNumber}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.partnerName}
                      </div>
                    }
                  </FlatTableDataCell>

                </FlatTableDataRow>

              ))
            }
          </FlatTable>
        </div>
      </div>
    </>
  );


  return (
    <Card
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={contentCardTable}
    />
  );

}

export default InvoiceHsBaseTabsInvoices;
