export interface IInvoiceHsBaseItem {
  id?: number;
  priceLength?: string;
  woodTypeName?: string;
  priceRange?: string;
  className?: string;
  fee?: number;
  unitPrice?: number;
  totalValueNoTaxWithExpenses?: number;
  totalValueWithTaxNoExpenses?: number;
  totalValueNoTaxNoExpenses?: number;
  manipulativeCosts?: number;
  invoiceHsBaseId?: number;
  m3?: number;
}

export const defaultValue: Readonly<IInvoiceHsBaseItem> = {};
