import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import {
  IUserOrganizationWarehouse,
  defaultValue,
} from 'app/shared/model/master/userOrganizationWarehouse/user-organization-warehouse.model';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IUser } from 'app/shared/model/user.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';

export const ACTION_TYPES = {
  SEARCH_USERORGANIZATIONWAREHOUSES: 'userOrganizationWarehouse/SEARCH_USERORGANIZATIONWAREHOUSES',
  FETCH_USERORGANIZATIONWAREHOUSE_LIST: 'userOrganizationWarehouse/FETCH_USERORGANIZATIONWAREHOUSE_LIST',
  FETCH_USERORGANIZATIONWAREHOUSE: 'userOrganizationWarehouse/FETCH_USERORGANIZATIONWAREHOUSE',
  CREATE_USERORGANIZATIONWAREHOUSE: 'userOrganizationWarehouse/CREATE_USERORGANIZATIONWAREHOUSE',
  UPDATE_USERORGANIZATIONWAREHOUSE: 'userOrganizationWarehouse/UPDATE_USERORGANIZATIONWAREHOUSE',
  DELETE_USERORGANIZATIONWAREHOUSE: 'userOrganizationWarehouse/DELETE_USERORGANIZATIONWAREHOUSE',
  RESET: 'userOrganizationWarehouse/RESET',

  CHOOSE_ORGANIZATION: 'userOrganizationWarehouse/CHOOSE_ORGANIZATION',
  CHOOSE_USER: 'userOrganizationWarehouse/CHOOSE_USER',
  CHOOSE_WAREHOUSE: 'userOrganizationWarehouse/CHOOSE_WAREHOUSE',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IUserOrganizationWarehouse>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,

  organization: null as Readonly<IOrganization>,
  user: null as Readonly<IUser>,
  warehouse: null as Readonly<IWarehouse>,
};

export type UserOrganizationWarehouseState = Readonly<typeof initialState>;

// Reducer

export default (state: UserOrganizationWarehouseState = initialState, action): UserOrganizationWarehouseState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_USERORGANIZATIONWAREHOUSES):
    case REQUEST(ACTION_TYPES.FETCH_USERORGANIZATIONWAREHOUSE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_USERORGANIZATIONWAREHOUSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_USERORGANIZATIONWAREHOUSE):
    case REQUEST(ACTION_TYPES.UPDATE_USERORGANIZATIONWAREHOUSE):
    case REQUEST(ACTION_TYPES.DELETE_USERORGANIZATIONWAREHOUSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_USERORGANIZATIONWAREHOUSES):
    case FAILURE(ACTION_TYPES.FETCH_USERORGANIZATIONWAREHOUSE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_USERORGANIZATIONWAREHOUSE):
    case FAILURE(ACTION_TYPES.CREATE_USERORGANIZATIONWAREHOUSE):
    case FAILURE(ACTION_TYPES.UPDATE_USERORGANIZATIONWAREHOUSE):
    case FAILURE(ACTION_TYPES.DELETE_USERORGANIZATIONWAREHOUSE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_USERORGANIZATIONWAREHOUSES):
    case SUCCESS(ACTION_TYPES.FETCH_USERORGANIZATIONWAREHOUSE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERORGANIZATIONWAREHOUSE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_USERORGANIZATIONWAREHOUSE):
    case SUCCESS(ACTION_TYPES.UPDATE_USERORGANIZATIONWAREHOUSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_USERORGANIZATIONWAREHOUSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.CHOOSE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case ACTION_TYPES.CHOOSE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case ACTION_TYPES.CHOOSE_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-organization-warehouses';
// Actions

export const getSearchUOWs = (query: string | undefined, page: number | undefined, size: number | undefined, sort: string | undefined) => {
  const requestUrl = `${apiUrl}${sort ? `?${query}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_USERORGANIZATIONWAREHOUSE_LIST,
    payload: axios.get<IUserOrganizationWarehouse[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getUOWs: ICrudGetAllAction<IUserOrganizationWarehouse> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_USERORGANIZATIONWAREHOUSE_LIST,
    payload: axios.get<IUserOrganizationWarehouse>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getUOW: ICrudGetAction<IUserOrganizationWarehouse> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USERORGANIZATIONWAREHOUSE,
    payload: axios.get<IUserOrganizationWarehouse>(requestUrl),
  };
};

export const createUOW: ICrudPutAction<IUserOrganizationWarehouse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USERORGANIZATIONWAREHOUSE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getUOWs());
  return result;
};

export const updateUOW: ICrudPutAction<IUserOrganizationWarehouse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USERORGANIZATIONWAREHOUSE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteUOW: ICrudDeleteAction<IUserOrganizationWarehouse> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USERORGANIZATIONWAREHOUSE,
    payload: axios.delete(requestUrl),
  });
  dispatch(getUOWs());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const chooseOrganization = (organization: IOrganization) => {
  return {
    type: ACTION_TYPES.CHOOSE_ORGANIZATION,
    payload: organization,
  };
};

export const chooseWarehouse = (warehouse: IWarehouse) => {
  return {
    type: ACTION_TYPES.CHOOSE_WAREHOUSE,
    payload: warehouse,
  };
};

export const chooseUser = (user: IUser) => {
  return {
    type: ACTION_TYPES.CHOOSE_USER,
    payload: user,
  };
};
