export interface IOrganization {
  id?: number;
  designation?: string;
  name?: string;
  rest_url?: string;
  active?: boolean;
}

export const defaultValue: Readonly<IOrganization> = {
  active: false,
};
