import React, {useEffect, useRef, useState} from "react";
import {Formik} from "formik";
import {
  Grid,
  Col,
  Row,
  InputComponentText,
  HeadlineH0,
  InputComponentNumber,
  ActionButton,
  STANDARD_SAVE_SUBMIT_PROPS,
  Loading, DataTable, getPageBeginIndex, getPageEndIndex, getSortState, dataTableItemTemplateDate, Card, CARD_IMPORTANCE
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {useDispatch, useSelector} from "react-redux";
import {
  addPopratnica,
  fillConsumptionFromRekapitulacijeForPopratnica,
  fillConsumptionFromTrupciForPopratnica,
  removePopratnica,
} from "app/shared/reducers/consumption/insert/consumption.insert";
import {IRootState} from "app/shared/reducers";
import {DataTableColumn, InputComponentBoolean} from "@msvsustavi/msv-ui-react/dist";
import {ITEMS_PER_PAGE_DIALOG} from "app/shared/util/pagination.constants";
import {useHistory} from "react-router-dom";
import {searchPopratnice} from "app/entities/nabavawrapper/popratnice/popratnice.reducer";
import {IConsumption} from "app/shared/model/consumption/consumption.model";
import {APP_LOCAL_DATE_FORMAT} from "app/config/constants";
import classNames from "classnames/bind";

const DocumentEntryDialogComponent = () => {

  const dispatch = useDispatch();
  const popratniceSearchData = useSelector(({popratnice}: IRootState) => popratnice.documentSearchData);
  const loading = useSelector(({popratnice}: IRootState) => popratnice.loading);
  const popratnice = useSelector(({popratnice}: IRootState) => popratnice.entities);
  const total = useSelector(({popratnice}: IRootState) => popratnice.totalItems);
  const popratniceForInsert = useSelector(({consumption}: IRootState) => consumption.insert.popratniceForInsert);
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState(getSortState(location, ITEMS_PER_PAGE_DIALOG));

  const cnNotChosen = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-50",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button"
  );

  const cnPopratnicaChoose = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-50",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button",
    "background-dark-lime-green"
  );

  const loadTable = () => {
    dispatch(searchPopratnice(search ? search : "", paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`));
  };


  const startSearching = () => {
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(searchPopratnice(
      search,
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`
    ));
  };

  const sortTable = () => {
    loadTable();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      history.push(`${location.pathname}${endURL}`);
    }
  };

  const sort = (c: string, o: string) => {
    setPaginationState({
      ...paginationState,
      order: o,
      sort: c
    });
  };

  const handlePagination = (currentPage) => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  };

  const clear = () => {
    setPaginationState({
      ...paginationState,
      sort: 'id',
      order: 'asc',
      activePage: 1
    });
    sortTable();
  };

  useEffect(() => {
    if (search) {
      startSearching();
    } else {
      clear();
    }
  }, [search]);

  const columns: DataTableColumn[] = [
    {
      field: '',
      header: '',
      sortable: true,
      itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => {
        return <div
          className={popratniceForInsert != null && popratniceForInsert.includes(rowData) ? cnPopratnicaChoose : cnNotChosen}
          onClick={
            () => {
              if(popratniceForInsert != null && popratniceForInsert.includes(rowData) ){
                dispatch(removePopratnica(rowData))
              }else{
                dispatch(addPopratnica(rowData))
              }
            }
          }>
        </div>
      }
    },
    {
      field: 'brojDokumenta',
      header: translate('denaliApp.nabavawrapperPopratnice.brojDokumenta'),
      sortable: true,
      itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => {
        return <div className={"d-flex justify-content-center align-content-center"}>
          {rowData?.brojDokumenta} {rowData?.stranica}
        </div>
      }
    },
    {
      field: 'organizacija',
      header: translate('denaliApp.nabavawrapperPopratnice.orgPartner'),
      sortable: true,
      itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => {
        return <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
          <div className={"fw-bold border-bottom p-1"}>{rowData?.organizacija}</div>
          <div className={"p-1"}>{rowData?.partner}</div>
        </div>
      }
    },
    {
      field: 'upravaSuma',
      header: translate('denaliApp.nabavawrapperPopratnice.uspSum'),
      sortable: true,
      itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => {
        return <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
          <div className={"fw-bold border-bottom p-1"}>{rowData?.upravaSuma}</div>
          <div className={"p-1"}>{rowData?.sumarija}</div>
        </div>
      }
    },
    {
      field: 'datumPopratnice',
      header: translate('denaliApp.nabavawrapperPopratnice.datumPopratnice'),
      sortable: true,
      itemTemplate: (column, rowData: IConsumption, rowIndex, colIndex, columnCount) => dataTableItemTemplateDate(column, rowData, rowIndex, colIndex, columnCount, APP_LOCAL_DATE_FORMAT)
    }
  ];

  const contentCard = (
    <Formik
      initialValues={popratniceSearchData}
      enableReinitialize
      onSubmit={(values, actions) => {
        let search = '';
        Object.keys(values).map(val => {
          if(val == 'stranica'){
            search += val && values[val] ? `${val}.equals=${values[val]}&` : '';
          }else{
            search += val && values[val] ? `${val}.contains=${values[val]}&` : '';
          }
        });
        dispatch(setSearch(search));
      }}
    >
      {
        formik => (
          <form onSubmit={formik.handleSubmit}>
            <Grid>
              <HeadlineH0 headline={translate("denaliApp.nabavawrapperPopratnice.home.search")}/>
              <Row>
                <Col>
                  <InputComponentText
                    id={"brojDokumenta"}
                    name={"brojDokumenta"}
                    label={translate("denaliApp.nabavawrapperPopratnice.brojDokumenta")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
                <Col>
                  <InputComponentText
                    id={"stranica"}
                    name={"stranica"}
                    label={translate("denaliApp.nabavawrapperPopratnice.stranica")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentText
                    id={"organizacija"}
                    name={"organizacija"}
                    label={translate("denaliApp.nabavawrapperPopratnice.organizacija")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
                <Col>
                  <InputComponentText
                    id={"partner"}
                    name={"partner"}
                    label={translate("denaliApp.nabavawrapperPopratnice.partner")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentText
                    id={"upravaSuma"}
                    name={"upravaSuma"}
                    label={translate("denaliApp.nabavawrapperPopratnice.upravaSuma")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentText
                    id={"sumarija"}
                    name={"sumarija"}
                    label={translate("denaliApp.nabavawrapperPopratnice.sumarija")}
                    required={false}
                    showBackground={false}
                    onChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
                <Col>
                  <InputComponentBoolean
                    id={"indVM"}
                    name={"indVM"}
                    label={translate("denaliApp.nabavawrapperPopratnice.indVM")}
                    required={false}
                    showBackground={false}
                    onValueChange={() => {
                      formik.submitForm();
                    }}
                  />
                </Col>
              </Row>
            </Grid>
          </form>
        )
      }
    </Formik>
  );

  const contentCardTable = (
    <>
    <div className={"w-100 px-3"}>
      {loading ? <Loading/> : <DataTable
        columns={columns}
        data={[].concat(popratnice)}
        activePage={paginationState.activePage}
        itemsPerPage={ITEMS_PER_PAGE_DIALOG}
        maxButtons={4}
        paginationLabel={translate("table.tableLabel", {
          first: getPageBeginIndex(paginationState.activePage, paginationState.itemsPerPage),
          last: getPageEndIndex(paginationState.activePage, paginationState.itemsPerPage, total),
        })
        }
        onSelectPage={handlePagination}
        onSort={(column, order) => {
          sort(column.field, order);
        }}
        onReload={clear}
        totalItems={total}/>}
    </div>
  <Row>
    <Col classname={"d-flex justify-content-center"}>
      <ActionButton
        label={translate('denaliApp.nabavawrapperPopratnice.saveType.trupci')}
        {...STANDARD_SAVE_SUBMIT_PROPS}
        command={
          () => {
            dispatch(fillConsumptionFromTrupciForPopratnica(popratniceForInsert))
          }
        }
      />
      <ActionButton
        {...STANDARD_SAVE_SUBMIT_PROPS}
        label={translate('denaliApp.nabavawrapperPopratnice.saveType.rekapitulacija')}
        command={
          () => {
            dispatch(fillConsumptionFromRekapitulacijeForPopratnica(popratniceForInsert))
          }
        }
      />
    </Col>
  </Row>
      </>
  );

  return (
    <>
      {contentCard}
      {contentCardTable}
    </>
  );
}

export default DocumentEntryDialogComponent;

