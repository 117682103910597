import { translate } from 'react-jhipster';

export const enum EConsumptionTransactionStatus {
  INITIALIZED = 'INITIALIZED',
  PREPARED = 'PREPARED',
  VERIFIED = 'VERIFIED',
  TRANSFERED = 'TRANSFERED',
  REJECTED = 'REJECTED',
}

export const consumptionTransactionStatus = (eConsumptionTransactionStatus: EConsumptionTransactionStatus) => {
  return eConsumptionTransactionStatus ? `denaliApp.EConsumptionTransactionStatus.${eConsumptionTransactionStatus}.label` : '---';
};
