import React from "react";
import classNames from 'classnames/bind';import {
  consumptionTransactionStatus,
  EConsumptionTransactionStatus
} from "app/shared/model/enumeration/e-transaction-status.model";
import {translate} from "react-jhipster";

export interface IConsumptionOnTransactionProps {
  onTransaction: boolean;
  transactionId: number
}

export const ConsumptionOnTransaction = (props: IConsumptionOnTransactionProps) => {
  const cn = classNames(
    'rounded-circle',
    'square-4',
    {'background-dark-lime-green': props.onTransaction},
    {'background-red': !props.onTransaction},
  );

  return (
      <>
        <div className={"d-flex justify-content-center align-items-center"}>
          <div className={cn}/> {props.transactionId}
        </div>
      </>
  )
}

