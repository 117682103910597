import moment, { Moment } from 'moment';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';

export interface IInput {
  id?: number;
  inputDate?: Moment;
  quantity?: number;
  document?: string;
  page?: string;
  partner?: string;
  forestAdministration?: string;
  forestAdministrationDivision?: string;
  indVm?: boolean;
  transportPartner?: string;
  transportDriver?: string;
  transportRegistration?: string;
  transportKm?: string;
  transportLocationStart?: string;
  transportLocationEnd?: string;

  organizationId?: number;
  warehouseId?: number;
  variationId?: number;

  organization?: IOrganization;
  warehouse?: IWarehouse;
}

export const defaultValue: Readonly<IInput> = {
  inputDate : moment()
};

export interface IInputSearch {
  organizationId?: number;
  warehouseId?: number;

  organization?: IOrganization;
  warehouse?: IWarehouse;

  inputDate?: Moment;
  quantity?: number;
  document?: string;
  page?: string;
  partner?: string;
  forestAdministration?: string;
  forestAdministrationDivision?: string;
  indVm?: boolean;
  transportPartner?: string;
  transportDriver?: string;
  transportRegistration?: string;
  transportKm?: string;
  transportLocationStart?: string;
  transportLocationEnd?: string;
}
