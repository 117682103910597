import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import Popup from "reactjs-popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translate, translate} from "react-jhipster";
import {
  chooseOrganization, chooseVariation,
  chooseWarehouse,
  setSearchData
} from "app/shared/reducers/consumptionGrouped/consumption-grouped.reducer";
import {
  InputComponentAutocomplete,
  InputComponentDate,
  InputComponentNumber,
  LIST_ITEMS_PER_PAGE
} from "@msvsustavi/msv-ui-react";
import {lookupOrganizations, lookupOrganizationsSelection} from "app/shared/lookups/organization/organization.lookup";
import {lookupWarehouses} from "app/shared/lookups/warehouse/warehouse.lookup";
import {APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATE_FORMAT_INPUT} from "app/config/constants";
import {lookupVariations} from "app/shared/lookups/variation/variation.lookup";
import {InputNumber} from "antd";
import {InputComponentText} from "@msvsustavi/msv-ui-react/dist";
import moment from "moment";

/**
 *
 */
const BasicDataConsumptionGroupedSelection = (props) => {
  const dispatch = useDispatch();

  const {consumptionGroupedSearchData,organization,warehouse,variation} = useSelector(({consumptionGrouped}: IRootState) => consumptionGrouped);
  const {account} = useSelector(({authentication}: IRootState) => authentication);

  const [values, setValues] = useState(consumptionGroupedSearchData);
  useEffect(()=>{setValues(consumptionGroupedSearchData)},[consumptionGroupedSearchData]);


  return (
    <Popup
      trigger={open => <div className={"mx-4 d-flex align-items-center"}>
        <div className={"me-1"}><Translate contentKey={"denaliApp.consumptionGrouped.organization"}/></div>
        <FontAwesomeIcon icon={["far", "angle-down"]}/>
      </div>}
      closeOnDocumentClick
      position={['bottom right', 'bottom left']}
      arrow
      onClose={(event => {
        dispatch(setSearchData(values));
      })}
    >
      <div className={"d-flex flex-column popup p-3  rounded shadow"}>
        <div className={"d-flex flex-column"}>
          <div className={"px-2 py-1 text-uppercase fs-5 fw-bold selection-header"}><Translate contentKey={"denaliApp.consumptionGrouped.organization"}/></div>
          <div className={"container"}>
            <div className={"row g-0"}>
              <div className={"col-24"}>
                <InputComponentAutocomplete
                  id={"organizationId"}
                  name={"organizationId"}
                  required={true}
                  showBackground={true}
                  value = {organization}
                  wrapInForm={false}
                  label={translate("denaliApp.consumptionGrouped.organization")}
                  getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
                  loadOptions={(inputValue) => lookupOrganizationsSelection( 0, LIST_ITEMS_PER_PAGE, 'asc',account.id, inputValue != "" ? inputValue : null).then(res => res.data)}
                  onChangeOption={(option) =>{
                    setValues({
                      ...values,
                      organization: option
                    });
                    dispatch(chooseOrganization(option))
                  }
                  }
                />
              </div>
              <div className={"col-24"}>
                <InputComponentAutocomplete
                  id={"warehouseId"}
                  name={"warehouseId"}
                  required={true}
                  showBackground={true}
                  value = {warehouse}
                  label={translate("denaliApp.consumptionGrouped.warehouse")}
                  wrapInForm={false}
                  getOptionLabel={option => option?.name?`${option.designation} - ${option.name}` :``}
                  loadOptions={(consumptionGroupedValue) => lookupWarehouses(`name.contains=${consumptionGroupedValue}&organizationId.equals=${organization.id}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
                  onChangeOption={(option) =>{
                    setValues({
                      ...values,
                      warehouse: option
                    });
                    dispatch(chooseWarehouse(option))
                  }
                  }
                />
              </div>
              <div className={"col-24"}>
                <InputComponentAutocomplete
                  id={"variationId"}
                  name={"variationId"}
                  required={true}
                  showBackground={true}
                  value = {variation}
                  label={translate("denaliApp.consumptionGrouped.variation")}
                  wrapInForm={false}
                  getOptionLabel={option => option?.variationName?`${option.variationName} - ${option.productDesignation}` :``}
                  loadOptions={(consumptionGroupedValue) => lookupVariations(`name.contains=${consumptionGroupedValue}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
                  onChangeOption={(option) =>{
                    setValues({
                      ...values,
                      variation: option
                    });
                    dispatch(chooseVariation(option))
                  }
                  }
                />
              </div>
            </div>
            <div className={"row g-0"}>
              <div className={"col-12"}>
                <InputComponentDate
                  id={"periodBegin"}
                  name={"periodBegin"}
                  disabled={false}
                  required={true}
                  showBackground={false}
                  wrapInForm={false}
                  value={values.periodBegin}
                  placeholder={APP_LOCAL_DATE_FORMAT}
                  dateDisplayFormat={APP_LOCAL_DATE_FORMAT_INPUT}
                  label={translate('denaliApp.consumptionTransaction.periodBegin')}
                  onDateChange={(date) => {
                    setValues({
                      ...values,
                        periodBegin : moment(date.target.value, APP_LOCAL_DATE_FORMAT_INPUT)
                    });
                  }}
                />
              </div>
              <div className={"col-12"}>
                <InputComponentDate
                  id={"periodEnd"}
                  name={"periodEnd"}
                  disabled={false}
                  required={true}
                  showBackground={false}
                  wrapInForm={false}
                  value={values.periodEnd}
                  placeholder={APP_LOCAL_DATE_FORMAT}
                  dateDisplayFormat={APP_LOCAL_DATE_FORMAT_INPUT}
                  label={translate('denaliApp.consumptionTransaction.periodEnd')}
                  onDateChange={(date) => {
                    setValues({
                      ...values,
                      periodEnd : moment(date.target.value, APP_LOCAL_DATE_FORMAT_INPUT)
                    });
                  }}
                />
              </div>
            </div>
            <div className={"row g-0"}>
              <div className={"col-24"}>
                <InputComponentText
                  id={"id"}
                  name={"id"}
                  required={true}
                  showBackground={false}
                  wrapInForm={false}
                  value={values.id}
                  label={translate('denaliApp.consumptionTransaction.transactionId')}
                  onChange={(id) => {
                    setValues({
                      ...values,
                      id : id.target.value
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default BasicDataConsumptionGroupedSelection;
