import React, { useEffect} from "react";
import {useRouteMatch} from "react-router-dom";


import {useDispatch, useSelector} from 'react-redux';
import {
  getEntity
} from "app/shared/reducers/invoiceHsBase/base/base/invoice-hs-base.reducer";
import {IRootState} from "app/shared/reducers";

import InvoiceHsBaseCardHeader from "app/modules/invoiceHsBase/card/header/invoiceHsBase.card.header";
import {Formik} from "formik";
import InvoiceHsBaseTabs from "app/modules/invoiceHsBase/card/tabs/invoiceHsBase.card.tabs";



const InvoiceHsBaseCard = () => {

  const match = useRouteMatch();
  const dispatch = useDispatch();

  const invoiceHsBase =  useSelector(({invoiceHsBase}: IRootState) => invoiceHsBase.entity);

  useEffect(() => {
    dispatch(getEntity(match.params['id']));
  }, []);

  return (
    <div className={"w-100 justify-content-center align-items-start"}>

            <Formik
              enableReinitialize={true}
              initialValues={{invoiceHsBase}} onSubmit={() => {}}>
              <>
                 <InvoiceHsBaseCardHeader/>
                 <InvoiceHsBaseTabs/>
              </>
            </Formik>


    </div>
  );
}

export default InvoiceHsBaseCard;


