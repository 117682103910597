import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  Card,
  CARD_IMPORTANCE,
  Grid,
  Col,
  Row,
  InputComponentDate,  LIST_ITEMS_PER_PAGE
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import { lookupOrganizationsSelection} from "app/shared/lookups/organization/organization.lookup";
import {lookupWarehouses} from "app/shared/lookups/warehouse/warehouse.lookup";
import {chooseOrganization, chooseWarehouse} from "app/shared/reducers/consumption/insert/consumption.insert";
import {APP_LOCAL_DATE_FORMAT_INPUT} from "app/config/constants";
import {InputComponentAutocomplete} from "@msvsustavi/msv-ui-react/dist";
import {IRootState} from "app/shared/reducers";


export default (props) => {

  const dispatch = useDispatch();
  const {
    organization
  } = useSelector(({consumption}: IRootState) => consumption.insert);
  const {account} = useSelector(({authentication}: IRootState) => authentication);


  const content = (
    <Grid>
      <Row>
        <Col>
          <InputComponentAutocomplete
            id={"organization"}
            name={"organization"}
            required={true}
            showBackground={true}
            label={translate("denaliApp.consumption.organization")}
            getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
            loadOptions={(inputValue) => lookupOrganizationsSelection( 0, LIST_ITEMS_PER_PAGE, 'asc',account.id, inputValue != "" ? inputValue : null).then(res => res.data)}
            onChangeOption={(option) =>{
              dispatch(chooseOrganization(option));
            }
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputComponentAutocomplete
            id={"warehouse"}
            name={"warehouse"}
            required={true}
            showBackground={true}
            label={translate("denaliApp.consumption.warehouse")}
            getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
            loadOptions={(inputValue) => lookupWarehouses(`name.contains=${inputValue}&organizationId.equals=${organization.id}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
            onChangeOption={(option) =>
              dispatch(chooseWarehouse(option))
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputComponentDate
            id={"consumption.consumptionDate"}
            name={"consumption.consumptionDate"}
            label={translate("denaliApp.consumption.consumptionDate")}
            required={true}
            disabled={false}
            showBackground={true}
            dateDisplayFormat={APP_LOCAL_DATE_FORMAT_INPUT}
          />
        </Col>
      </Row>
    </Grid>
  );


  return (
    <Card
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={content}
    />
  );
};
