import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { ICrudDeleteAction, ICrudGetAllAction } from 'react-jhipster';
import axios from 'axios';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';
import { IInput, IInputSearch } from 'app/shared/model/input/input.model';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import authentication from 'app/shared/reducers/authentication';

export const ACTION_TYPES = {
  FETCH_INPUTS: 'input/view/FETCH_INPUTS',
  SEARCH_INPUTS: 'input/view/SEARCH_INPUTS',
  RESET: 'input/view/RESET',

  FETCH_INPUT_LIST: 'input/view/FETCH_INPUT_LIST',
  DELETE_INPUT: 'input/view/DELETE_INPUT',

  SET_SEARCH: 'input/view/SET_SEARCH',
  CLEAR_SEARCH: 'input/view/CLEAR_SEARCH',

  CHOOSE_ORGANIZATION: 'input/view/CHOOSE_ORGANIZATION',
  CHOOSE_WAREHOUSE: 'input/view/CHOOSE_WAREHOUSE',
};

const defaultInputSearch: IInputSearch = {};

const initialState = {
  loading: false,
  errorMessage: null,
  inputs: [] as ReadonlyArray<IInput>,
  totalItems: 0,
  updateSuccess: false,
  updating: false,

  inputSearchData: { ...defaultInputSearch },

  organization: null as Readonly<IOrganization>,
  warehouse: null as Readonly<IWarehouse>,
};

const inputSearch = {
  selection: null,
  userId: '',
};

export type InputViewState = Readonly<typeof initialState>;

export default (state: InputViewState = initialState, action): InputViewState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INPUTS):
    case REQUEST(ACTION_TYPES.FETCH_INPUT_LIST):
    case REQUEST(ACTION_TYPES.SEARCH_INPUTS):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.DELETE_INPUT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_INPUTS):
    case FAILURE(ACTION_TYPES.SEARCH_INPUTS):
    case FAILURE(ACTION_TYPES.FETCH_INPUT_LIST):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_INPUT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INPUTS):
    case SUCCESS(ACTION_TYPES.SEARCH_INPUTS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        inputs: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_INPUT_LIST):
      return {
        ...state,
        loading: false,
        inputs: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.DELETE_INPUT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
      };
    case ACTION_TYPES.SET_SEARCH:
      return {
        ...state,
        inputSearchData: action.payload,
      };
    case ACTION_TYPES.CLEAR_SEARCH:
      return {
        ...state,
        inputSearchData: defaultInputSearch,
      };
    case ACTION_TYPES.CHOOSE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case ACTION_TYPES.CHOOSE_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/inputs';

export const loadInputs: ICrudGetAllAction<IInput[]> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_INPUTS,
    payload: axios.get<IInput[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const fetchInputsAllSelection = (selection, userId, page?: number, sort?: string) => {
  const requestUrl = `${apiUrl}/all/${userId}${sort ? `?page=${page}&size=${ITEMS_PER_PAGE}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_INPUTS,
    payload: axios.put<any>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, selection),
  };
};

export const searchInputs = (query: string | undefined, page: number | undefined, size: number | undefined, sort: string | undefined) => {
  const requestUrl = `${apiUrl}${sort ? `?${query}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_INPUT_LIST,
    payload: axios.get<IInput[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getInputs: ICrudGetAllAction<IInput> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_INPUT_LIST,
    payload: axios.get<IInput>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const deleteInput: ICrudDeleteAction<IInput> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_INPUT,
    payload: axios.delete(requestUrl),
  });
  dispatch(getInputs());
  return result;
};

export const setSearchData = (inputSearchData: IInputSearch) => {
  return {
    type: ACTION_TYPES.SET_SEARCH,
    payload: inputSearchData,
  };
};

export const clearSearchData = () => {
  return {
    type: ACTION_TYPES.CLEAR_SEARCH,
  };
};

export const chooseOrganization = (organization: IOrganization) => {
  return {
    type: ACTION_TYPES.CHOOSE_ORGANIZATION,
    payload: organization,
  };
};

export const chooseWarehouse = (wh: IWarehouse) => {
  return {
    type: ACTION_TYPES.CHOOSE_WAREHOUSE,
    payload: wh,
  };
};

export const downloadExcelInput = selection => async dispatch => {
  return axios({
    url: `api/inputs/all/download`,
    method: 'PUT',
    responseType: 'blob', // important
    data: selection,
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'zaprimanje.xlsx');
    document.body.appendChild(link);
    link.click();
  });
};
