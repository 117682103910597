import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'react-dates/lib/css/_datepicker.css';
import './app.scss';

import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { hot } from 'react-hot-loader';

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import ErrorBoundary from 'app/shared/error/error-boundary';
import AppRoutes from 'app/routes';
import {User, UserContext} from "@msvsustavi/msv-ui-react";

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {}

export const App = (props: IAppProps) => {
  useEffect(() => {
    props.getSession();
    props.getProfile();
  }, []);

  const [user, setUser] = useState({} as User);
  useEffect(()=>{
    setUser({
      name: `${props.account.firstName} ${props.account.lastName}`,
      firstName: props.account.firstName,
      lastName: props.account.lastName,
      username: props.account.login
    })
  }, [props.account]);
  return (
    <Router basename={baseHref}>
      <UserContext.Provider value={user}>
        <ErrorBoundary>
          <AppRoutes />
        </ErrorBoundary>
      </UserContext.Provider>
    </Router>
  );
};

const mapStateToProps = ({ authentication}: IRootState) => ({
  loading: authentication.loading,
  isAuthenticated: authentication.isAuthenticated,
  account: authentication.account
});

const mapDispatchToProps = { setLocale, getSession, getProfile };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
