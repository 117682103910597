import { defaultValue, defaultValueInput, IConsumption } from 'app/shared/model/consumption/consumption.model';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';
import { v4 } from 'uuid';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IVariation } from 'app/shared/model/master/variation/variation.model';
import { IPOPRATNICE } from 'app/shared/model/nabavawrapper/popratnice.model';
import { ITRUPCI } from 'app/shared/model/nabavawrapper/trupci.model';
import { ICrudGetAction } from 'react-jhipster';
import { IPopratnicaRekaptulacija } from 'app/shared/model/nabavawrapper/popratnica-rekaptulacija.model';

export const ACTION_TYPES = {
  CREATE_CONSUMPTIONS: 'consumption/insert/CREATE_CONSUMPTIONS',
  ADD_CONSUMPTION_INPUT: 'consumption/insert/ADD_CONSUMPTION_INPUT',
  REMOVE_CONSUMPTION_INPUT: 'consumption/insert/REMOVE_CONSUMPTION_INPUT',

  CHOOSE_ORGANIZATION: 'consumption/insert/CHOOSE_ORGANIZATION',
  CHOOSE_WAREHOUSE: 'consumption/insert/CHOOSE_WAREHOUSE',
  CHOOSE_VARIATION: 'consumption/insert/CHOOSE_VARIATION',

  RESET: 'consumption/insert/RESET',

  FILL_CONSUMPTION_INPUT: 'consumption/insert/FILL_CONSUMPTION_INPUT',
  SET_VARIATION_INPUT: 'consumption/insert/SET_VARIATION_INPUT',
  SET_DOCUMENT_INPUT: 'consumption/insert/SET_DOCUMENT_INPUT',
  SET_UQ_INPUT: 'consumption/insert/SET_UQ_INPUT',

  ADD_TRUPAC_FOR_INSERT: 'consumption/insert/ADD_TRUPAC_FOR_INSERT',
  REMOVE_TRUPAC_FOR_INSERT: 'consumption/insert/REMOVE_TRUPAC_FOR_INSERT',

  ADD_POPRATNICA_FOR_INSERT: 'consumption/insert/ADD_POPRATNICA_FOR_INSERT',
  REMOVE_POPRATNICA_FOR_INSERT: 'consumption/insert/REMOVE_POPRATNICA_FOR_INSERT',

  FETCH_VARIATION_BY_NAME: 'consumption/insert/FETCH_VARIATION_BY_NAME',
  FETCH_REKAPITULACIJA_FOR_POPRATNICA: 'consumption/insert/FETCH_REKAPITULACIJA_FOR_POPRATNICA',
  RESET_CONSUMPTION_INSERT: 'consumption/insert/RESET_CONSUMPTION_INSERT',
};

const initialState = {
  loading: false,
  errorMessage: null,
  consumption: defaultValue,
  items: [] as ReadonlyArray<IConsumption>,
  consumptionInput: defaultValueInput,
  updating: false,
  updateSuccess: false,

  organization: null as Readonly<IOrganization>,
  warehouse: null as Readonly<IWarehouse>,
  variation: null as Readonly<IVariation>,

  variationInput: false,
  documentInput: false,
  uqInput: false,

  trupciForInsert: [],
  popratniceForInsert: [],

  rekapitulacijeForPopratnica: [],
};

export type ConsumptionInsertState = Readonly<typeof initialState>;

export default (state: ConsumptionInsertState = initialState, action): ConsumptionInsertState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_CONSUMPTIONS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_VARIATION_BY_NAME):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.FETCH_REKAPITULACIJA_FOR_POPRATNICA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_CONSUMPTIONS):
    case REQUEST(ACTION_TYPES.FETCH_VARIATION_BY_NAME):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_CONSUMPTIONS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        consumption: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_VARIATION_BY_NAME):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: true,
        loading: false,
        variation: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_REKAPITULACIJA_FOR_POPRATNICA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: true,
        loading: false,
        rekapitulacijeForPopratnica: action.payload.data,
      };
    case ACTION_TYPES.ADD_CONSUMPTION_INPUT: {
      const consumptions_input = [].concat(state.items);
      const n: IConsumption = action.payload;
      n.uuid = v4();
      consumptions_input.push(n);
      return {
        ...state,
        items: consumptions_input,
      };
    }
    case ACTION_TYPES.REMOVE_CONSUMPTION_INPUT: {
      const consumptions_input = state.items.filter(value => value.uuid !== action.payload.uuid);
      return {
        ...state,
        items: consumptions_input,
      };
    }
    case ACTION_TYPES.CHOOSE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case ACTION_TYPES.CHOOSE_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    case ACTION_TYPES.CHOOSE_VARIATION:
      return {
        ...state,
        variation: action.payload,
      };
    case ACTION_TYPES.SET_VARIATION_INPUT:
      return {
        ...state,
        variationInput: action.payload,
      };
    case ACTION_TYPES.SET_DOCUMENT_INPUT:
      return {
        ...state,
        documentInput: action.payload,
      };
    case ACTION_TYPES.SET_UQ_INPUT:
      return {
        ...state,
        uqInput: action.payload,
      };
    case ACTION_TYPES.ADD_TRUPAC_FOR_INSERT: {
      const trupci = [].concat(state.trupciForInsert);
      const n: ITRUPCI = action.payload;
      trupci.push(n);
      return {
        ...state,
        trupciForInsert: trupci,
      };
    }
    case ACTION_TYPES.ADD_POPRATNICA_FOR_INSERT: {
      const popratnice = [].concat(state.popratniceForInsert);
      const n: IPOPRATNICE = action.payload;
      popratnice.push(n);
      return {
        ...state,
        popratniceForInsert: popratnice,
      };
    }
    case ACTION_TYPES.REMOVE_TRUPAC_FOR_INSERT: {
      const trupci = state.trupciForInsert.filter(value => value.id !== action.payload.id);
      return {
        ...state,
        trupciForInsert: trupci,
      };
    }
    case ACTION_TYPES.REMOVE_POPRATNICA_FOR_INSERT: {
      const popratnice = state.popratniceForInsert.filter(value => value.id !== action.payload.id);
      return {
        ...state,
        popratniceForInsert: popratnice,
      };
    }
    case ACTION_TYPES.FETCH_VARIATION_BY_NAME:
      return {
        ...state,
        variation: action.payload,
      };
    case ACTION_TYPES.FILL_CONSUMPTION_INPUT:
      return {
        ...state,
        consumptionInput: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.RESET_CONSUMPTION_INSERT:
      return {
        ...state,
        consumptionInput: defaultValueInput,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/consumptions-list';

const apiUrlVariations = 'api/variations';

const apiUrlRekapitulacije = 'services/nabavawrapper/api/popratnica-rekapitulacijas';
const apiUrlTrupci = 'services/nabavawrapper/api/trupcis';

export const createConsumptions = (consumptionDTO: IConsumption, consumptionDTOList: IConsumption[]) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.CREATE_CONSUMPTIONS,
    payload: axios.post(apiUrl, {
      consumptionDTO,
      consumptionDTOList,
    }),
  });
};

export const chooseOrganization = (organization: IOrganization) => {
  return {
    type: ACTION_TYPES.CHOOSE_ORGANIZATION,
    payload: organization,
  };
};

export const chooseWarehouse = (wh: IWarehouse) => {
  return {
    type: ACTION_TYPES.CHOOSE_WAREHOUSE,
    payload: wh,
  };
};

export const chooseVariation = (variat: IVariation) => {
  return {
    type: ACTION_TYPES.CHOOSE_VARIATION,
    payload: variat,
  };
};

export const setVariationInput = (variationInput: boolean) => {
  return {
    type: ACTION_TYPES.SET_VARIATION_INPUT,
    payload: variationInput,
  };
};

export const setDocumentInput = (documentInput: boolean) => {
  return {
    type: ACTION_TYPES.SET_DOCUMENT_INPUT,
    payload: documentInput,
  };
};

export const setUqInput = (uqInput: boolean) => {
  return {
    type: ACTION_TYPES.SET_UQ_INPUT,
    payload: uqInput,
  };
};

export const getVariationByName: ICrudGetAction<IVariation> = variationName => {
  const requestUrl = `${apiUrlVariations}/findByName/${variationName}`;
  return {
    type: ACTION_TYPES.FETCH_VARIATION_BY_NAME,
    payload: axios.get<IVariation>(requestUrl),
  };
};

//Punjenje stavke iz trupca
export const fillConsumptionFromTrupac = (trupciForInsert: ITRUPCI[]) => async dispatch => {
  trupciForInsert.map(trupac => {
    const requestUrlVarijacija = `${apiUrlVariations}/findByName/${trupac.varijacija}`;
    axios.get<IVariation>(requestUrlVarijacija).then(variation => {
      const consup: IConsumption = {
        uuid: v4(),
        variation: variation.data,
        quantity: trupac.masaM3,
        document: trupac.brojDokumenta + ' ' + trupac.stranica,
        uqDesignation: trupac.brojPlocice,
      };
      dispatch(addConsumptionInput(consup));
    });
  });
};

//Punjenje iz rekapitulacije
export const fillConsumptionFromRekapitulacijeForPopratnica = (popratniceForInsert: IPOPRATNICE[]) => async dispatch => {
  popratniceForInsert.map(popratnica => {
    const requestUrl = `${apiUrlRekapitulacije}/findByHsPopratnicaId/${popratnica.id}`;
    const consumption = axios.get<IPopratnicaRekaptulacija[]>(requestUrl).then(res => {
      res.data &&
        res.data.map(rekap => {
          const requestUrlVarijacija = `${apiUrlVariations}/findByName/${rekap.varijacija}`;
          axios.get<IVariation>(requestUrlVarijacija).then(variation => {
            const consup: IConsumption = {
              uuid: v4(),
              variation: variation.data,
              quantity: rekap.volume,
              document: popratnica.brojDokumenta + ' ' + popratnica.stranica,
            };
            dispatch(addConsumptionInput(consup));
          });
        });
    });
  });
};

//Punjenje trupci
export const fillConsumptionFromTrupciForPopratnica = (popratniceForInsert: IPOPRATNICE[]) => async dispatch => {
  popratniceForInsert.map(popratnica => {
    const requestUrl = `${apiUrlTrupci}/findByHsPopratnicaId/${popratnica.id}`;
    const consumption = axios.get<ITRUPCI[]>(requestUrl).then(res => {
      res.data &&
        res.data.map(trupac => {
          const requestUrlVarijacija = `${apiUrlVariations}/findByName/${trupac.varijacija}`;
          axios.get<IVariation>(requestUrlVarijacija).then(variation => {
            const consup: IConsumption = {
              uuid: v4(),
              variation: variation.data,
              quantity: trupac.masaM3,
              document: popratnica.brojDokumenta + ' ' + popratnica.stranica,
              uqDesignation: trupac.brojPlocice,
            };
            dispatch(addConsumptionInput(consup));
          });
        });
    });
  });
};

export const addConsumptionInput = (consumptionInput: IConsumption) => {
  return {
    type: ACTION_TYPES.ADD_CONSUMPTION_INPUT,
    payload: consumptionInput,
  };
};

export const removeConsumptionInput = (consumptionInput: IConsumption) => {
  return {
    type: ACTION_TYPES.REMOVE_CONSUMPTION_INPUT,
    payload: consumptionInput,
  };
};

export const addTrupac = (trupac: ITRUPCI) => {
  return {
    type: ACTION_TYPES.ADD_TRUPAC_FOR_INSERT,
    payload: trupac,
  };
};

export const addPopratnica = (popratnica: IPOPRATNICE) => {
  return {
    type: ACTION_TYPES.ADD_POPRATNICA_FOR_INSERT,
    payload: popratnica,
  };
};

export const removeTrupac = (trupac: ITRUPCI) => {
  return {
    type: ACTION_TYPES.REMOVE_TRUPAC_FOR_INSERT,
    payload: trupac,
  };
};

export const removePopratnica = (popratnica: IPOPRATNICE) => {
  return {
    type: ACTION_TYPES.REMOVE_POPRATNICA_FOR_INSERT,
    payload: popratnica,
  };
};

export const fillConsumptionInput = (consumption: IConsumption) => {
  return {
    type: ACTION_TYPES.FILL_CONSUMPTION_INPUT,
    payload: consumption,
  };
};

export const resetConsumptionInput = () => {
  return {
    type: ACTION_TYPES.RESET_CONSUMPTION_INSERT,
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
