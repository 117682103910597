import { Moment } from 'moment';

export interface IInvoiceHsSelected {
  id?: number;
  invoiceDesignation?: string;
  generatedInvoiceDesignation?: string;
  documentNumber?: string;
  invoiceDate?: Moment;
  partnerName?: string;
  value?: number;
  organizationId?: number;
  invoiceHsBaseTransactionId?: number;
}

export const defaultValue: Readonly<IInvoiceHsSelected> = {};
