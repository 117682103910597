import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {
  getSortState,
  FlatTable,
  FlatTableHeaderCell,
  FlatTableHeader,
  FlatTableDataCell,
  FlatTableDataRow,
  FlatTablePagination,
  FlatTableDataRowActions,
} from "@msvsustavi/msv-ui-react";
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {IRootState} from "app/shared/reducers";
import { translate} from "react-jhipster";
import {formatDateForDisplay} from "app/shared/util/date-utils";
import {
  deleteInput, fetchInputsAllSelection,
} from "app/shared/reducers/input/view/input.view";
import InputSelection from "app/modules/input/view/selection/input.selection";
import {setInput, showEditDialog} from "app/shared/reducers/input/edit/input.edit";
import InputEditDialog from "app/modules/input/edit/inputEdit.dialog";


const InputsView = (props) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {isAuthenticated,account} = useSelector(({authentication}: IRootState) => authentication);
  const {inputs,totalItems,inputSearchData} = useSelector(({input}: IRootState) => input.view);
  const [paginationState, setPaginationState] = useState(
    {
      ...getSortState(location, ITEMS_PER_PAGE),
      sort: 'id',
      order: 'desc',
      activePage: 0
    }

  );
  const loadTable = () => {
    dispatch(fetchInputsAllSelection(inputSearchData,account.id, paginationState.activePage, `${paginationState.sort},${paginationState.order}`));
  };

  useEffect( () => {
      loadTable();
  },[])

  const sortTable = () => {
    loadTable();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      history.push(`${location.pathname}${endURL}`);
    }
  };

  const handlePagination = currentPage => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage -1
    });
    history.push(`${location.pathname}?page=${currentPage-1}&sort=${paginationState.sort},${paginationState.order}`);
  };

  useEffect(() => {
    sortTable()
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    dispatch(fetchInputsAllSelection(inputSearchData,account.id, paginationState.activePage, `${paginationState.sort},${paginationState.order}`));
  }, [inputSearchData]);

  const handleSort = (column, order) => {
    setPaginationState({
      ...paginationState,
      sort: column,
      order: order
    });
    history.push(`${location.pathname}?page=${paginationState.activePage}&sort=${column},${order}`);
  };

  return (
    isAuthenticated ?
    <>
      <InputSelection/>
      <div className={"d-flex w-100 flex-column justify-content-end align-items-end"}>
        <div className={"w-100 px-3 justify-content-end"}>
          <FlatTable>
            <FlatTableHeader>

              <FlatTableHeaderCell  first showSort
                                    onHeaderClick={(sort) => handleSort("organization", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}
                                    className={"col-3"}>
                {translate('denaliApp.input.organization')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort
                                   onHeaderClick={(sort) => handleSort("document", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}
              >
                {translate('denaliApp.input.document')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort
                                   onHeaderClick={(sort) => handleSort("partner", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}
              >
                {translate('denaliApp.input.partner')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell  showSort
                                    onHeaderClick={(sort) => handleSort("forestAdministration", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}
              >
                {translate('denaliApp.input.forestAdministration')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort
                                   onHeaderClick={(sort) => handleSort("forestAdministrationDivision", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}
              >
                { translate('denaliApp.input.forestAdministrationDivision')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort
                                   onHeaderClick={(sort) => handleSort("inputDate", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}
              >
                { translate('denaliApp.input.inputDate')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
              </FlatTableHeaderCell>

            </FlatTableHeader>
            {
              [].concat(inputs) && [].concat(inputs).map(row =>(
                <FlatTableDataRow>

                  <FlatTableDataCell type={"center"} showBorder={false} className={"col-3"}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.organization.designation} / {row.warehouse.designation}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"center"} showBorder={true}>
                    {
                      <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
                        <div className={"fw-bold  p-1"}>{row?.document} {row?.page}</div>
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"center"}>
                    {
                      <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
                        <div className={"fw-bold  p-1"}>{row?.partner}</div>
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"center"}>
                    {
                      <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
                        <div className={"fw-bold  p-1"}>{row?.forestAdministration}</div>
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"center"}>
                    {
                      <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
                        <div className={"fw-bold  p-1"}>{row?.forestAdministrationDivision}</div>
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"center"} >
                    {
                      <div className={"d-flex w-100 flex-column justify-content-begin align-content-center"}>
                        <div className={"fw-bold  p-1"}> { formatDateForDisplay(row?.inputDate)}</div>
                      </div>
                    }
                  </FlatTableDataCell>


                  <FlatTableDataRowActions showEdit={true} showDelete={true}  className={"col-2"} onEdit={ () => {
                      dispatch(setInput(row));
                      dispatch(showEditDialog(true))
                    }
                  }
                  onDelete={ () => {
                      dispatch(deleteInput(row?.id));
                    }
                  }
                  />

                </FlatTableDataRow>

              ))
            }
            <FlatTablePagination activePage={paginationState.activePage + 1} onSelect={(page) => {
              handlePagination(page)
            }} maxButtons={4} totalItems={totalItems} itemsPerPage={ITEMS_PER_PAGE} showPagination={true}/>
          </FlatTable>
        </div>
        <InputEditDialog/>
      </div>
    </> : <></>
  );
};



export default InputsView;
