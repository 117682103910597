import React from "react";
import {useRouteMatch, Route} from "react-router-dom";
import {translate} from "react-jhipster";
import {TabRouter, TabsRouter} from "@msvsustavi/msv-ui-react/dist";
import {navigation} from "app/shared/util/navigation.constants";
import InvoiceHsBaseTabsItems from "app/modules/invoiceHsBase/card/tabs/invoiceHsBase.card.tabs.items";
import InvoiceHsBaseTabsInvoices from "app/modules/invoiceHsBase/card/tabs/invoiceHsBase.card.tabs.invoices";

const InvoiceHsBaseTabs = () => {
  const match = useRouteMatch();
  const tabs: TabsRouter[] = [
    {
      id: 'itemsTab',
      label: translate('denaliApp.invoiceHsBaseItem.home.title'),
      showAlert: false,
      target: navigation.invoiceHsBase.card.tabs.items.replace(':id', match.params['id'])
    },
    {
      id: 'invoicesTab',
      label: translate('denaliApp.invoiceHsBaseInvoice.home.title'),
      showAlert: false,
      target: navigation.invoiceHsBase.card.tabs.invoices.replace(':id', match.params['id'])
    }
  ];
  return (
    <TabRouter tabs={tabs}>
      <Route path={navigation.invoiceHsBase.card.tabs.items}>
        <InvoiceHsBaseTabsItems/>
      </Route>
      <Route path={navigation.invoiceHsBase.card.tabs.invoices}>
        <InvoiceHsBaseTabsInvoices/>
      </Route>
    </TabRouter>
  )

}

export default InvoiceHsBaseTabs;
