import { defaultValue, IInput } from 'app/shared/model/input/input.model';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { ICrudPutAction } from 'react-jhipster';

export const ACTION_TYPES = {
  UPDATE_INPUT: 'input/edit/UPDATE_INPUT',
  FETCH_INPUT: 'input/edit/FETCH_INPUT',

  CHOOSE_ORGANIZATION: 'input/edit/CHOOSE_ORGANIZATION',
  CHOOSE_WAREHOUSE: 'input/edit/CHOOSE_WAREHOUSE',

  SHOW_EDIT_DIALOG: 'input/edit/SHOW_EDIT_DIALOG',

  RESET: 'input/edit/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  input: defaultValue,
  updating: false,
  updateSuccess: false,

  organization: null as Readonly<IOrganization>,
  warehouse: null as Readonly<IWarehouse>,
  showEditDialog: false,
};

export type InputEditState = Readonly<typeof initialState>;

export default (state: InputEditState = initialState, action): InputEditState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.UPDATE_INPUT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.UPDATE_INPUT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        input: {},
      };
    case SUCCESS(ACTION_TYPES.UPDATE_INPUT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        input: action.payload.data,
      };
    case ACTION_TYPES.FETCH_INPUT:
      return {
        ...state,
        input: action.payload,
      };
    case ACTION_TYPES.CHOOSE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case ACTION_TYPES.CHOOSE_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    case ACTION_TYPES.SHOW_EDIT_DIALOG:
      return {
        ...state,
        showEditDialog: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/inputs';

export const updateInput: ICrudPutAction<IInput> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INPUT,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const setInput = input => {
  return {
    type: ACTION_TYPES.FETCH_INPUT,
    payload: input,
  };
};

export const chooseOrganization = (organization: IOrganization) => {
  return {
    type: ACTION_TYPES.CHOOSE_ORGANIZATION,
    payload: organization,
  };
};

export const chooseWarehouse = (wh: IWarehouse) => {
  return {
    type: ACTION_TYPES.CHOOSE_WAREHOUSE,
    payload: wh,
  };
};

export const showEditDialog = (showEditDialog: boolean) => {
  return {
    type: ACTION_TYPES.SHOW_EDIT_DIALOG,
    payload: showEditDialog,
  };
};
