import { IOrganization } from 'app/shared/model/master/organization/organization.model';

export interface IInvoiceHsBase {
  id?: number;
  status?: string;
  designation?: string;
  organizationId?: number;
  invoiceHsBaseTransactionId?: number;
  organizationName?: string;
  totalValueNoTaxWithExpenses?: number;
  totalValueWithTaxNoExpenses?: number;
  totalValueNoTaxNoExpenses?: number;
  manipulativeCosts?: number;
  m3?: number;
}

export const defaultValue: Readonly<IInvoiceHsBase> = {};

export interface IBaseSearch {
  organizationId?: number;
  organization?: IOrganization;
  designation?: '';
}
