import {translate} from "react-jhipster";
import React from "react";
import {HeaderElement} from '@msvsustavi/msv-ui-react/dist';
export interface IHeaderElement{
  titleElement:JSX.Element;
  setCurrentTitleElement: (currentTitle:JSX.Element)=>void;
};

const defEl = <HeaderElement title={translate('global.title')} subtitle={translate('global.subtitle')}/>;

export const DEFAULT_HEADER_ELEMENT:IHeaderElement = {
  titleElement: defEl,
  setCurrentTitleElement(currentTitle:JSX.Element){}
}

export const HeaderElementContext = React.createContext<IHeaderElement>(DEFAULT_HEADER_ELEMENT);

export const useHeaderTitle = ():IHeaderElement => {
  const [titleElement, setTitleElement] = React.useState(defEl);

  const setCurrentTitleElement = React.useCallback((currentTitle:JSX.Element):void=>{
    setTitleElement(currentTitle);
  },[]);

  return {
    titleElement,
    setCurrentTitleElement,
  }
}
