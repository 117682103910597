import React, {useEffect, useRef, useState} from "react";
import {Formik} from "formik";
import {
  Grid,
  Col,
  Row,
  InputComponentText,
  HeadlineH0,
  InputComponentNumber,
  ActionButton,
  STANDARD_SAVE_SUBMIT_PROPS,
  InputComponentSelectAsync, LIST_ITEMS_PER_PAGE, InputComponentDate
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {
  APP_DECIMAL_SEPARATOR,
  APP_LOCAL_DATE_FORMAT,
  APP_LOCAL_DATE_FORMAT_INPUT,
  APP_THOUSAND_SEPARATOR
} from "app/config/constants";
import {useDispatch, useSelector} from "react-redux";
import {lookupVariations} from "app/shared/lookups/variation/variation.lookup";
import {
  chooseOrganization, chooseVariation, chooseWarehouse, showEditDialog, updateConsumption
} from "app/shared/reducers/consumption/edit/consumption.edit";
import {IRootState} from "app/shared/reducers";
import {lookupOrganizations, lookupOrganizationsSelection} from "app/shared/lookups/organization/organization.lookup";
import {lookupWarehouses} from "app/shared/lookups/warehouse/warehouse.lookup";
import moment from "moment";
import {InputComponentAutocomplete} from "@msvsustavi/msv-ui-react/dist";



const ConsumptionEditDialogComponent = () => {

  const dispatch = useDispatch();
  const consumptionEdit = useSelector(({consumption}:IRootState)=>consumption.edit.consumption);
  const organization = useSelector(({consumption}:IRootState)=>consumption.edit.organization);
  const warehouse = useSelector(({consumption}:IRootState)=>consumption.edit.warehouse);
  const variation = useSelector(({consumption}:IRootState)=>consumption.edit.variation);
  const {account} = useSelector(({authentication}: IRootState) => authentication);


  return (
    <Formik
      initialValues={
         consumptionEdit
      }
      enableReinitialize={true}
      onSubmit={(consumption, actions) => {
          dispatch(updateConsumption({
            ...consumption,
            variationId : variation === null ? consumptionEdit.variation.id  : variation.id,
            organizationId : organization === null ? consumptionEdit.organization.id  : organization.id,
            warehouseId : warehouse === null ? consumptionEdit.warehouse.id  : warehouse.id,
            consumptionDate : moment(consumption.consumptionDate).set({"hour": 12, "minute": 0})
            }
          ));
          dispatch(showEditDialog(false));
      }}
    >
      {
        formik => (
          <form onSubmit={formik.handleSubmit}>
            <Grid>
              <HeadlineH0 headline={translate("denaliApp.consumption.insert.title")}/>
              <Row>
                <Col>
                  <InputComponentAutocomplete
                    id={"organization"}
                    name={"organization"}
                    value={consumptionEdit.organization}
                    required={true}
                    showBackground={true}
                    label={translate("denaliApp.consumption.organization")}
                    getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
                    loadOptions={(inputValue) => lookupOrganizationsSelection( 0, LIST_ITEMS_PER_PAGE, 'asc',account.id, inputValue != "" ? inputValue : null).then(res => res.data)}
                    onChangeOption={(option) => {
                      dispatch(chooseOrganization(option))
                    }
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentAutocomplete
                    id={"warehouse"}
                    name={"warehouse"}
                    required={true}
                    showBackground={true}
                    value={consumptionEdit.warehouse}
                    label={translate("denaliApp.consumption.warehouse")}
                    getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
                    loadOptions={(inputValue) => lookupWarehouses(`name.contains=${inputValue}&organizationId.equals=${organization === null ? consumptionEdit.organization.id : organization.id}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
                    onChangeOption={(option) =>
                      dispatch(chooseWarehouse(option))
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentDate
                    id={"consumptionDate"}
                    name={"consumptionDate"}
                    label={translate("denaliApp.consumption.consumptionDate")}
                    required={true}
                    disabled={false}
                    showBackground={true}
                    dateDisplayFormat={APP_LOCAL_DATE_FORMAT_INPUT}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                    <InputComponentAutocomplete
                      id={"variation"}
                      name={"variation"}
                      required={true}
                      showBackground={true}
                      value={consumptionEdit.variation}
                      label={translate("denaliApp.consumption.variation")}
                      getOptionLabel={option => option?.variationName?`${option.sku} - ${option.variationName} - (${option.uom})`:``}
                      loadOptions={(inputValue) => lookupVariations(`name.contains=${inputValue}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
                      onChangeOption={option => {
                        dispatch(chooseVariation(option));
                      }}
                    />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentNumber
                    id={"quantity"}
                    name={"quantity"}
                    label={translate("denaliApp.consumption.quantity")}
                    required={true}
                    showBackground={true}
                    decimalScale={2}
                    thousandSeparator={APP_THOUSAND_SEPARATOR}
                    decimalSeparator={APP_DECIMAL_SEPARATOR}
                  />
                </Col>
              </Row>
               <Row>
                 <Col>
                    <InputComponentText
                      id={"uqDesignation"}
                      name={"uqDesignation"}
                      label={translate("denaliApp.consumption.uqDesignation")}
                      required={false}
                      showBackground={false}
                    />
                  </Col>
                  <Col>
                    <InputComponentText
                      id={"document"}
                      name={"document"}
                      label={translate("denaliApp.consumption.document")}
                      required={false}
                      showBackground={false}
                    />
                  </Col>
              </Row>
              <Row>
                <Col>
                  <InputComponentText
                    id={"location"}
                    name={"location"}
                    label={translate("denaliApp.consumption.location")}
                    required={false}
                    showBackground={false}
                  />
                </Col>
              </Row>
              <Row>
                <Col classname={"d-flex justify-content-center"}>
                  <ActionButton
                    label={translate('entity.action.save')}
                    {...STANDARD_SAVE_SUBMIT_PROPS}
                    faIcon={true}
                    icon={["fas","arrow-down"]}

                  />
                </Col>
              </Row>
            </Grid>
          </form>
        )
      }
    </Formik>
  );
};

export default ConsumptionEditDialogComponent;
