import axios from 'axios';
import { ICrudGetAllAction } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IInvoiceHsSelected, defaultValue } from 'app/shared/model/invoiceHsBase/transaction/selected/invoice-hs-selected.model';

export const ACTION_TYPES = {
  FETCH_INVOICEHSSELECTED_LIST: 'invoiceHsSelected/FETCH_INVOICEHSSELECTED_LIST',
  RESET: 'invoiceHsSelected/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IInvoiceHsSelected>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type InvoiceHsSelectedState = Readonly<typeof initialState>;

// Reducer

export default (state: InvoiceHsSelectedState = initialState, action): InvoiceHsSelectedState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INVOICEHSSELECTED_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_INVOICEHSSELECTED_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVOICEHSSELECTED_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/invoice-hs-selecteds';

export const getInvoicesForTransaction: ICrudGetAllAction<IInvoiceHsSelected> = id => {
  const requestUrl = `${apiUrl}/transaction/${id}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICEHSSELECTED_LIST,
    payload: axios.get<IInvoiceHsSelected>(`${requestUrl}`),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
