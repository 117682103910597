export interface IPOPRATNICE {
  id?: number;
  datumPopratnice?: string;
  brojDokumenta?: string;
  stranica?: number;
  organizacija?: string;
  partner?: string;
  upravaSuma?: string;
  sumarija?: string;
  indVM?: boolean;
}

export interface IPopratniceSearch {
  datumPopratnice?: string;
  brojDokumenta?: string;
  stranica?: string;
  organizacija?: string;
  partner?: string;
  upravaSuma?: string;
  sumarija?: string;
  indVM?: boolean;
}

export const defaultValue: Readonly<IPOPRATNICE> = {
  indVM: false,
};
