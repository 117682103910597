import { IConsumption, IConsumptionSearch } from 'app/shared/model/consumption/consumption.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { ICrudDeleteAction, ICrudGetAllAction, ICrudSearchAction } from 'react-jhipster';
import axios from 'axios';
import { IConsumptionGrouped, IConsumptionGroupedSearch } from 'app/shared/model/consumption/consumption-grouped.model';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';
import { IVariation } from 'app/shared/model/master/variation/variation.model';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export const ACTION_TYPES = {
  FETCH_CONSUMPTIONS: 'consumption/view/FETCH_CONSUMPTIONS',
  SEARCH_CONSUMPTIONS: 'consumption/view/SEARCH_CONSUMPTIONS',
  RESET: 'consumption/view/RESET',

  FETCH_CONSUMPTION_LIST: 'consumption/view/FETCH_CONSUMPTION_LIST',
  DELETE_CONSUMPTION: 'consumption/view/DELETE_CONSUMPTION',

  SET_SEARCH: 'consumption/view/SET_SEARCH',
  CHOOSE_ORGANIZATION: 'consumption/view/CHOOSE_ORGANIZATION',
  CHOOSE_WAREHOUSE: 'consumption/view/CHOOSE_WAREHOUSE',
  CHOOSE_VARIATION: 'consumption/view/CHOOSE_VARIATION',
  CLEAR_SEARCH: 'consumption/view/CLEAR_SEARCH',
};

const defaultConsumptionSearch: IConsumptionSearch = {};

const initialState = {
  loading: false,
  errorMessage: null,
  consumptions: [] as ReadonlyArray<IConsumption>,
  totalItems: 0,
  updateSuccess: false,
  updating: false,

  consumptionSearchData: { ...defaultConsumptionSearch },

  organization: null as Readonly<IOrganization>,
  warehouse: null as Readonly<IWarehouse>,
  variation: null as Readonly<IVariation>
};

export type ConsumptionViewState = Readonly<typeof initialState>;

export default (state: ConsumptionViewState = initialState, action): ConsumptionViewState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CONSUMPTIONS):
    case REQUEST(ACTION_TYPES.FETCH_CONSUMPTION_LIST):
    case REQUEST(ACTION_TYPES.SEARCH_CONSUMPTIONS):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.DELETE_CONSUMPTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_CONSUMPTIONS):
    case FAILURE(ACTION_TYPES.SEARCH_CONSUMPTIONS):
    case FAILURE(ACTION_TYPES.FETCH_CONSUMPTION_LIST):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case FAILURE(ACTION_TYPES.DELETE_CONSUMPTION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONSUMPTIONS):
    case SUCCESS(ACTION_TYPES.SEARCH_CONSUMPTIONS):
      return {
        ...state,
        loading: false,
        errorMessage: null,
        consumptions: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONSUMPTION_LIST):
      return {
        ...state,
        loading: false,
        consumptions: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.DELETE_CONSUMPTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
      };
    case ACTION_TYPES.SET_SEARCH:
      return {
        ...state,
        consumptionSearchData: action.payload,
      };
    case ACTION_TYPES.CHOOSE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case ACTION_TYPES.CHOOSE_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    case ACTION_TYPES.CHOOSE_VARIATION:
      return {
        ...state,
        variation: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };

    case ACTION_TYPES.CLEAR_SEARCH:
      return {
        ...state,
        consumptionSearchData: defaultConsumptionSearch,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/consumptions';

export const loadConsumptions: ICrudGetAllAction<IConsumption[]> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTIONS,
    payload: axios.get<IConsumption[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const searchConsumptions = (
  query: string | undefined,
  page: number | undefined,
  size: number | undefined,
  sort: string | undefined
) => {
  const requestUrl = `${apiUrl}${sort ? `?${query}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTION_LIST,
    payload: axios.get<IConsumptionGrouped[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const fetchConsumptionsAllSelection = (selection, userId, page?: number, sort?: string) => {
  const requestUrl = `${apiUrl}/all/${userId}${sort ? `?page=${page}&size=${ITEMS_PER_PAGE}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTION_LIST,
    payload: axios.put<any>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, selection),
  };
};

export const getConsumptions: ICrudGetAllAction<IConsumption> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTION_LIST,
    payload: axios.get<IConsumption>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const deleteConsumption: ICrudDeleteAction<IConsumption> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CONSUMPTION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getConsumptions());
  return result;
};

export const setSearchData = (consumptionSearchData: IConsumptionSearch) => {
  return {
    type: ACTION_TYPES.SET_SEARCH,
    payload: consumptionSearchData,
  };
};

export const chooseOrganization = (organization: IOrganization) => {
  return {
    type: ACTION_TYPES.CHOOSE_ORGANIZATION,
    payload: organization,
  };
};

export const chooseWarehouse = (wh: IWarehouse) => {
  return {
    type: ACTION_TYPES.CHOOSE_WAREHOUSE,
    payload: wh,
  };
};

export const chooseVariation = (variation: IVariation) => {
  return {
    type: ACTION_TYPES.CHOOSE_VARIATION,
    payload: variation,
  };
};

export const clearSearchData = () => {
  return {
    type: ACTION_TYPES.CLEAR_SEARCH,
  };
};

export const downloadExcelConsumptions = selection => async dispatch => {
  return axios({
    url: `api/consumptions/all/download`,
    method: 'PUT',
    responseType: 'blob', // important
    data: selection,
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'potrosnja.xlsx');
    document.body.appendChild(link);
    link.click();
  });
};
