import React from "react";
import {Modal, ModalBody} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import ConsumptionEditDialogComponent from "app/modules/consumption/edit/consumptionEdit.dialog.component";
import {showEditDialog} from "app/shared/reducers/consumption/edit/consumption.edit";

const ConsumptionEditDialog = () => {

  const dispatch = useDispatch();
  const showDialog = useSelector(({consumption}:IRootState)=>consumption.edit.showEditDialog);
  return (
    <Modal isOpen={showDialog}
           toggle={() => dispatch(showEditDialog(!showDialog))}
           modalTransition={{timeout: 300}}
           backdropTransition={{timeout: 600}}
           centered={true}
           keyboard={true}
           fade={true}
           size={'lg'}
    >
      <ModalBody>
        <ConsumptionEditDialogComponent/>
      </ModalBody>
    </Modal>
  );
};

export default ConsumptionEditDialog;
