import { Moment } from 'moment';

export interface IInvoiceHsBaseInvoice {
  id?: number;
  invoiceDesignation?: string;
  generatedInvoiceDesignation?: string;
  documentNumber?: string;
  invoiceDate?: string;
  partnerName?: string;
  invoiceHsBaseId?: number;
}

export const defaultValue: Readonly<IInvoiceHsBaseInvoice> = {};
