import React from 'react';
import {Link} from 'react-router-dom';
import {navigation} from "app/shared/util/navigation.constants";
import {Translate} from "react-jhipster";
import {IRootState} from "app/shared/reducers";
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";
import {connect} from "react-redux";
import {UserContext} from '@msvsustavi/msv-ui-react/dist';

export type INoLogin = StateProps;

const NoLogin = (props:INoLogin) => {

  const welcome = ()=>{
    return (
      <UserContext.Consumer>
        {
          user => {
            return <div>{user.name}, Welcome to Denali</div>
          }
        }
      </UserContext.Consumer>
    );
  };

  const signIn = ()=> <Link to={navigation.login}><Translate contentKey={'global.menu.account.login'}>global.menu.account.login</Translate></Link>;
  return (
    <div>
      {props.isAuthenticated?welcome():signIn()}
    </div>
  );
}

const mapStateToProps = ({ authentication, applicationProfile, locale }: IRootState) => ({
  loading: authentication.loading,
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
  account: authentication.account
});


type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(NoLogin);
