import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {navigation} from "app/shared/util/navigation.constants";
import WarehouseView from "app/modules/master/warehouse/warehouse.view";
import WarehouseEdit from "app/modules/master/warehouse/warehouse.edit";


const Routes = (match) => (
    <Switch>
      <Route path={navigation.master.warehouse.edit} exact component={WarehouseEdit}/>
      <Route path={navigation.master.warehouse.view} component={WarehouseView}/>
    </Switch>
);

export default Routes;
