import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import Popup from "reactjs-popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translate, translate} from "react-jhipster";
import {
  chooseOrganization,
  chooseWarehouse,
  setSearchData
} from "app/shared/reducers/input/view/input.view";
import {InputComponentAutocomplete, InputComponentText, LIST_ITEMS_PER_PAGE} from "@msvsustavi/msv-ui-react";
import { lookupOrganizationsSelection} from "app/shared/lookups/organization/organization.lookup";
import {lookupWarehouses} from "app/shared/lookups/warehouse/warehouse.lookup";

/**
 *
 */
const OrganizationSelection = (props) => {
  const dispatch = useDispatch();

  const {inputSearchData,organization,warehouse} = useSelector(({input}: IRootState) => input.view);
  const {account} = useSelector(({authentication}: IRootState) => authentication);
  const [values, setValues] = useState(inputSearchData);
  useEffect(()=>{setValues(inputSearchData)},[inputSearchData]);


  return (
    <Popup
      trigger={open => <div className={"mx-4 d-flex align-items-center"}>
        <div className={"me-1"}><Translate contentKey={"denaliApp.input.organization"}/></div>
        <FontAwesomeIcon icon={["far", "angle-down"]}/>
      </div>}
      closeOnDocumentClick
      position={['bottom right', 'bottom left']}
      arrow
      onClose={(event => {
        dispatch(setSearchData(values));
      })}
    >
      <div className={"d-flex flex-column popup p-3  rounded shadow"}>
        <div className={"d-flex flex-column"}>
          <div className={"px-2 py-1 text-uppercase fs-5 fw-bold selection-header"}><Translate contentKey={"denaliApp.input.organization"}/></div>
          <div className={"container"}>
            <div className={"row g-0"}>
              <div className={"col-24"}>
                <InputComponentAutocomplete
                  id={"organizationId"}
                  name={"organizationId"}
                  required={true}
                  showBackground={true}
                  value = {organization}
                  wrapInForm={false}
                  label={translate("denaliApp.input.organization")}
                  getOptionLabel={option => option?.name?`${option.designation} - ${option.name}`:``}
                  loadOptions={(inputValue) => lookupOrganizationsSelection( 0, LIST_ITEMS_PER_PAGE, 'asc',account.id, inputValue != "" ? inputValue : null).then(res => res.data)}
                  onChangeOption={(option) =>{
                    setValues({
                      ...values,
                      organization: option
                    });
                    dispatch(chooseOrganization(option))
                  }
                  }
                />
              </div>
              <div className={"col-24"}>
                <InputComponentAutocomplete
                  id={"warehouseId"}
                  name={"warehouseId"}
                  required={true}
                  showBackground={true}
                  value = {warehouse}
                  label={translate("denaliApp.input.warehouse")}
                  wrapInForm={false}
                  getOptionLabel={option => option?.name?`${option.designation} - ${option.name}` :``}
                  loadOptions={(inputValue) => lookupWarehouses(`name.contains=${inputValue}&organizationId.equals=${organization.id}`, 0, LIST_ITEMS_PER_PAGE, 'asc').then(res => res.data)}
                  onChangeOption={(option) =>{
                    setValues({
                      ...values,
                      warehouse: option
                    });
                    dispatch(chooseWarehouse(option))
                  }
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default OrganizationSelection;
