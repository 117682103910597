export const navigation = {
  root: {
    home: '/',
  },
  logout: '/logout',
  login: '/login',
  admin: {
    home: '/admin',
    userManagement: '/admin/user-management',
    userManagementEdit: '/admin/user-management/edit/:login',
    userManagementDetails: '/admin/user-management/:login',
    userManagementNew: '/admin/user-management/new',
  },
  account: {
    home: '/account',
  },
  master: {
    home: '/master',
    organization: {
      view: '/master/organization',
      edit: '/master/organization/:id',
      new: '/master/organization/new',
    },
    variation: {
      view: '/master/variation',
      edit: '/master/variation/:id',
      new: '/master/variation/new',
    },
    warehouse: {
      view: '/master/warehouse',
      edit: '/master/warehouse/:id',
      new: '/master/warehouse/new',
    },
    userOrganizationWarehouse: {
      view: '/master/userOrganizationWarehouse',
      edit: '/master/userOrganizationWarehouse/:id',
      new: '/master/userOrganizationWarehouse/new',
    },
  },
  consumption: {
    home: '/consumption',
    view: {
      home: '/consumption/view',
      sumQuantity: '/consumption/sumQuantity',
    },
    tabs: {
      consumptionTable: '/consumption/view',
      sumQuantity: '/consumption/sumQuantity',
      pieQuantity: '/consumption/pieQuantity',
      lineQuantity: '/consumption/lineQuantity',
      barQuantity: '/consumption/barQuantity',
      consumptionTransactions: '/consumption/consumptionTransactions/view',
      consumptionGrouped: '/consumption/consumptionGrouped/view',
      input: '/consumption/input/view',
    },
    insert: {
      home: '/consumption/insert',
    },
  },
  input: {
    insert: {
      home: '/input/insert',
    },
    view: {
      home: '/input/view',
    },
  },
  invoiceHsBase: {
    dashboard: {
      view: '/invoiceHsBase/view',
    },
    card: {
      view: '/invoiceHsBase/card/:id',
      tabs: {
        items: '/invoiceHsBase/card/:id/items',
        invoices: '/invoiceHsBase/card/:id/invoices',
      },
    },
    transaction: {
      insert: '/invoiceHsBase/transaction/insert',
      preview: '/invoiceHsBase/transaction/preview/:id',
    },
  },
};
