export interface IInvoiceHsBaseTransactionQuantityPartner {
  id?: number;
  priceLength?: string;
  priceRange?: string;
  woodTypeName?: string;
  className?: string;
  quantity?: number;
  invoiceHsBaseTransactionId?: number;
  organizationId?: number;
  organizationName?: string;
}

export const defaultValue: Readonly<IInvoiceHsBaseTransactionQuantityPartner> = {};
