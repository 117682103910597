import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IRootState} from 'app/shared/reducers';
import {
  ACTION_TYPE,
  BUTTON_SHAPE,
  FormTable,
  isComplexQuery,
  LAYOUT_TYPE,
  parseQuery
} from '@msvsustavi/msv-ui-react/dist';
import {translate} from "react-jhipster";
import {navigation} from "app/shared/util/navigation.constants";

import {VariationDataTableColumns} from "app/shared/model/master/variation/variation.column.model";
import {getSearchVariations, getVariations} from "app/shared/reducers/master/variation/variation.reducer";

export const VariationView = (props) => {

  const dispatch = useDispatch();
  const variations = useSelector(({variation}: IRootState) => variation.entities);
  const count = useSelector(({variation}: IRootState) => variation.totalItems);
  const loading = useSelector(({variation}: IRootState) => variation.loading);

  return <FormTable
    loadEntities={
      (activepage, itemsPerPage, sort) => {
        return dispatch(getVariations(activepage - 1, itemsPerPage, sort));
      }
    }
    filterEntities={
      (query, activepage, itemsPerPage, sort) => {
        return dispatch(getSearchVariations(isComplexQuery(query) ? parseQuery(query) : `variationName.contains=${query}`, activepage - 1, itemsPerPage, sort));
      }
    }
    columns={VariationDataTableColumns}
    items={variations}
    count={count}
    exportEntities={() => {
    }}
    actions={[
      {
        label: translate('denaliApp.variation.home.createLabel'),
        showLabel: true,
        icon: 'icon-add',
        name: 'new-country',
        type: ACTION_TYPE.LINK,
        layout: LAYOUT_TYPE.TOP_ICON_BOTTOM_TEXT,
        shape: BUTTON_SHAPE.ROUNDED,
        link: navigation.master.variation.new
      }
    ]}
    loading={loading}
  />;

}


export default VariationView;
