import { defaultValue } from 'app/shared/model/input/input.model';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';
import { IPOPRATNICE } from 'app/shared/model/nabavawrapper/popratnice.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import { ICrudPutAction } from 'react-jhipster';
import { IInput } from 'app/shared/model/input/input.model';
import axios from 'axios';
import { cleanEntity } from 'app/shared/util/entity-utils';

export const ACTION_TYPES = {
  CREATE_INPUT: 'input/insert/CREATE_INPUT',
  CHOOSE_ORGANIZATION: 'input/insert/CHOOSE_ORGANIZATION',
  CHOOSE_WAREHOUSE: 'input/insert/CHOOSE_WAREHOUSE',

  CHOOSE_POPRATNICA_FOR_INSERT: 'input/insert/CHOOSE_POPRATNICA_FOR_INSERT',
  UNCHOOSE_POPRATNICA_FOR_INSERT: 'input/insert/UNCHOOSE_POPRATNICA_FOR_INSERT',
  SET_SHOW_POPRATNICA_DIALOG: 'input/insert/SET_SHOW_POPRATNICA_DIALOG',
  SET_SHOW_MANUAL_ENTRY: 'input/insert/SET_SHOW_MANUAL_ENTRY',
  APPLY_POPRATNICA: 'input/insert/APPLY_POPRATNICA',

  RESET: 'input/insert/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  input: defaultValue,
  updating: false,
  updateSuccess: false,

  organization: null as Readonly<IOrganization>,
  warehouse: null as Readonly<IWarehouse>,

  popratnicaForInsert: null as Readonly<IPOPRATNICE>,
  showPopratnicaDialog: false,

  showManualEntry: false,
};

export type InputInsertState = Readonly<typeof initialState>;

export default (state: InputInsertState = initialState, action): InputInsertState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_INPUT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_INPUT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.CREATE_INPUT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        input: action.payload.data,
      };
    case ACTION_TYPES.CHOOSE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case ACTION_TYPES.CHOOSE_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    case ACTION_TYPES.CHOOSE_POPRATNICA_FOR_INSERT:
      return {
        ...state,
        popratnicaForInsert: action.payload,
      };
    case ACTION_TYPES.UNCHOOSE_POPRATNICA_FOR_INSERT:
      return {
        ...state,
        popratnicaForInsert: null as Readonly<IPOPRATNICE>,
      };
    case ACTION_TYPES.SET_SHOW_POPRATNICA_DIALOG:
      return {
        ...state,
        showPopratnicaDialog: action.payload,
      };
    case ACTION_TYPES.SET_SHOW_MANUAL_ENTRY:
      return {
        ...state,
        showManualEntry: action.payload,
      };
    case ACTION_TYPES.APPLY_POPRATNICA:
      return {
        ...state,
        input: {
          ...state.input,
          document: state.popratnicaForInsert.brojDokumenta,
          page: state.popratnicaForInsert.stranica.toString(),
          forestAdministration: state.popratnicaForInsert.upravaSuma,
          forestAdministrationDivision: state.popratnicaForInsert.sumarija,
          partner: state.popratnicaForInsert.partner,
        },
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/inputs';

export const createInput: ICrudPutAction<IInput> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INPUT,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const chooseOrganization = (organization: IOrganization) => {
  return {
    type: ACTION_TYPES.CHOOSE_ORGANIZATION,
    payload: organization,
  };
};

export const chooseWarehouse = (wh: IWarehouse) => {
  return {
    type: ACTION_TYPES.CHOOSE_WAREHOUSE,
    payload: wh,
  };
};

export const choosePopratnicaForInsert = popratnica => {
  return {
    type: ACTION_TYPES.CHOOSE_POPRATNICA_FOR_INSERT,
    payload: popratnica,
  };
};

export const unchoosePopratnicaForInsert = () => {
  return {
    type: ACTION_TYPES.UNCHOOSE_POPRATNICA_FOR_INSERT,
  };
};

export const applyPopratnica = () => {
  return {
    type: ACTION_TYPES.APPLY_POPRATNICA,
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const setShowPopratnicaDialog = (showPopratnicaDialog: boolean) => {
  return {
    type: ACTION_TYPES.SET_SHOW_POPRATNICA_DIALOG,
    payload: showPopratnicaDialog,
  };
};

export const setShowManualEntry = (showManualEntry: boolean) => {
  return {
    type: ACTION_TYPES.SET_SHOW_MANUAL_ENTRY,
    payload: showManualEntry,
  };
};
