import axios from 'axios';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';

export const lookupOrganizations = (
  query: string | undefined,
  page: number | undefined,
  size: number | undefined,
  sort: string | undefined
) => {
  const apiUrl = 'api/organizations';
  const requestUrl = `${apiUrl}/${sort ? `?${query}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return axios.get<IOrganization[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`);
};

export const lookupOrganizationsSelection = (
  page: number | undefined,
  size: number | undefined,
  sort: string | undefined,
  userId,
  selection
) => {
  const apiUrl = 'api/organizations/lookup';
  const requestUrl = `${apiUrl}/${userId}/${selection}/${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return axios.get<IOrganization[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`);
};
