import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IInvoiceHsBaseItem, defaultValue } from 'app/shared/model/invoiceHsBase/base/item/invoice-hs-base-item.model';

export const ACTION_TYPES = {
  FETCH_INVOICEHSBASEITEM_LIST_FOR_BASE: 'invoiceHsBaseItem/FETCH_INVOICEHSBASEITEM_LIST_FOR_BASE',
  RESET: 'invoiceHsBaseItem/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IInvoiceHsBaseItem>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type InvoiceHsBaseItemState = Readonly<typeof initialState>;

// Reducer

export default (state: InvoiceHsBaseItemState = initialState, action): InvoiceHsBaseItemState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INVOICEHSBASEITEM_LIST_FOR_BASE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_INVOICEHSBASEITEM_LIST_FOR_BASE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVOICEHSBASEITEM_LIST_FOR_BASE):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/invoice-hs-base-items';

export const getItemsByHsBase: ICrudGetAllAction<IInvoiceHsBaseItem> = id => {
  const requestUrl = `${apiUrl}/${id}/items`;
  return {
    type: ACTION_TYPES.FETCH_INVOICEHSBASEITEM_LIST_FOR_BASE,
    payload: axios.get<IInvoiceHsBaseItem>(`${requestUrl}`),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const downloadAttachment = (baseId,fileName) => {
  let fileNameForDownload = fileName + ".xlsx";
  return axios({
    url: `api/invoice-hs-base-items/${baseId}/download`,
    method: 'GET',
    responseType: 'blob', // important
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download',fileNameForDownload);
    document.body.appendChild(link);
    link.click();
  });
};
