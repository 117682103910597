import React from "react";
import classNames from 'classnames/bind';import {
  consumptionTransactionStatus,
  EConsumptionTransactionStatus
} from "app/shared/model/enumeration/e-transaction-status.model";
import {translate} from "react-jhipster";

export interface IConsumptionTransactionStatusProps {
  status: EConsumptionTransactionStatus;
}

export const ConsumptionTransactionStatus = (props: IConsumptionTransactionStatusProps) => {
  const cn = classNames(
    'rounded-circle',
    'square-4',
    {'background-vivid-purple': props.status === EConsumptionTransactionStatus.TRANSFERED},
    {'background-mud-green': props.status === EConsumptionTransactionStatus.INITIALIZED},
    {'background-ugly-yellow': props.status === EConsumptionTransactionStatus.PREPARED},
    {'background-dark-lime-green': props.status === EConsumptionTransactionStatus.VERIFIED},
    {'background-red': props.status === EConsumptionTransactionStatus.REJECTED},
  );

  return (
      <>
        <div className={"d-flex justify-content-center align-items-center"}>
          <div className={cn}/>
        </div>
      </>
  )
}

export const calculateConsumptionTransactionStatus = (status: EConsumptionTransactionStatus): string => {
  switch (status) {
    case EConsumptionTransactionStatus.TRANSFERED:
      return 'vivid-purple';
    case EConsumptionTransactionStatus.INITIALIZED:
      return 'mud-green';
    case EConsumptionTransactionStatus.PREPARED:
      return 'ugly-yellow';
    case EConsumptionTransactionStatus.VERIFIED:
      return 'dark-lime-green';
    case EConsumptionTransactionStatus.REJECTED:
      return 'red';
    default:
      return 'warm-grey';
  }
};

export const ConsumptionTransactionStatusLegend = () => {

  const cnSmall = (status) =>  classNames(
    'rounded-circle',
    'square-2',
    {'background-vivid-purple': status === EConsumptionTransactionStatus.TRANSFERED},
    {'background-mud-green': status === EConsumptionTransactionStatus.INITIALIZED},
    {'background-ugly-yellow': status === EConsumptionTransactionStatus.PREPARED},
    {'background-dark-lime-green': status === EConsumptionTransactionStatus.VERIFIED},
    {'background-red': status === EConsumptionTransactionStatus.REJECTED},
  );

  return (
    <div className={"d-flex"}>
      <div className={"d-flex justify-content-center align-items-center px-2"}>{translate(consumptionTransactionStatus(EConsumptionTransactionStatus.INITIALIZED))}</div>
      <div className={"d-flex justify-content-center align-items-center"} style={{"paddingRight":10}}>
        <div className={cnSmall(EConsumptionTransactionStatus.INITIALIZED)}/>
      </div>
      <div className={"d-flex justify-content-center align-items-center px-2"}>{translate(consumptionTransactionStatus(EConsumptionTransactionStatus.PREPARED))}</div>
      <div className={"d-flex justify-content-center align-items-center"} style={{"paddingRight":10}}>
        <div className={cnSmall(EConsumptionTransactionStatus.PREPARED)}/>
      </div>
      <div className={"d-flex justify-content-center align-items-center px-2"}>{translate(consumptionTransactionStatus(EConsumptionTransactionStatus.VERIFIED))}</div>
      <div className={"d-flex justify-content-center align-items-center"} style={{"paddingRight":10}}>
        <div className={cnSmall(EConsumptionTransactionStatus.VERIFIED)}/>
      </div>
      <div className={"d-flex justify-content-center align-items-center px-2"}>{translate(consumptionTransactionStatus(EConsumptionTransactionStatus.TRANSFERED))}</div>
      <div className={"d-flex justify-content-center align-items-center"} style={{"paddingRight":10}}>
        <div className={cnSmall(EConsumptionTransactionStatus.TRANSFERED)}/>
      </div>
      <div className={"d-flex justify-content-center align-items-center px-2"}>{translate(consumptionTransactionStatus(EConsumptionTransactionStatus.REJECTED))}</div>
      <div className={"d-flex justify-content-center align-items-center"} style={{"paddingRight":10}}>
        <div className={cnSmall(EConsumptionTransactionStatus.REJECTED)}/>
      </div>
    </div>
  )
}


