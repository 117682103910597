import React from "react";

import "./consumptionGrouped.selection.scss";

import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import {ITEMS_PER_PAGE} from "app/shared/util/pagination.constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {clearSearchData} from "app/shared/reducers/consumptionGrouped/consumption-grouped.reducer";
import BasicDataConsumptionGroupedSelection from "app/modules/consumptionGrouped/view/selection/basicData.selection";
import StatusTransactionSelection from "app/modules/consumptionGrouped/view/selection/status.selection";


const ConsumptionGroupedSelection = () => {

  const dispatch = useDispatch();
  const {entities,totalItems} = useSelector(({consumptionGrouped}: IRootState) => consumptionGrouped);

  const separator = () => (
    <div className={"border-left-1 border-left-color-white"}>&nbsp;</div>
  );

  return (
    <div
      className={"w-100 input_selection rounded-1 d-flex flex-row justify-content-begin align-items-center color-brown-grey-two px-2 fs-5 fw-bold tooltipBoundary"}>
      <BasicDataConsumptionGroupedSelection/>
      {separator()}
      <StatusTransactionSelection/>
      {separator()}
      <div className={"flex-grow-1 d-flex justify-content-end"}>
        {separator()}
        <div className={"px-2"}>{`${entities && entities?.length>ITEMS_PER_PAGE?ITEMS_PER_PAGE:entities?.length}/${totalItems}`}</div>
        {separator()}
        <FontAwesomeIcon className={"m-2 color-theme-secundary"} icon={["far", "times"]} onClick={()=>{
          dispatch(clearSearchData())}}/>
      </div>
    </div>
  );
};

export default ConsumptionGroupedSelection;
