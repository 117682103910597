import React from "react";
import {Switch} from "react-router-dom";
import PrivateRoute from "app/shared/auth/private-route";
import ErrorBoundaryRoute from "app/shared/error/error-boundary-route";
import {navigation} from "app/shared/util/navigation.constants";
import PageNotFound from "app/shared/error/page-not-found";
import NoLogin from "app/modules/login/no-login";
import {AUTHORITIES} from "app/config/constants";
import Dashboard from "app/modules/dashboard/dashboard";
import Master from "app/modules/master";
import Consumptions from "app/modules/consumption";
import ConsumptionInsert from "app/modules/consumption/insert/consumption.insert.card";
import InputInsert from "app/modules/input/insert/input.insert.card";
import InvoiceHsBaseTransactionInsertCard
  from "app/modules/invoiceHsBase/transaction/insert/invoiceHsBaseTransaction.insert.card";
import InvoiceHsBaseTransactionPreviewCard
  from "app/modules/invoiceHsBase/transaction/preview/invoiceHsBaseTransaction.preview.card";
import BasesView from "app/modules/invoiceHsBase/dashboard/invoiceHsBase.view";
import InvoiceHsBaseCard from "app/modules/invoiceHsBase/card/invoiceHsBase.card";

/**
 *  AppContentRoutes
 *
 */

const AppContentRoutes = (props) => {
  return (
    <Switch>
      <PrivateRoute path={navigation.root.home} exact component={Dashboard} />
      <PrivateRoute path={navigation.consumption.insert.home} component={ConsumptionInsert} hasAnyAuthorities={[AUTHORITIES.CONSUMPTION]}/>
      <PrivateRoute path={navigation.input.insert.home} component={InputInsert} hasAnyAuthorities={[AUTHORITIES.INPUT]}/>
      <PrivateRoute path={navigation.consumption.home} component={Consumptions} hasAnyAuthorities={[AUTHORITIES.CONSUMPTION]}/>
      <PrivateRoute path={navigation.invoiceHsBase.card.view} component={InvoiceHsBaseCard} hasAnyAuthorities={[AUTHORITIES.BASES]}/>
      <PrivateRoute path={navigation.invoiceHsBase.dashboard.view} component={BasesView} hasAnyAuthorities={[AUTHORITIES.BASES]}/>
      <PrivateRoute path={navigation.master.home} component={Master} hasAnyAuthorities={[AUTHORITIES.ADMIN]}/>
      <PrivateRoute path={navigation.invoiceHsBase.transaction.preview} component={InvoiceHsBaseTransactionPreviewCard} hasAnyAuthorities={[AUTHORITIES.BASES]}/>
      <PrivateRoute path={navigation.invoiceHsBase.transaction.insert} component={InvoiceHsBaseTransactionInsertCard} hasAnyAuthorities={[AUTHORITIES.BASES]}/>
      <ErrorBoundaryRoute path={navigation.root.home} exact component={NoLogin}/>
      <ErrorBoundaryRoute component={PageNotFound}/>
    </Switch>

  );
};

export default AppContentRoutes;
