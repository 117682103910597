import { combineReducers } from 'redux';
import view, { ConsumptionViewState } from 'app/shared/reducers/consumption/view/consumption.view';
import insert, { ConsumptionInsertState } from 'app/shared/reducers/consumption/insert/consumption.insert';
import edit, { ConsumptionEditState } from 'app/shared/reducers/consumption/edit/consumption.edit';

export interface ConsumptionState {
  readonly view: ConsumptionViewState;
  readonly insert: ConsumptionInsertState;
  readonly edit: ConsumptionEditState;
}

export default combineReducers<ConsumptionState>({
  view,
  insert,
  edit,
});
