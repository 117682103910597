import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IInvoiceHsBase, defaultValue, IBaseSearch } from 'app/shared/model/invoiceHsBase/base/base/invoice-hs-base.model';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IInputSearch } from 'app/shared/model/input/input.model';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export const ACTION_TYPES = {
  FETCH_INVOICEHSBASE_LIST: 'invoiceHsBase/FETCH_INVOICEHSBASE_LIST',
  FETCH_INVOICEHSBASE: 'invoiceHsBase/FETCH_INVOICEHSBASE',
  RESET: 'invoiceHsBase/RESET',
  CREATE_INVOICEHSBASE_BY_TRANSACTION: 'invoiceHsBase/CREATE_INVOICEHSBASE_BY_TRANSACTION',
  CHOOSE_ORGANIZATION: 'invoiceHsBase/CHOOSE_ORGANIZATION',
  SET_SEARCH: 'invoiceHsBase/SET_SEARCH',
};

const defaultBaseSearch: IBaseSearch = {};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IInvoiceHsBase>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,

  organization: null as Readonly<IOrganization>,
  basesSearchData: { ...defaultBaseSearch },
};

export type InvoiceHsBaseState = Readonly<typeof initialState>;

// Reducer

export default (state: InvoiceHsBaseState = initialState, action): InvoiceHsBaseState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INVOICEHSBASE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_INVOICEHSBASE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_INVOICEHSBASE_BY_TRANSACTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_INVOICEHSBASE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_INVOICEHSBASE):
    case FAILURE(ACTION_TYPES.CREATE_INVOICEHSBASE_BY_TRANSACTION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVOICEHSBASE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_INVOICEHSBASE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_INVOICEHSBASE_BY_TRANSACTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        loading: false,
      };
    case ACTION_TYPES.CHOOSE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case ACTION_TYPES.SET_SEARCH:
      return {
        ...state,
        basesSearchData: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/invoice-hs-bases';

export const createInvoiceHsBaseByTransaction = id => async dispatch => {
  const requestUrl = `${apiUrl}/create_by_transaction/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_INVOICEHSBASE_BY_TRANSACTION,
    payload: axios.post(requestUrl),
  });
  return result;
};

export const getEntities: ICrudGetAllAction<IInvoiceHsBase> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICEHSBASE_LIST,
    payload: axios.get<IInvoiceHsBase>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IInvoiceHsBase> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICEHSBASE,
    payload: axios.get<IInvoiceHsBase>(requestUrl),
  };
};

export const fetchBasesAllSelection = (selection, page?: number, sort?: string) => {
  const requestUrl = `${apiUrl}/all${sort ? `?page=${page}&size=${ITEMS_PER_PAGE}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_INVOICEHSBASE_LIST,
    payload: axios.put<any>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`, selection),
  };
};

export const chooseOrganization = (organization: IOrganization) => {
  return {
    type: ACTION_TYPES.CHOOSE_ORGANIZATION,
    payload: organization,
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const setSearchData = (basesSearchData: IBaseSearch) => {
  return {
    type: ACTION_TYPES.SET_SEARCH,
    payload: basesSearchData,
  };
};
