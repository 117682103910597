import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';

export const useOrganization = () => {
  const { isRestricted, account } = useSelector(({ authentication }: IRootState) => authentication);
  const [organization, setOrganization] = useState(null as IOrganization);
  const [warehouseFirst, setFirstWarehouse] = useState(null as IWarehouse);

  useEffect(() => {
    if (isRestricted) {
      axios.get<IOrganization>(`api/users/${account.id}/organization`).then(res => {
        setOrganization(res.data);
        axios.get<IWarehouse>(`api/warehouses/${res.data.id}/first`).then(res => setFirstWarehouse(res.data));
      });
    }
  }, [isRestricted]);

  return {
    organization,
    isRestricted,
    warehouseFirst,
  };
};
