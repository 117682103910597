import axios from 'axios';
import { ICrudSearchAction, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IConsumptionTransaction, defaultValue } from 'app/shared/model/consumption/consumption-transaction.model';
import { IConsumption } from 'app/shared/model/consumption/consumption.model';
import { IOrganization } from 'app/shared/model/master/organization/organization.model';
import { IWarehouse } from 'app/shared/model/master/warehouse/warehouse.model';
import { EConsumptionTransactionStatus } from 'app/shared/model/enumeration/e-transaction-status.model';

export const ACTION_TYPES = {
  SEARCH_CONSUMPTIONTRANSACTIONS: 'consumptionTransaction/SEARCH_CONSUMPTIONTRANSACTIONS',
  FETCH_CONSUMPTIONTRANSACTION_LIST: 'consumptionTransaction/FETCH_CONSUMPTIONTRANSACTION_LIST',
  FETCH_CONSUMPTIONTRANSACTION: 'consumptionTransaction/FETCH_CONSUMPTIONTRANSACTION',
  CREATE_CONSUMPTIONTRANSACTION: 'consumptionTransaction/CREATE_CONSUMPTIONTRANSACTION',
  UPDATE_CONSUMPTIONTRANSACTION: 'consumptionTransaction/UPDATE_CONSUMPTIONTRANSACTION',
  DELETE_CONSUMPTIONTRANSACTION: 'consumptionTransaction/DELETE_CONSUMPTIONTRANSACTION',
  SHOW_DIALOG: 'consumptionTransaction/SHOW_DIALOG',
  RESET: 'consumptionTransaction/RESET',

  CHOOSE_ORGANIZATION: 'consumptionTransaction/CHOOSE_ORGANIZATION',
  CHOOSE_WAREHOUSE: 'consumptionTransaction/CHOOSE_WAREHOUSE',

  FIND_LAST_FOR_ORG_AND_WH: 'consumptionTransaction/FIND_LAST_FOR_ORG_AND_WH',
  SHOW_DIALOG_EDIT: 'consumptionTransaction/SHOW_DIALOG_EDIT',

  SELECT_STATUS_FOR_CHANGE: 'consumptionTransaction/SELECT_STATUS_FOR_CHANGE',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IConsumptionTransaction>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,

  show_dialog: false,
  show_dialog_edit: false,

  organization: null as Readonly<IOrganization>,
  warehouse: null as Readonly<IWarehouse>,

  lastPeriodEnd: null as Readonly<IConsumptionTransaction>,

  status: '',
};

export type ConsumptionTransactionState = Readonly<typeof initialState>;

// Reducer

export default (state: ConsumptionTransactionState = initialState, action): ConsumptionTransactionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SEARCH_CONSUMPTIONTRANSACTIONS):
    case REQUEST(ACTION_TYPES.FETCH_CONSUMPTIONTRANSACTION_LIST):
    case REQUEST(ACTION_TYPES.FIND_LAST_FOR_ORG_AND_WH):
    case REQUEST(ACTION_TYPES.FETCH_CONSUMPTIONTRANSACTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_CONSUMPTIONTRANSACTION):
    case REQUEST(ACTION_TYPES.UPDATE_CONSUMPTIONTRANSACTION):
    case REQUEST(ACTION_TYPES.DELETE_CONSUMPTIONTRANSACTION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.SEARCH_CONSUMPTIONTRANSACTIONS):
    case FAILURE(ACTION_TYPES.FETCH_CONSUMPTIONTRANSACTION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CONSUMPTIONTRANSACTION):
    case FAILURE(ACTION_TYPES.CREATE_CONSUMPTIONTRANSACTION):
    case FAILURE(ACTION_TYPES.UPDATE_CONSUMPTIONTRANSACTION):
    case FAILURE(ACTION_TYPES.DELETE_CONSUMPTIONTRANSACTION):
    case FAILURE(ACTION_TYPES.FIND_LAST_FOR_ORG_AND_WH):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.SEARCH_CONSUMPTIONTRANSACTIONS):
    case SUCCESS(ACTION_TYPES.FETCH_CONSUMPTIONTRANSACTION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONSUMPTIONTRANSACTION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_CONSUMPTIONTRANSACTION):
    case SUCCESS(ACTION_TYPES.UPDATE_CONSUMPTIONTRANSACTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_CONSUMPTIONTRANSACTION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.SHOW_DIALOG:
      return {
        ...state,
        show_dialog: action.payload,
      };
    case ACTION_TYPES.SHOW_DIALOG_EDIT:
      return {
        ...state,
        show_dialog_edit: action.payload,
      };
    case ACTION_TYPES.CHOOSE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case ACTION_TYPES.CHOOSE_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FIND_LAST_FOR_ORG_AND_WH):
      return {
        ...state,
        loading: false,
        lastPeriodEnd: action.payload,
      };
    case ACTION_TYPES.SELECT_STATUS_FOR_CHANGE:
      return {
        ...state,
        status: action.payload,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/consumption-transactions';
const apiSearchUrl = 'api/_search/consumption-transactions';

// Actions

export const createEntityAndFillGrouped: ICrudPutAction<IConsumptionTransaction> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/saveAndFillGrouped`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CONSUMPTIONTRANSACTION,
    payload: axios.post(requestUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const searchConsumptionTransactions: ICrudSearchAction<IConsumptionTransaction[]> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_CONSUMPTIONTRANSACTIONS,
  payload: axios.get<IConsumptionTransaction[]>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const loadConsumptionTransactions: ICrudGetAllAction<IConsumptionTransaction[]> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTIONTRANSACTION_LIST,
    payload: axios.get<IConsumptionTransaction[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getSearchEntities: ICrudSearchAction<IConsumptionTransaction> = (query, page, size, sort) => ({
  type: ACTION_TYPES.SEARCH_CONSUMPTIONTRANSACTIONS,
  payload: axios.get<IConsumptionTransaction>(`${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`),
});

export const getEntities: ICrudGetAllAction<IConsumptionTransaction> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTIONTRANSACTION_LIST,
    payload: axios.get<IConsumptionTransaction>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IConsumptionTransaction> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CONSUMPTIONTRANSACTION,
    payload: axios.get<IConsumptionTransaction>(requestUrl),
  };
};

export const getLastPeriodEndForOrgAndWh = (organizationId, warehouseId) => {
  const requestUrl = `${apiUrl}/${organizationId}/${warehouseId}`;
  return {
    type: ACTION_TYPES.FIND_LAST_FOR_ORG_AND_WH,
    payload: axios.get<IConsumptionTransaction>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IConsumptionTransaction> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CONSUMPTIONTRANSACTION,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IConsumptionTransaction> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CONSUMPTIONTRANSACTION,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IConsumptionTransaction> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CONSUMPTIONTRANSACTION,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});

export const setShowDialog = (showDialog: boolean) => {
  return {
    type: ACTION_TYPES.SHOW_DIALOG,
    payload: showDialog,
  };
};

export const setShowDialogEdit = (showDialogEdit: boolean) => {
  return {
    type: ACTION_TYPES.SHOW_DIALOG_EDIT,
    payload: showDialogEdit,
  };
};

export const chooseOrganization = (organization: IOrganization) => {
  return {
    type: ACTION_TYPES.CHOOSE_ORGANIZATION,
    payload: organization,
  };
};

export const chooseWarehouse = (wh: IWarehouse) => {
  return {
    type: ACTION_TYPES.CHOOSE_WAREHOUSE,
    payload: wh,
  };
};

export const chooseStatusForChange = (status: EConsumptionTransactionStatus) => {
  return {
    type: ACTION_TYPES.SELECT_STATUS_FOR_CHANGE,
    payload: status,
  };
};
