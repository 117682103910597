import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  Card,
  CARD_IMPORTANCE,
  Grid,
  Col,
  Row,
  HeadlineH0,
  getSortState,
  FlatTable,
  FlatTableHeader,
  FlatTableHeaderCell,
  FlatTableDataRow,
  FlatTableDataCell,
   FlatTablePagination
} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {IRootState} from "app/shared/reducers";

import {ITEMS_PER_PAGE, ITEMS_PER_PAGE_DIALOG} from "app/shared/util/pagination.constants";
import {useHistory, useRouteMatch} from "react-router-dom";
import {getTotalsForTransaction} from "app/shared/reducers/invoiceHsBase/transaction/total/invoice-hs-base-transaction-total.reducer";
import NumberFormat from "react-number-format";
import {APP_DECIMAL_SEPARATOR, APP_NUMBER_FORMAT_DECIMAL_SCALE, APP_THOUSAND_SEPARATOR} from "app/config/constants";

const InvoiceHsBaseTransactionPreviewTotals = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const [paginationState, setPaginationState] = useState(getSortState(location, ITEMS_PER_PAGE_DIALOG));
  const totals  = useSelector(({invoiceHsBaseTransactionTotal}: IRootState) => invoiceHsBaseTransactionTotal.totals);
  const totalsCount  = useSelector(({invoiceHsBaseTransactionTotal}: IRootState) => invoiceHsBaseTransactionTotal.totalItems);

  const match = useRouteMatch();


  const handleSort = (column, order) => {
    setPaginationState({
      ...paginationState,
      sort: column,
      order: order
    });
    history.push(`${location.pathname}?page=${paginationState.activePage}&sort=${column},${order}`);
  };

  const contentCardTable = (
    <>
      <div className={"d-flex w-100 flex-column justify-content-end align-items-end"}>
        <div className={"w-100 px-3 justify-content-end"}>
          <FlatTable>
            <FlatTableHeader>

              <FlatTableHeaderCell  first showSort onHeaderClick={(sort) => handleSort("woodTypeName", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseTransactionTotal.woodTypeName')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("className", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseTransactionTotal.className')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell showSort onHeaderClick={(sort) => handleSort("priceLength", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseTransactionTotal.priceLength')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell  showSort onHeaderClick={(sort) => handleSort("priceRange", sort === 1 ? 'asc' : sort === 2 ? 'desc' : '')}>
                {translate('denaliApp.invoiceHsBaseTransactionTotal.priceRange')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.m3')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.fee')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.unitPrice')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.totalValueNoTaxWithExpenses')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.totalValueWithTaxNoExpenses')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.totalValueNoTaxNoExpenses')}
              </FlatTableHeaderCell>

              <FlatTableHeaderCell className={"col-2"}>
                { translate('denaliApp.invoiceHsBaseTransactionTotal.manipulativeCosts')}
              </FlatTableHeaderCell>

            </FlatTableHeader>
            {
              [].concat(totals) && [].concat(totals).map(row =>(
                <FlatTableDataRow>

                  <FlatTableDataCell type={"begin"} showBorder={false}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.woodTypeName}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"} showBorder={true}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.className}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"} showBorder={true}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.priceLength}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"begin"}>
                    {
                      <div className={"d-flex w-100 flex-column"}>
                        {row.priceRange}
                      </div>
                    }
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"center"} className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.m3}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"center"} className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.fee}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell type={"center"} className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.unitPrice}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.totalValueNoTaxWithExpenses}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.totalValueWithTaxNoExpenses}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.totalValueNoTaxNoExpenses}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                  <FlatTableDataCell className={"col-2"}>
                    <div className={"d-flex flex-column justify-content-end align-content-center"}>
                      <NumberFormat
                        displayType={'text'}
                        fixedDecimalScale={true}
                        isNumericString={true}
                        className={"text-end"}
                        value={row.manipulativeCosts}
                        thousandSeparator={APP_THOUSAND_SEPARATOR}
                        decimalSeparator={APP_DECIMAL_SEPARATOR}
                        decimalScale={APP_NUMBER_FORMAT_DECIMAL_SCALE}
                      />
                    </div>
                  </FlatTableDataCell>

                </FlatTableDataRow>

              ))
            }
          </FlatTable>
        </div>
      </div>
    </>
  );


  return (
    <Card
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={contentCardTable}
      titleBordered={true}
      title= {translate('denaliApp.invoiceHsBaseTransactionTotal.home.title')}
    />
  );
};


export default InvoiceHsBaseTransactionPreviewTotals;
