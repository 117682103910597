import React from "react";
import {ActionButton, Col, Row, STANDARD_CANCEL_PROPS, STANDARD_SAVE_SUBMIT_PROPS} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";
import {useHistory} from "react-router-dom";

/**
 *  SaveCancelButtons
 *
 */

const SaveCancelButtons = (props) => {
  const history = useHistory();
  return (
    <Row>
      <Col classname={"d-flex justify-content-center"}>
        <ActionButton
          label={translate('entity.action.save')}
          icon={'icon-save'}
          {...STANDARD_SAVE_SUBMIT_PROPS}
        />
        <ActionButton
          {...STANDARD_CANCEL_PROPS}
          label={translate('entity.action.cancel')}
          command={() => history.goBack()}
        />
      </Col>
    </Row>
  );
};

export default SaveCancelButtons;
