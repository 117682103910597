import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import InvoiceHsBaseTransactionPreviewHeader from "app/modules/invoiceHsBase/transaction/preview/header/invoiceHsBaseTransaction.preview.header";
import InvoiceHsBaseTransactionPreviewTotals from "app/modules/invoiceHsBase/transaction/preview/totals/invoiceHsBaseTransaction.preview.totals";
import InvoiceHsBaseTransactionPreviewPartner from "app/modules/invoiceHsBase/transaction/preview/partner/invoiceHsBaseTransaction.preview.partner";
import {Formik} from "formik";

import {IRootState} from "app/shared/reducers";
import {getTransaction} from "app/shared/reducers/invoiceHsBase/transaction/transaction/invoice-hs-base-transaction.reducer";
import {useHistory, useRouteMatch} from "react-router-dom";
import {createInvoiceHsBaseByTransaction} from "app/shared/reducers/invoiceHsBase/base/base/invoice-hs-base.reducer";
import InsertForm from "app/shared/util/insert-form";
import {Loading} from "@msvsustavi/msv-ui-react";
import {navigation} from "app/shared/util/navigation.constants";
import {getTotalsForTransaction} from "app/shared/reducers/invoiceHsBase/transaction/total/invoice-hs-base-transaction-total.reducer";
import {getQuantityByPartners} from "app/shared/reducers/invoiceHsBase/transaction/quantityPatner/invoice-hs-base-transaction-quantity-partner.reducer";
import InvoiceHsBaseTransactionPreviewItems
  from "app/modules/invoiceHsBase/transaction/preview/invoice/invoiceHsBaseTransaction.preview.invoices";
import {getInvoicesForTransaction} from "app/shared/reducers/invoiceHsBase/transaction/selected/invoice-hs-selected.reducer";

const InvoiceHsBaseTransactionPreviewCard = (props) => {

  const transaction = useSelector(({invoiceHsBaseTransaction}: IRootState) => invoiceHsBaseTransaction.entity);
  const loading = useSelector(({invoiceHsBase}: IRootState) => invoiceHsBase.loading);
  const dispatch = useDispatch();
  const history = useHistory();

  const match = useRouteMatch();

  useEffect(() => {
    dispatch(getTransaction(match.params['id']));
    dispatch(getQuantityByPartners(match.params['id']));
    dispatch(getInvoicesForTransaction(match.params['id']));
    dispatch(getTotalsForTransaction(match.params['id']));
  }, []);

  useEffect(() => {
    if(loading){
      history.push(navigation.invoiceHsBase.dashboard.view)
    }
  }, [loading]);

  return (
    <div className={"w-100 justify-content-center align-items-start"}>
      <Formik
        enableReinitialize={true}
        initialValues={{transaction}}
        onSubmit={(values) => {
          dispatch(createInvoiceHsBaseByTransaction(match.params['id']))
        }}>
        {formik => (
          loading ? <Loading/> :
          <>
           <InsertForm handleSubmit={formik.handleSubmit} title={"denaliApp.invoiceHsBaseTransaction.home.previewTitle"}>
              <InvoiceHsBaseTransactionPreviewHeader/>
              <InvoiceHsBaseTransactionPreviewPartner/>
             <InvoiceHsBaseTransactionPreviewTotals/>
             <InvoiceHsBaseTransactionPreviewItems/>
            </InsertForm>
          </>
          )}
        </Formik>
     </div>
  );
}


export default InvoiceHsBaseTransactionPreviewCard;
