import React from "react";
import {IRootState} from "app/shared/reducers";
import { useDispatch, useSelector} from "react-redux";
import {
  Card,
  CARD_IMPORTANCE,
  Grid,
} from "@msvsustavi/msv-ui-react";
import classNames from "classnames/bind";
import {Translate, translate} from "react-jhipster";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setShowManualEntry, setShowPopratnicaDialog} from "app/shared/reducers/input/insert/input.insert";

export default (props) => {

  const dispatch = useDispatch();
  const {showPopratnicaDialog,showManualEntry} = useSelector(({input}:IRootState)=>input.insert);

  const cnLink = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-90",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button"
  );

  const cnLinkChoose = classNames(
    "d-flex",
    "justify-content-between",
    "align-items-center",
    "my-3",
    "mx-3",
    "p-3",
    "w-90",
    "border",
    "rounded-3",
    "shadow-sm",
    "fw-bold",
    "text-uppercase",
    "button",
    "background-dark-lime-green"
  );


  const content = (
    <Grid>
      <div className = "row g-0 p-2">
        <div className = "col-sm-12 col-md-12 pe-2">
          <div className={showPopratnicaDialog ? cnLinkChoose : cnLink}  onClick={
              ()=>
                dispatch(setShowPopratnicaDialog(!showPopratnicaDialog))
            }>
            <div className={"d-flex flex-column"}>
              <Translate contentKey={"denaliApp.input.documentLookup"}/>
            </div>
            <FontAwesomeIcon className={"fs-1 color-black"} icon={["fas", "th-list"]}/>
          </div>
        </div>
        <div className = "col-sm-12 col-md-12 pe-2">
          <div className={showManualEntry ? cnLinkChoose : cnLink}  onClick={
            ()=>
              dispatch(setShowManualEntry(!showManualEntry))
          }>
            <div className={"d-flex flex-column"}>
              <Translate contentKey={"denaliApp.input.manualEntry"}/>
            </div>
            <FontAwesomeIcon className={"fs-1 color-black"} icon={["far", "pen"]}/>
          </div>
        </div>
      </div>
    </Grid>
  );


  return (
    <Card
      title={translate("denaliApp.input.chooseInputInsertType")}
      titleBordered={true}
      showSideSpace={false}
      showSideButton={false}
      actionsBordered={true}
      importance={CARD_IMPORTANCE.HIGH}
      rootClassName={"w-100"}
      content={content}
    />
  );
};
