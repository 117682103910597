import React from "react";
import {IRootState} from "app/shared/reducers";
import {connect, useDispatch} from "react-redux";
import {
  ACTION_TYPE,
  BUTTON_SHAPE,
  IButtonProps,
  LAYOUT_TYPE,
  LayoutStandard,
  ProfileLink,
  SideBar
} from "@msvsustavi/msv-ui-react";
import AppContentRoutes from "app/modules/home/content";
import {
  modulesAll,
  modulesBases,
  modulesBasesAndConsumption,
  modulesConsumption,
  modulesNon
} from "app/modules/home/modules";
import {navigation} from "app/shared/util/navigation.constants";
import {useHistory} from "react-router-dom";
import {setShowDialog} from "app/shared/reducers/transaction/consumption-transaction.reducer";
import ConsumptionTransactionEntryDialog from "app/modules/consumptionGrouped/transaction/insert/consumptionTransactionEntry.dialog";
import {setShowChooseInsertDialog} from "app/shared/reducers/dashboard/dashboard-choose-insert.reducer";
import InsertChooseDialog from "app/modules/home/insertChooseDialog/insertChoose.dialog";
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";

/**
 * ${USER}: ${DATE} ${TIME}
 */
export interface IHomeProps extends StateProps, DispatchProps {
}

const Home = (props: IHomeProps) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const links: ProfileLink[] = [
    /**{
      link: "#",
      label: "Profile"
    },
    {
      link: "#",
      label: "Preferences"
    }, **/
    {
      label: "Korisnici apkacije",
      link:navigation.admin.userManagement
    }
  ];

  const actions: IButtonProps[] = [
    {
      type: ACTION_TYPE.BUTTON,
      layout: LAYOUT_TYPE.CENTER,
      shape: BUTTON_SHAPE.ROUNDED,
      showLabel: false,
      icon : ["fas", "home"],
      faIcon: true,
      background: 'background-white',
      color: 'color-black',
      command: ()=>{
        history.push(navigation.root.home);
      },
      visible : props.isConsumption
    },
    {
      type: ACTION_TYPE.BUTTON,
      layout: LAYOUT_TYPE.CENTER,
      shape: BUTTON_SHAPE.ROUNDED,
      showLabel: false,
      icon: 'icon-add',
      background: 'background-primary',
      color: 'color-white',
      command: ()=>{
        dispatch(setShowChooseInsertDialog(true))
      },
      visible : props.isConsumption || props.isInput
    },
    {
      type: ACTION_TYPE.BUTTON,
      layout: LAYOUT_TYPE.CENTER,
      shape: BUTTON_SHAPE.ROUNDED,
      showLabel: false,
      icon : ["fas", "warehouse"],
      faIcon: true,
      background: 'background-primary',
      color: 'color-white',
      command: ()=>{
        dispatch(setShowDialog(true))
      },
      visible : props.isConsumption
    },
    {
      type: ACTION_TYPE.BUTTON,
      layout: LAYOUT_TYPE.CENTER,
      shape: BUTTON_SHAPE.ROUNDED,
      showLabel: false,
      icon : ["fas", "table"],
      faIcon: true,
      background: 'background-shamrock-green',
      color: 'color-white',
      command: ()=>{
        history.push(navigation.invoiceHsBase.transaction.insert);
      },
      visible : props.isBases
    }
  ];
  // @ts-ignore
  return (
    <>
      <LayoutStandard
        profileLinks={links}
        help={{showHelp: false, helpLink: "#", helpLabel: "Help"}}
        logout={{link: navigation.logout, label: "Logout"}}
        showProfileElement={props.isAuthenticated}
        showSideBar={props.isAuthenticated}
        sideBarElement={<SideBar modules={
          props.isBases && !props.isConsumption && !props.isInput ? modulesBases:
            !props.isBases && props.isConsumption && props.isInput ? modulesConsumption :
              props.isBases && props.isConsumption && props.isInput ? modulesBasesAndConsumption :
                modulesNon
        } actions={actions}/>}
      >
        <AppContentRoutes/>
        <ConsumptionTransactionEntryDialog/>
        <InsertChooseDialog/>
      </LayoutStandard>
    </>
  );
}


const mapStateToProps = ({authentication}: IRootState) => ({
  account: authentication.account,
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isBases: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.BASES]),
  isInput: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.INPUT]),
  isConsumption: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.CONSUMPTION])
});

const mapDispatchToProps = {
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Home);
