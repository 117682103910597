import React from "react";
import {HeadlineCopyBold} from "@msvsustavi/msv-ui-react";
import {translate} from "react-jhipster";

const ConsumptionHeader = () => {
  return (
    <div className={"d-flex flex-row px-2"}>
      <HeadlineCopyBold headline={translate("denaliApp.consumption.variation")} className={"w-25 p-2 border-bottom"}/>
      <HeadlineCopyBold headline={translate("denaliApp.consumption.uqDesignation")} className={"w-25 p-2 border-bottom"}/>
      <HeadlineCopyBold headline={translate("denaliApp.consumption.document")} className={"w-25 p-2 border-bottom"}/>
      <HeadlineCopyBold headline={translate("denaliApp.consumption.quantity")} className={"w-25 p-2 border-bottom"}/>
    </div>
  );
};

export default ConsumptionHeader;
