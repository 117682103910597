import React from 'react';
import ChatsSelectionsView from "app/modules/dashboard/cubeCharts/segments/selection";
import TotalsChartsView from "app/modules/dashboard/cubeCharts/segments/totalsPanel";
import WeekBeforeByVariationsChartsView from "app/modules/dashboard/cubeCharts/segments/weekBeforeByVariationsCharts";
import WeekConsumptionChartsView from "app/modules/dashboard/cubeCharts/segments/weekConsumptionCharts";
import WeekConsumptionByVariationChartsView
  from "app/modules/dashboard/cubeCharts/segments/weekConsumptionByVariationCharts";
import YearConsumptionChartsView from "app/modules/dashboard/cubeCharts/segments/yearConsumptionCharts";
import {useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";


const Dashboard = () => {

  const isConsumption = useSelector(({authentication}:IRootState)=> hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.CONSUMPTION]));

  return (
    isConsumption ?
    <>
      <ChatsSelectionsView/>
      <div className = "row g-0">
        <div className = "col-sm-24 col-md-12">
        </div>
        <div className = "col-sm-24 col-md-12">
          <TotalsChartsView/>
        </div>
      </div>
      <div className = "row g-0 p-2">
        <div className = "col-sm-24 col-md-12 pe-2">
          <div className={"border rounded shadow p-3"}>
            <WeekConsumptionChartsView/>
          </div>
        </div>
        <div className = "col-sm-24 col-md-12 pe-2">
          <div className={"border rounded shadow p-3"}>
            <YearConsumptionChartsView/>
          </div>
        </div>
      </div>
      <div className = "row g-0 p-2">
        <div className = "col-sm-24 col-md-12 pe-2">
          <div className={"border rounded shadow p-3"}>
            <WeekConsumptionByVariationChartsView/>
          </div>
        </div>
        <div className = "col-sm-24 col-md-12 pe-2">
          <div className={"border rounded shadow p-3"}>
            <WeekBeforeByVariationsChartsView/>
          </div>
        </div>
      </div>
    </> : <> </>)
    ;
}

export default Dashboard;

