export interface ITRUPCI {
  id?: number;
  brojPlocice?: string;
  cjDuljina?: string;
  cjRazred?: string;
  duljina?: number;
  masaM3?: number;
  nazivJM?: string;
  nazivSortimenta?: string;
  nazivVrste?: string;
  promjer?: number;
  promjerBK?: number;
  sumPloc?: string;
  datumPopratnice?: string;
  brojDokumenta?: string;
  stranica?: number;
  varijacija?: string;
}

export interface ITrupciSearch {
  brojPlocice?: string;
  cjDuljina?: string;
  cjRazred?: string;
  duljina?: number;
  masaM3?: number;
  nazivJM?: string;
  nazivSortimenta?: string;
  nazivVrste?: string;
  promjer?: number;
  promjerBK?: number;
  sumPloc?: string;
  datumPopratnice?: string;
  brojDokumenta?: string;
  stranica?: number;
  varijacija?: string;
}

export const defaultValue: Readonly<ITRUPCI> = {};
