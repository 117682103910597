import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ITRUPCI, defaultValue, ITrupciSearch } from 'app/shared/model/nabavawrapper/trupci.model';

export const ACTION_TYPES = {
  FETCH_TRUPCI_LIST: 'tRUPCI/FETCH_TRUPCI_LIST',
  FETCH_TRUPCI: 'tRUPCI/FETCH_TRUPCI',
  RESET: 'tRUPCI/RESET',
  SET_SEARCH: 'tRUPCI/search/SET_SEARCH',
};

const defaultTrupciSearch: ITrupciSearch = {};
const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITRUPCI>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  uqSearchData: { ...defaultTrupciSearch },
  search: '',
};

export type TrupciState = Readonly<typeof initialState>;

// Reducer

export default (state: TrupciState = initialState, action): TrupciState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TRUPCI_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TRUPCI):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_TRUPCI_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TRUPCI):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRUPCI_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRUPCI):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    case ACTION_TYPES.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

const apiUrl = 'services/nabavawrapper/api/trupcis';

// Actions

export const getEntities: ICrudGetAllAction<ITRUPCI> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_TRUPCI_LIST,
    payload: axios.get<ITRUPCI>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const searchTrupci = (query: string | undefined, page: number | undefined, size: number | undefined, sort: string | undefined) => {
  const apiUrl = '/services/nabavawrapper/api/trupcis';
  const requestUrl = `${apiUrl}${sort ? `?${query}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_TRUPCI_LIST,
    payload: axios.get<ITRUPCI>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<ITRUPCI> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TRUPCI,
    payload: axios.get<ITRUPCI>(requestUrl),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
