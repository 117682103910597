import React from "react";
import {
  DataTableColumn, dataTableItemTemplateBoolean,
  dataTableItemTemplateFK, dataTableItemTemplateString
} from "@msvsustavi/msv-ui-react/dist";
import Translate from "react-jhipster/lib/src/language/translate";



export const VariationDataTableColumns: DataTableColumn[] = [
  {
    field:  'sku',
    header: <Translate contentKey={'denaliApp.variation.sku'}/>,
     sortable: true,
    itemTemplate: (column, rowData, rowIndex, colIndex, columnCount) => //
     dataTableItemTemplateFK(column, rowData, rowIndex, colIndex, columnCount, (r, c) => r['sku'], (r, c) => `/master/variation/${r['id']}`)
  },{
    field: 'variationName',
    header: <Translate contentKey={'denaliApp.variation.variationName'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateString
  },
  {
    field: 'active',
    header: <Translate contentKey={'denaliApp.variation.active'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateBoolean
  },
  {
    field: 'productDesignation',
    header: <Translate contentKey={'denaliApp.variation.productDesignation'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateString
  },
  {
    field: 'productName',
    header: <Translate contentKey={'denaliApp.variation.productName'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateString
  },
  {
    field: 'uom',
    header: <Translate contentKey={'denaliApp.variation.uom'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateString
  },
  {
    field: 'productType',
    header: <Translate contentKey={'denaliApp.variation.productType'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateString
  },
  {
    field: 'productCategory',
    header: <Translate contentKey={'denaliApp.variation.productCategory'}/>,
    sortable: true,
    itemTemplate: dataTableItemTemplateString
  }
];
