import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { AUTHORITIES } from 'app/config/constants';
import {HeaderElementContext, useHeaderTitle} from "app/modules/layout/header";
import {navigation} from "app/shared/util/navigation.constants";

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>,
});

const Routes = () => {
  const title = useHeaderTitle();
  return (
    <HeaderElementContext.Provider value={title}>
      <Switch>
        <ErrorBoundaryRoute path={navigation.login} exact={true} component={Login}/>
        <ErrorBoundaryRoute path={navigation.logout} exact={true} component={Logout}/>
        <PrivateRoute path={navigation.admin.home} component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]}/>
        <ErrorBoundaryRoute path={navigation.root.home} component={Home}/>
      </Switch>
    </HeaderElementContext.Provider>
  )
};

export default Routes;
