import { IOrganization } from 'app/shared/model/master/organization/organization.model';

export interface IWarehouse {
  id?: number;
  designation?: string;
  name?: string;
  active?: boolean;
  mappingToElbrusWarehouse?: string;
  organizationId?: number;

  organization?: IOrganization;
}

export const defaultValue: Readonly<IWarehouse> = {
  active: false,
};
