import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "app/shared/reducers";
import Popup from "reactjs-popup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translate, translate} from "react-jhipster";
import {setSearchData} from "app/shared/reducers/consumption/view/consumption.view";
import {InputComponentText} from "@msvsustavi/msv-ui-react/dist";

/**
 *
 */
const DocumentSelection = (props) => {
  const dispatch = useDispatch();

  const {consumptionSearchData} = useSelector(({consumption}: IRootState) => consumption.view);

  const [values, setValues] = useState(consumptionSearchData);
  useEffect(()=>{setSearchData(consumptionSearchData)},[consumptionSearchData]);


  return (
    <Popup
      trigger={open => <div className={"mx-4 d-flex align-items-center"}>
        <div className={"me-1"}><Translate contentKey={"denaliApp.consumption.document"}/></div>
        <FontAwesomeIcon icon={["far", "angle-down"]}/>
      </div>}
      closeOnDocumentClick
      position={['bottom right', 'bottom left']}
      arrow
      onClose={(event => {
        dispatch(setSearchData(values));
      })}
    >
      <div className={"d-flex flex-column popup p-3  rounded shadow"}>
        <div className={"d-flex flex-column"}>
          <div className={"px-2 py-1 text-uppercase fs-5 fw-bold selection-header"}><Translate contentKey={"denaliApp.consumption.document"}/></div>
          <div className={"container"}>
            <div className={"row g-0"}>
              <div className={"col-24"}>
                <InputComponentText
                  id={"document"}
                  name={"document"}
                  value = {values.document}
                  wrapInForm={false}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.consumption.document")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      document: e.target.value
                    });
                  }}
                />
              </div>
            </div>
            <div className={"row g-0"}>
              <div className={"col-24"}>
                <InputComponentText
                  id={"location"}
                  name={"location"}
                  value = {values.location}
                  wrapInForm={false}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.consumption.location")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      location: e.target.value
                    });
                  }}
                />
              </div>
            </div>
            <div className={"row g-0"}>
              <div className={"col-24"}>
                <InputComponentText
                  id={"partner"}
                  name={"partner"}
                  value = {values.uqDesignation}
                  wrapInForm={false}
                  required={false}
                  showBackground={true}
                  label={translate("denaliApp.consumption.uqDesignation")}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      uqDesignation: e.target.value
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default DocumentSelection;
