import React from "react";
import {HeadlineCopyBold} from "@msvsustavi/msv-ui-react";
import NumberFormat from "react-number-format";
import {APP_DECIMAL_SEPARATOR, APP_THOUSAND_SEPARATOR} from "app/config/constants";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IConsumption} from "app/shared/model/consumption/consumption.model";
import {
  fillConsumptionInput,
  removeConsumptionInput,
  setVariationInput
} from "app/shared/reducers/consumption/insert/consumption.insert";

export interface IConsumptionRowProps{
  consumption: IConsumption,
  showDelete: boolean;
}

const ConsumptionRow = (props:IConsumptionRowProps) => {
  const {consumption} = props;
  const dispatch = useDispatch();
  return (
    <div className={"d-flex flex-row border-bottom pe-2"}>
      {props.showDelete?<div className={"d-flex p-2"} onClick={()=>{
        dispatch(fillConsumptionInput(consumption));
        dispatch(setVariationInput(true));
      }}>
        <FontAwesomeIcon className={"color-black"} icon={["far","pen"]}/>
      </div>:<></>}
      <HeadlineCopyBold headline={`${consumption.variation.variationName}`} className={"w-25 p-2"}/>
      {
        consumption.uqDesignation != null ? <HeadlineCopyBold headline={`${consumption.uqDesignation}`} className={"w-25 p-2"}/> : <div  className={"w-25 p-2"}></div>
      }
      {
        consumption.document != null ? <HeadlineCopyBold headline={`${consumption.document}`} className={"w-25 p-2"}/> : <div  className={"w-25 p-2"}></div>
      }
      {
        consumption.quantity != null ? <HeadlineCopyBold headline={`${consumption.quantity} ${consumption.variation.uom}`} className={"w-25 p-2"}/> : <div  className={"w-25 p-2"}></div>
      }
      {props.showDelete?<div className={"d-flex p-2"} onClick={()=>
       dispatch(removeConsumptionInput(consumption))}>
        <FontAwesomeIcon className={"color-red"} icon={["far","trash-alt"]}/>
      </div>:<></>}
    </div>
  );
};

export default ConsumptionRow;


